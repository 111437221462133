import {
  Divider,
  List,
  Typography,
  Card,
} from '@mui/material';
import React from 'react';
import { CheckBoxItems } from './checkBoxItems';

const XmlTypes = [
  {
    name: 'Autorizados',
    initial: 'A'
  },
  {
    name: 'Cancelados',
    initial: 'C'
  }
]

const TypesCheckBox = ({ typesChecked, setTypesChecked }) => {


  function updateTypesChecked(type) {

    const newTypesChecked = Array.from(typesChecked);

    const typeExist = newTypesChecked.includes(type)

    if (!typeExist) {
      const typesUpdate = [...newTypesChecked, type]
      return setTypesChecked('status', typesUpdate)

    } else {

      const typesFiltered = newTypesChecked.filter(t => t !== type)

      return setTypesChecked('status', typesFiltered)

    }

  }


  return (
    <Card
      elevation={3}
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        maxWidth: 300,
        bgcolor: 'background.paper',
        padding: '1rem',
        overflow: 'hidden',
      }}
    >
      <Typography>Tipos de Xml</Typography>
      <Divider />
      <List
        sx={{
          width: 400,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 200,
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        {XmlTypes.map(type => (
          <CheckBoxItems
            key={type.name}
            xmlType={type}
            typesChecked={typesChecked}
            updateTypesChecked={updateTypesChecked}
          />
        ))}
      </List>
    </Card>
  );
};

export { TypesCheckBox };
