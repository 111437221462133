import { Box, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useState } from 'react';
import { v4 } from 'uuid';
import logoWhite from '../../../../assets/img/logo-white.png';
import api from '../../../../services/api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1253da',
    color: theme.palette.common.white,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const currentDate = new Date().toLocaleDateString('pt-BR')

const ReportTransportedPackages = ({ packageList, driverId, originDockId, destinationDockId }) => {

  const tablesForRows = useMemo(() => {
    let count = 0
    let index = 0

    const result = packageList.reduce((acc, curr) => {

      if (count < 23) {
        if (!acc[index].tableId) {
          acc[index].tableId = v4()
        }
        acc[index].tableRows.push(curr)
      }

      if (count >= 23) {
        const newData = {
          tableId: v4(),
          tableRows: [curr]
        }
        acc.push(newData)
        index++
        count = 0
      }

      count++
      return acc
    },
      [
        {
          tableId: '',
          tableRows: []
        }
      ])

    return result
  }, [packageList])

  const { data: docks } = useQuery(['docks'])
  const { data: drivers } = useQuery(['operations/drivers'])

  const originDock = useMemo(() => {
    if (!docks) return 'N/D'

    const result = docks.find(d => d.id === originDockId)

    if (!result) return 'N/D'

    return result.dock

  }, [originDockId, docks])

  const destinationDock = useMemo(() => {
    if (!docks) return 'N/D'

    const result = docks.find(d => d.id === destinationDockId)

    if (!result) return 'N/D'

    return result.dock

  }, [destinationDockId, docks])


  const driver = useMemo(() => {
    if (!drivers) return 'N/D'

    const result = drivers.find(d => d.id === driverId)

    if (!result) return 'N/D'

    return result

  }, [driverId, drivers])

  return (
    <Box
      flexDirection='column'
      height='100%'
      flex='1'
      gap='1rem'
      className='print'
    >
      {tablesForRows.map(t => (
        <Box display='flex' gap='1rem' flexDirection='column' key={t.tableId}>
          <Box w='100%' display='flex' alignItems='center' justifyContent='center' gap='1rem'>
            <img src={logoWhite} alt="logo" width="fit-content" height="50px" />
          </Box>
          <Box
            display='flex'
            width='100%'
            alignItems='center' justifyContent='space-between'
          >
            <Box>
              <Typography>
                <strong>Transportadora Vai Fácil</strong>
              </Typography>
              <Typography>
                Origem: <strong>{originDock}</strong>
              </Typography>
              <Typography>
                Destino: <strong>{destinationDock}</strong>
              </Typography>
            </Box>
            <Box>
              <Typography>Data da Expedição: <strong>{currentDate}</strong></Typography>
              <Typography>Total de Pedidos: <strong>{packageList.length}</strong> </Typography>
              <Typography>Motorista: <strong>{driver.name}</strong> </Typography>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ minHeight: '65vh', marginBottom: '1rem' }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Nº</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Codigo do Pacote</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Contrato</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Bairro</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Cidade</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>Estado</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {t.tableRows.map((row) => (
                  <StyledTableRow
                    key={row.count}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">{row.count}</TableCell>
                    <TableCell component="th" scope="row">{row.code}</TableCell>
                    <TableCell>{row.contractName}</TableCell>
                    <TableCell>{row.neighborhood}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.state}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box flex='1' height='100%' display='flex' alignItems='end' justifyContent='space-between' gap='2rem' marginBottom='3rem'>
            <Box display='flex' alignItems='center' justifyContent='center' gap='1rem' flexDirection='column'>
              <Typography variant="body1" component="h2" textAlign='center'>
                Assinatura Conferente
              </Typography>
              <Typography variant="body1" component="h2" textAlign='center'>
                _____________________________
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center' gap='1rem' flexDirection='column'>
              <Typography variant="body1" component="h2" textAlign='center'>
                Assinatura Cliente
              </Typography>
              <Typography variant="body1" component="h2" textAlign='center'>
                _____________________________
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export { ReportTransportedPackages };
