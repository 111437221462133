import styled from "styled-components";

export const TableElement = styled.table`
    max-width: 780px;
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 2rem;
    flex-direction: column;
    border: 1px solid #e1e1e0;
    border-radius: 0.5rem;
    border-spacing: 0;
    justify-content: space-between;
    border-spacing: 0.5rem;

    text-transform: uppercase;

    tbody {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    thead{
            tr {
            display: flex;
            flex: 1;
            justify-content: space-between;
            width: 100%;
        }
    }

    th {
        text-align: start;
        padding: 0.5rem;
        color: #ccc;
    }

    td {
        text-align: start;
        width: 100%;
    }

    tfoot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.5rem;
        color: #ccc;
    }

    @media(max-width: 780px){
    font-size: 1rem;
    }
`;
