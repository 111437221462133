import styled from "styled-components";
import { darken } from 'polished'

export const Container = styled.div`
display: flex;
flex-direction: column;
border: 1px solid #e1e1e0;
align-items: center;
justify-content: center;
height: 190px;
width: 21rem;
border-radius: 0.5rem;
padding: 1.5rem 0 0 0;
color: #333;
`;

export const Total = styled.strong`
font-size: 5rem;
font-weight: 400;
`;

export const Footer = styled.div`
border-top: ${props => props.style ? '' : '1px solid #e1e1e0'};
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
border-radius: 0 0 0.5rem 0.5rem;
color: ${props => props.style ? '' : '#cd5c5c'};
`;

export const Description = styled.span`
font-weight: bold;
font-size: 2rem;
`;


export function themesBody(theme) {
    if (theme === 'good') {
        return {
            background: '#32cd32',
            color: 'green',
        }
    }
    if (theme === 'attention') {
        return {
            background: 'orange',
            color: '#ff0',
        }
    }
    if (theme === 'bad') {
        return {
            background: 'red',
            color: '#ffff',
        }
    }
}

export function themesFooter(theme) {
    if (theme === 'good') {
        return {
            background: darken(-0.1, '#32cd32'),
            color: 'green',
        }
    }
    if (theme === 'attention') {
        return {
            background: darken(-0.1, 'orange'),
            color: '#ff0',
        }
    }
    if (theme === 'bad') {
        return {
            background: darken(-0.1, 'red'),
            color: '#ffff',
        }
    }
}
