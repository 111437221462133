const statusColor = {
  'Pendente': {
    background: 'gray',
    color: 'white'
  },
  'Aguardando Coleta': {
    background: 'blue',
    color: 'white'
  },
  'Coletado': {
    background: 'blue',
    color: 'white'
  },
  'Armazenado': {
    background: 'orange',
    color: 'white'
  },
  'Entrega em andamento': {
    background: '#42ffad',
    color: 'black'
  },
  'Entregue': {
    background: '#00691b',
    color: 'white'
  },
  'Pronto para retirada': {
    background: '#00691b',
    color: 'white'
  },
  'Pedido Cancelado': {
    background: 'purple',
    color: 'white'
  },
  'Aguardando Devolução': {
    background: 'orange',
    color: 'white'
  },
  'Devolvido': {
    background: 'purple',
    color: 'white'
  },
  'Transferência entre Unidades': {
    background: 'orange',
    color: 'black'
  },
  'Extraviado': {
    background: 'purple',
    color: 'white'
  }
};

export default statusColor
