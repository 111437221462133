import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import api from '../../../../services/api';
import terms from '../../../../utils/searchTerms.json';
import { isValid } from '../../../../utils/validations';
import DockSelect from '../../components/DockSelect';
import DriverSupplier from '../../components/DriverSupplier';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendOrder, setOrder } from '../../utils/OperationActions';
import { Container, Title } from './styles';
import { logError } from '../../../../utils/error';

const Collect = () => {
    const [driver, setDriver] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function handleTransfer(target) {
        if (!target.value) return;

        const orderCode = target.value;

        target.value = '';
        target.focus();

        if (orders.map((o) => o.number).includes(orderCode)) {
            enqueueSnackbar(`Pedido "${orderCode}" já foi registrado nesta tentantiva`, {
                variant: 'info',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        const orderObject = setOrder({
            dock: origin,
            destination,
            order: orderCode
        });

        if (term) {
            Object.assign(orderObject, { searchBy: term });
        }

        if (isValid(driver)) {
            Object.assign(orderObject, driver);
        } else {
            enqueueSnackbar('Motorista não selecionado', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        if (!!origin && !!destination && !!orderObject.driver) {
            const listObj = {
                origin,
                destination
            };

            Object.assign(listObj, driver);

            try {
                const { data } = await api.post('/operations/list', listObj);

                if (data && orderObject) {
                    Object.assign(orderObject, { list: data.id });
                }
            } catch (error) {
                logError(error);

                enqueueSnackbar('Erro na comunicação', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }
        }

        if (orderObject) {
            const response = await sendOrder({
                payload: orderObject,
                operation: 'transfer'
            });
            if (response.type === 'toast') {
                enqueueSnackbar(response.data, {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } else {
                setOrders([...response.data, ...orders]);
            }
        }
    }

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Container>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Title>Transbordo</Title>
                <DriverSupplier
                    onChange={(e) => setDriver(isValid(e) ? e : null)}
                    externalTransfer
                />
                <DockSelect onChange={setOrigin} placeholder="Origem" disabled={!isValid(driver)} />
                <DockSelect
                    onChange={setDestination}
                    placeholder="Destino"
                    disabled={!origin || !isValid(driver)}
                />

                <FormControl variant="filled" style={{ width: '300px' }} required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        disabled={!destination || !isValid(driver)}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    disabled={!destination || !isValid(driver)}
                    label="Digite aqui para buscar..."
                    style={{ width: '300px' }}
                    onKeyDown={(event) => event.key === 'Enter' && handleTransfer(event.target)}
                />
            </Stack>

            <OperationPackagesFeedback
                orders={orders}
                handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
            />
        </Container>
    );
};

export default Collect;
