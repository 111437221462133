import styled from "styled-components"

export const Container = styled.div`
display: flex;
justify-content: space-around;
padding: 3rem;
flex-wrap: wrap;
`;

export const CardsSection = styled.div`

display: flex;
flex-direction: column;
justify-content: space-evenly;
 height: 32rem;
;

`
