import configs from './configs.json';
import {
    dashboardPages,
    financialPages,
    operationBatchPages,
    operationPages,
    panelPages,
    retailerPages
} from './menu';

const mapName = (items) => items.map((r) => r.name);

const resources = {
    operation: mapName([...operationPages, ...operationBatchPages]),
    retailer: mapName(retailerPages),
    panel: mapName(panelPages),
    dashboard: mapName(dashboardPages),
    financial: mapName(financialPages),
    backoffice: []
};

const {
    rules: { actions }
} = configs;

actions.forEach((a) => {
    if (a.module === 'operation') {
        resources.operation.push(a.id);
    }
    if (a.module === 'backoffice') {
        resources.backoffice.push(a.id);
    }
    if (a.module === 'retailer') {
        resources.retailer.push(a.id);
    }
});

const filterResourcesByKeys = (keys) =>
    keys.reduce((acc, curr) => {
        resources[curr].forEach((resource) => acc.push(resource));

        return acc;
    }, []);

export const roles = {
    1: {
        resources: ['*'],
        name: 'Admin'
    },
    2: {
        resources: filterResourcesByKeys(['panel', 'operation', 'backoffice']),
        name: 'Operational Manager'
    },
    3: {
        resources: filterResourcesByKeys(['panel', 'operation', 'backoffice']),
        name: 'Operational Backoffice'
    },
    4: {
        resources: [
            ...filterResourcesByKeys(['operation']).filter(
                (i) => !['monitoring', 'storage'].includes(i)
            ),
            'backoffice-interaction-on-search'
        ],
        name: 'Operational'
    },
    5: {
        resources: ['search', ...filterResourcesByKeys(['financial'])],
        name: 'Financial'
    },
    6: {
        resources: [],
        name: 'Administrative'
    },
    9: {
        resources: filterResourcesByKeys(['retailer']),
        name: 'Customer Admin'
    },
    10: {
        resources: filterResourcesByKeys(['retailer']),
        name: 'Customer Manager'
    },
    11: {
        resources: filterResourcesByKeys(['retailer']),
        name: 'Customer User'
    },
    12: {
        resources: [...filterResourcesByKeys(['panel']), 'search'],
        name: 'Monitoring'
    },
    13: {
        resources: ['dispatch', 'search', 'backoffice-interaction-on-search'],
        name: 'Supplier dispatcher'
    },
    14: {
        resources: filterResourcesByKeys(['dashboard']),
        name: 'Dashboard'
    },
    queryOnly: {
        resources: ['retailer-tracking'],
        name: 'Query-Only Customer'
    }
};
