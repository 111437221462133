import styled from 'styled-components';

export const Container = styled.button`
    border: none;
    cursor: pointer;
    max-width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fafafa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
        0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0px;

    transition: transform 0.2s, box-shadow 0.2s, border-color 0.5s, border-width 0.5s,
        border-style 0.5s;
    border: 1px dashed gray;

    ${(props) =>
        props.checked &&
        'transform:translate(10px,-10px);box-shadow: -50px 41px 20px -1px rgba(0,0,0,0.42);border: 2px solid #00cc36;'}

    @media screen and (max-width:500px) {
        flex-wrap: wrap;
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const Item = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px;
`;

export const Label = styled.div`
    /* width: 30%; */
    text-transform: uppercase;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
`;
export const Value = styled.div`
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
