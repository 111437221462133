import styled, { css } from 'styled-components';
import { darken } from 'polished';

const variants = {
    primary: css`
        background: ${(props) => props.theme.primary};
        color: ${(props) => props.theme.secondary};
        &:active {
            background-color: ${(props) => props.theme.primary};
        }
    `,
    secondary: css`
        background: ${(props) => props.theme.secondary};
        color: ${(props) => props.theme.primary};
        &:active {
            background-color: #d1d1d1;
        }
        border: 1px solid rgba(1, 34, 79, 0.5);
    `,
    danger: css`
        background: ${(props) => props.theme.danger};
        color: ${(props) => props.theme.secondary};
        &:active {
            background-color: #ff8285;
        }
        border: 1px solid rgba(255, 34, 79, 0.5);
    `,
    warning: css`
        background: ${(props) => props.theme.warning};
        color: ${(props) => props.theme.secondary};
        &:active {
            background-color: #ffbd00;
        }
        border: 1px solid #cdd900;
    `
};

export const Button = styled.button`
    background: #1253da;
    border-radius: 3px;
    padding: 8px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: ${(props) => props.theme.secondary};
    border: none;
    cursor: pointer;

    transition: opacity 0.2s;
    &:active {
        opacity: 1;
        background-color: ${(props) => props.theme.primary};
    }

    &:hover {
        opacity: 0.6;
    }

    &:disabled {
        opacity: 0.5;
    }
    ${(props) => variants[props.variant]}
`;

export const Input = styled.input`
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};

    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
        &::placeholder {
            color: ${(props) => props.theme.dark};
            font-weight: 400;
        }
    }

    ${({ isErrored, theme }) =>
        isErrored &&
        css`
            color: #ffff;
            &::placeholder {
                color: #ffff;
                font-weight: 400;
            }
            background-color: ${theme.danger};
            &:focus {
                color: #ffff;
                font-weight: 400;
                &::placeholder {
                    color: #ffff;
                    font-weight: 400;
                }
            }
        `}

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Textarea = styled.textarea`
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    padding: 10px;
    max-width: 300px;
    min-width: 300px;
    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};

    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
        &::placeholder {
            color: ${(props) => props.theme.dark};
            font-weight: 400;
        }
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Select = styled.select`
    background: #fafafa;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;

    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};

    color: ${(props) => props.theme.dark};

    height: fit-content;

    &:active,
    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }

    ${({ isErrored, theme }) =>
        isErrored &&
        css`
            color: #ffff;
            &::placeholder {
                color: #ffff;
                font-weight: 400;
            }
            background-color: ${theme.danger};
            &:focus {
                color: #ffff;
                font-weight: 400;
                &::placeholder {
                    color: #ffff;
                    font-weight: 400;
                }
            }

            & > option {
                color: ${(props) => props.theme.dark};
                background-color: #ffff;
            }
        `}
`;

export const Option = styled.option`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 300;
    color: ${(props) => props.theme.dark};
`;

export const Card = styled.div`
    width: fit-content;
    height: fit-content;
    background-color: ${(props) => props.theme.secondary};
    /* flex: 1; */

    background: #fafafa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
        0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    margin: 5px;
`;

export const Icon = styled.span`
    color: ${(props) => props.theme.success};
    width: fit-content;
    height: fit-content;
`;
