import { Alert, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { json2excel } from 'js2excel';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../components';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import PickUpAtStoreOrderCard from '../../components/PickUpAtStoreOrderCard';
import { CardList, Container, Header, Title } from './styles';
import { logError } from '../../../../utils/error';

const PickUpAtStore = () => {
    const [drivers, setDrivers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [driver, setDriver] = useState(null);
    const [customer, setCustomer] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function getDriversFromOrders(orders) {
        const newDrivers = orders.reduce((acc, curr) => {
            const exists = acc.filter((i) => i.id === curr.driverId).length > 0;
            if (!exists) {
                acc.push({ id: curr.driverId, label: curr.driver });
            }

            return acc;
        }, []);

        return newDrivers;
    }
    function getCustomersFromOrders(orders) {
        const newCustomers = orders.reduce((acc, curr) => {
            const exists = acc.filter((i) => i.id === curr.customerId).length > 0;
            if (!exists) {
                acc.push({ id: curr.customerId, label: curr.customer });
            }

            return acc;
        }, []);

        return newCustomers;
    }

    const fetchOrders = useCallback(() => {
        setLoading(true);
        api.get('/operations/store-withdrawal')
            .then((response) => {
                const { data } = response;

                setOrders(data);

                setDrivers(getDriversFromOrders(data));

                setCustomers(getCustomersFromOrders(data));
            })
            .catch(() => {
                enqueueSnackbar('Verifique sua conexão ou se tem acesso a este recurso.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    function handleSelectOrder(code) {
        const alreadyExist = selected.includes(code);

        if (alreadyExist) {
            const newSelectedOrders = selected.filter((o) => o !== code);

            setSelected(newSelectedOrders);
        } else {
            setSelected([...selected, code]);
        }
    }

    function orderFiltering(orderList) {
        if (!orderList) return null;

        if (!driver && !customer) return [];

        return orderList.filter((o) => {
            const driverFilter = !!driver ? o.driverId === driver : true;

            const customerFilter = !!customer ? o.customerId === customer : true;

            return driverFilter && customerFilter;
        });
    }

    function handleSelectAll() {
        const filtered = orderFiltering(orders);

        const selectedAll =
            !!filtered && filtered.length > 0 && selected.length === filtered.length;

        if (selectedAll) {
            setSelected([]);
        } else {
            const newSelected = orderFiltering(filtered).map((i) => i.code);

            setSelected(newSelected);
        }
    }

    function handleSelectDriver(id) {
        setDriver(id);

        const filteredOrders = orders.filter((o) => o.driverId === id);

        const newCustomers = getCustomersFromOrders(
            filteredOrders.length === 0 ? orders : filteredOrders
        );

        setCustomers(newCustomers);
    }

    function handleSelectCustomer(id) {
        setCustomer(id);

        const filteredOrders = orders.filter((o) => o.customerId === id);

        const newDrivers = getDriversFromOrders(
            filteredOrders.length === 0 ? orders : filteredOrders
        );

        setDrivers(newDrivers);
    }

    function handleExport() {
        try {
            const data = orders
                .filter((o) => selected.includes(o.code))
                .map((o) => {
                    delete o.customerId;
                    delete o.driverId;

                    o.cliente = o.customer;
                    o.motorista = o.driver;
                    o.endereco = o.location;
                    o.pedido = o.code;
                    o.destinatario = o.endCustomer;

                    delete o.customer;
                    delete o.driver;
                    delete o.location;
                    delete o.code;
                    delete o.endCustomer;

                    return o;
                });
            json2excel({
                data,
                name: `retira-na-loja-${Date.now()}`,
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);

            enqueueSnackbar('Falha ao gerar arquivo, tente novamente', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
        } finally {
            reset();
        }
    }

    function reset() {
        setDriver(null);
        setCustomer(null);
        setSelected([]);
        fetchOrders();
    }

    return (
        <Container>
            <Title>Retira na Loja</Title>
            {!!loading ? (
                <Loader style={{ flex: 1 }} />
            ) : orders && orders.length > 0 ? (
                <>
                    <Header>
                        <Stack
                            spacing={2}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center">
                            <FormControl variant="filled" style={{ width: '300px' }}>
                                <InputLabel id="driverSelector">Motorista</InputLabel>
                                <Select
                                    defaultValue=""
                                    labelId="driverSelector"
                                    id="driverSelector"
                                    label="Motorista"
                                    onChange={(e) => handleSelectDriver(e.target.value)}>
                                    {drivers.map((d) => (
                                        <MenuItem value={d.id} key={d.id}>
                                            {d.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl variant="filled" style={{ width: '300px' }}>
                                <InputLabel id="customerSelector">Cliente</InputLabel>
                                <Select
                                    defaultValue=""
                                    labelId="customerSelector"
                                    id="customerSelector"
                                    label="Cliente"
                                    onChange={(e) => handleSelectCustomer(e.target.value)}>
                                    {customers.map((c) => (
                                        <MenuItem value={c.id} key={c.id}>
                                            {c.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <h3>Selecionados: {selected.length}</h3>
                            <Button
                                style={{ marginTop: '20px', width: 'fit-content' }}
                                onClick={() => handleSelectAll()}>
                                Selecionar todos
                            </Button>
                            <Button style={{ marginTop: '20px' }} onClick={() => handleExport()}>
                                Gerar arquivo
                            </Button>
                        </Stack>
                    </Header>

                    <CardList>
                        {orderFiltering(orders).map((o, i) => (
                            <PickUpAtStoreOrderCard
                                key={o.code + i}
                                data={o}
                                selected={selected.includes(o.code)}
                                onCheck={(v) => handleSelectOrder(v)}
                            />
                        ))}
                    </CardList>
                </>
            ) : (
                <Alert
                    severity="success"
                    sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                    Nenhuma atualização
                </Alert>
            )}
        </Container>
    );
};

export default PickUpAtStore;
