import { Description, Total, Container, Footer, themesBody, themesFooter } from "./styles"


const Card = ({ value, description, theme }) => {

    return (
        <Container style={themesBody(theme)}>
            <Total>{value}</Total>
            <Footer style={themesFooter(theme)}>
                <Description>{description}</Description>
            </Footer>
        </Container>
    )

}

export default Card
