import terms from '../../../utils/searchTerms.json';
import api from '../../../services/api';
import { haveApiErrors } from '../../../utils/validations';

import { isValid } from '../../../utils/validations';

export function setOrder({
    dock,
    destination,
    order,
    driver = null,
    term = 'code',
    deliveryModality
}) {
    if (!order || !dock) {
        return null;
    }

    const orderObject = {
        dock,
        packages: [
            {
                term: order
            }
        ]
    };

    if (!!isValid(deliveryModality)) {
        Object.assign(orderObject, {
            deliveryModality
        });
    }
    if (!!isValid(destination)) {
        Object.assign(orderObject, {
            destination: destination
        });
    }

    if (!!isValid(driver)) {
        Object.assign(orderObject, {
            driver: driver
        });
    }

    const searchBy = terms[term];

    if (searchBy !== 'code') {
        Object.assign(orderObject, { searchBy });
    }

    return orderObject;
}

export function setBatchOrder({
    dock,
    destination,
    orders,
    driver = null,
    term = 'code',
    deliveryModality
}) {
    if (!orders || !orders.length > 0) {
        return null;
    }

    const orderObject = {
        packages: orders.map((o) => {
            return {
                term: o
            };
        })
    };

    if (!!isValid(deliveryModality)) {
        Object.assign(orderObject, {
            deliveryModality
        });
    }
    if (!!isValid(dock)) {
        Object.assign(orderObject, {
            dock
        });
    }

    if (!!isValid(destination)) {
        Object.assign(orderObject, {
            destination: destination
        });
    }

    if (!!isValid(driver)) {
        Object.assign(orderObject, {
            driver: driver
        });
    }

    const searchBy = terms[term];

    if (searchBy !== 'code') {
        Object.assign(orderObject, { searchBy });
    }

    return orderObject;
}

export function sendOrder({ payload, operation }) {
    return api
        .post(`/operations/${operation}`, payload)
        .then((response) => {
            const { errors } = response.data;

            if (errors.length > 0) {
                const orderObj = {
                    number: errors[0].term,
                    state: 'error',
                    description: errors[0].err || 'Erro'
                };
                const responseObject = { type: 'error', data: [orderObj] };
                return responseObject;
            } else {
                const orderObj = {
                    number: payload.packages[0].term,
                    state: 'success'
                };
                const responseObject = { type: 'success', data: [orderObj] };
                return responseObject;
            }
        })
        .catch((e) => {
            console.log(e);
            const responseObject = {
                type: 'toast',
                data: {
                    type: 'error',
                    title: `Erro!`,
                    description: 'Erro de rede, tente novamente'
                }
            };
            return responseObject;
        });
}

export function sendBatchOrder({ payload, operation }) {
    return api
        .post(`/operations/${operation}`, payload)
        .then((response) => {
            const { errors } = response.data;

            const responseList = [];

            if (haveApiErrors(response.data)) {
                const responseObject = {
                    type: 'toast',
                    data: {
                        type: 'error',
                        title: `Erro inesperado!`,
                        description:
                            'Parece que houve um erro na aplicação, caso continue entre em contato com ti@vaifacil.com'
                    }
                };
                responseList.push(responseObject);
            }

            if (errors.length > 0) {
                const errorsList = errors.map((e) => {
                    return {
                        number: e.term,
                        state: 'error',
                        description: e.err || 'Erro'
                    };
                });
                const responseObject = { type: 'error', data: errorsList };
                responseList.push(responseObject);
            }

            const successList = payload.packages
                .filter((p) => !!errors && !errors.map((e) => e.term).includes(p.term))
                .map((p) => {
                    return {
                        number: p.term,
                        state: 'success'
                    };
                });

            if (successList && successList.length > 0) {
                const responseObject = { type: 'success', data: successList };
                responseList.push(responseObject);
            }

            return responseList;
        })
        .catch((e) => {
            console.log(e);
            const responseList = [
                {
                    type: 'toast',
                    data: {
                        type: 'error',
                        title: `Erro!`,
                        description: 'Erro de rede, tente novamente'
                    }
                }
            ];
            return responseList;
        });
}
