import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { textToCodeArray } from '../../../../utils/formatting';
import terms from '../../../../utils/searchTerms.json';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendBatchOrder, setBatchOrder } from '../../utils/OperationActions';
import { Container, LowerSection, Title } from './styles';
import { logError } from '../../../../utils/error';

const ReversingConfirm = () => {
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const inputRef = useRef(null);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function handleBatchReversingConfirm() {
        if (!inputRef.current) return;

        const codes = textToCodeArray(inputRef.current.value);

        inputRef.current.value = '';

        const existentOrders = orders.filter((o) => {
            if (codes.includes(o.number)) {
                return true;
            } else {
                return false;
            }
        });

        if (existentOrders.length > 0) {
            enqueueSnackbar(`"${existentOrders.length}" pedidos duplicados`, {
                variant: 'info',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        const ordersList = setBatchOrder({
            orders: codes
        });

        if (term) {
            Object.assign(ordersList, { searchBy: term });
        }

        if (ordersList) {
            const responseList = await sendBatchOrder({
                payload: ordersList,
                operation: 'reversed'
            });

            const toasts = responseList.filter((r) => r.type === 'toast');

            toasts.forEach((response) => {
                enqueueSnackbar(response.data,
                  {
                    action: (id) => handleCloseAction(id, closeSnackbar)
                  });
            });

            const ordersResponse = responseList
                .filter((r) => r.type !== 'toast')
                .reduce((acc, curr) => {
                    acc.push(...curr.data);
                    return acc;
                }, []);

            const newOrders = [...ordersResponse, ...orders];

            setOrders(newOrders);
        }
    }

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Container>
            <Stack spacing={2} direction="column" justifyContent="flex-start" alignItems="center">
                <Title>CONFIRMAR REVERSA</Title>
                <FormControl variant="filled" style={{ width: '300px' }} required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    inputRef={inputRef}
                    disabled={!origin}
                    id="outlined-multiline-flexible"
                    label="Digite aqui para buscar..."
                    style={{ width: '300px' }}
                    multiline
                    rows={4}
                />

                <Button
                    type="button"
                    style={{ width: '300px' }}
                    variant="contained"
                    onClick={() => handleBatchReversingConfirm()}>
                    Enviar
                </Button>
            </Stack>

            <LowerSection>
                <OperationPackagesFeedback
                    orders={orders}
                    handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                />
            </LowerSection>
        </Container>
    );
};

export default ReversingConfirm;
