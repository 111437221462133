import { Box, CircularProgress } from '@mui/material';

const Loader = ({ style, color = 'black', size = '2rem' }) => {
    return (
        <Box
            style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color,
                ...style
            }}>
            <CircularProgress size={size} color="inherit" />
        </Box>
    );
};

export default Loader;
