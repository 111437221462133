import React from 'react';
import { Container, Form, Title } from './styles';
import { Button } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import api from '../../../services/api';
import handleCloseAction from '../../../components/HandleCloseAction';
import { logError } from '../../../utils/error';

const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('Campo obrigatório'),
    newPasswordConfirm: Yup.string().required('Campo obrigatório')
});

const ChangePassword = () => {
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            newPasswordConfirm: ''
        },
        validationSchema,
        onSubmit: handlePasswordChange
    });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { user } = useAuth();

    async function handlePasswordChange({ newPassword, newPasswordConfirm }) {
        if (newPassword !== newPasswordConfirm) {
            enqueueSnackbar(
                'Sua nova senha não corresponde com sua confirmação, digite novamente.',
                { variant: 'error',
                  action: (id) => handleCloseAction(id, closeSnackbar)
                }
            );

            return;
        }

        if (!!user && !!user.id) {
            try {
                await api.put(`/profile/${user.id}`, {
                    password: newPassword
                });

                enqueueSnackbar('Sua senha foi alterada com sucesso!!', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } catch (error) {
                logError(error);

                enqueueSnackbar('Não autorizado',
                { variant: 'error',
                  action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }
        } else {
            enqueueSnackbar('Não autorizado',
            { variant: 'error',
              action: (id) => handleCloseAction(id, closeSnackbar)
            });
        }
    }

    return (
        <Container>
            <Title>Para alterar sua senha preencha corretamente o formulário abaixo.</Title>
            <Form onSubmit={formik.handleSubmit}>
                <TextField
                    id="newPassword"
                    type="password"
                    label="Nova senha"
                    autocomplete="new-password"
                    onChange={formik.handleChange}
                    value={formik.newPassword}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
                <TextField
                    id="newPasswordConfirm"
                    type="password"
                    autocomplete="new-password"
                    label="Confirmar senha"
                    onChange={formik.handleChange}
                    value={formik.newPasswordConfirm}
                    error={
                        formik.touched.newPasswordConfirm &&
                        Boolean(formik.errors.newPasswordConfirm)
                    }
                    helperText={
                        formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm
                    }
                />
                <Button type="submit" style={{ width: '150px' }}>
                    Enviar
                </Button>
            </Form>
        </Container>
    );
};

export default ChangePassword;
