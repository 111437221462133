import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Warning from '@mui/icons-material/ReportProblem';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import statusColor from '../../../../../utils/statusColors';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const BlockedDeliveriesRows = ({row}) => {
  const [open, setOpen] = useState(false);

  const historyFormatted = row.history.map(h => {
    return {
      ...h,
      requestDate: new Date(h.requestDate).toLocaleString("pt-BR"),
      slaResolution: new Date(h.slaResolution).toLocaleString("pt-BR")
    }
  })

  const formattedPhoneNumber = row.phone ? `${row.phone.substring(0, 5)}-${row.phone.substring(5)}` : undefined;



  return (
		<>
			<StyledTableRow	 scope="row" sx={{ height: '55px', cursor: 'pointer' }}
				hover
			>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
        <Chip
         label={row.currentOccurrence}
         icon={<Warning sx={{ fontSize: '18px'}} />}
         color='warning'
         variant="outlined"
         sx={{ paddingLeft: '0.25rem'}}
        />
				</TableCell>
        <TableCell  scope="row">
				 <strong>{row.code}</strong>
				</TableCell>
				<TableCell><Chip sx={{ background: statusColor[row.status].background , color: statusColor[row.status].color}} label={row.status} /></TableCell>
				<TableCell>{row.driver}</TableCell>
				<TableCell>{formattedPhoneNumber}</TableCell>
			</StyledTableRow>
			<StyledTableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant="h6" gutterBottom component="div"
              >
								Historico de notificações
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Tipo de Notificação</TableCell>
										<TableCell>Data da solicitação</TableCell>
										<TableCell>Prazo limite para Resolução</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{historyFormatted.map((h) => (
										<TableRow key={h.index}>
											<TableCell>{h.occurrence}</TableCell>
											<TableCell>{h.requestDate}</TableCell>
											<TableCell>{h.slaResolution}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</StyledTableRow>
		</>

  )
}

export default BlockedDeliveriesRows
