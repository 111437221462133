import { Box, Button } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ReportTransportedPackages } from "../../components/ReportTransportedPackages";

const PrintButton = ({ packageList, driverId, originDock, destinationDock }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <Button
        variant='contained'
        style={{ backgroundColor: '#1253da', width: '240px', height: '40px' }}
        onClick={() => handlePrint()}>
        Gerar Romaneio
      </Button>

      <Box
        ref={componentRef}
        position='absolute'
      >
        <ReportTransportedPackages
          packageList={packageList}
          driverId={driverId}
          originDockId={originDock}
          destinationDockId={destinationDock}
        />
      </Box>
    </>
  )
}

export { PrintButton }
