import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import ListItem from './ListItem';
import Panels from './Panels';

export const OperationPackagesFeedback = ({ orders, handleUpdatedPackageOnTrack = () => {} }) => {
    const orderList = useMemo(() => {
        return orders.map((order) => {
            const { number, description } = order;

            const status = !order.state ? 'error' : order.state;

            const orderObj = {
                number,
                status
            };

            if (!!description) {
                Object.assign(orderObj, { description });

                if (String(description).includes('área não atendida')) {
                    orderObj.status = 'offCoverage';
                }
            } else {
                Object.assign(orderObj, {
                    description: status === 'success' ? 'Pacote atualizado' : 'erro'
                });
            }

            return orderObj;
        });
    }, [orders]);

    return (
        <Stack
            width="100%"
            minWidth="400px"
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}>
            <Panels orders={orders} />
            <Stack
                width="100%"
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}>
                {orderList.map((order) => (
                    <ListItem
                        key={order.number}
                        order={order}
                        handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
