import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getMenu } from '../../store/menuSlice';
import Appbar from './Appbar';
import Menu from './Menu';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const Layout = ({ children }) => {
    const smallScreens = useMediaQuery(`(max-width:600px)`);

    const { drawer } = useSelector(getMenu());

    const showMenu = React.useMemo(() => (smallScreens ? drawer : true), [drawer, smallScreens]);

    return (
        <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
            <CssBaseline />

            <Appbar />

            {showMenu && <Menu />}

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    width: drawer ? `calc(100vw - 320px)` : 'calc(100vw - 80px)',

                    height: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}>
                <DrawerHeader />
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
