import React, { useMemo, useState } from 'react';
import { Container, Title, Order, Textarea, Input, Select, Label, Option } from './styles';
import { Button } from '../../../../../components';
import DockSelect from '../../DockSelect';
import { isValid } from '../../../../../utils/validations';
import { useSnackbar } from 'notistack';
import api from '../../../../../services/api';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { logError } from '../../../../../utils/error';

const BackofficeOccurrence = ({ order, onOccurrence }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [comment, setComment] = useState('');
    const [occurrence, setOccurrence] = useState(undefined);
    const [charge, setCharge] = useState(() => {
        const { invoices } = order;

        const orderValue = !!invoices && invoices.length > 0 ? `R$${invoices[0].value}` : 'R$0';

        return orderValue;
    });

    const [reference, setReference] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
            .toISOString()
            .split('T')[0]
    );

    const [dock, setDock] = useState(null);

    const occurrenceTypes = useMemo(() => {
        return order.order.integration.occurrences;
    }, [order.order.integration.occurrences]);

    const haveLostCargo = useMemo(() => {
        if (!!occurrence) {
            const currentOccurrence = occurrenceTypes.filter((o) => o.id === occurrence)[0];

            if (currentOccurrence) {
                const { config } = currentOccurrence;
                if (config && (config.flagAsLostCargo || config.flagAsInjuredCargo)) {
                    return true;
                }
            }
        }
        return false;
    }, [occurrence, occurrenceTypes]);

    function handleOccurrence() {
        const item = {
            term: order.alphaCode,
            data: {
                occurrence,
                description: comment
            }
        };

        const value = Number(charge.match(/\d+\.?\d+/gi).join(''));

        if (haveLostCargo && !!value) {
            const lostLoad = {
                driverId: order.driverId,
                packageId: order.id,
                charge: value,
                description: comment,
                reference
            };

            Object.assign(item.data, { lostLoad });
        }

        const occurrenceObject = {
            packages: [item]
        };

        if (haveLostCargo && !!dock) {
            Object.assign(occurrenceObject, { dock });
        }

        api.post('/operations/occurrence', occurrenceObject)
            .then((response) => {
                const { errors } = response.data;
                if (errors.length > 0) {
                    errors.forEach((e) => {
                        enqueueSnackbar(e.err, {
                            variant: 'error',
                            action: (id) => handleCloseAction(id, closeSnackbar)
                        });
                    });
                } else {
                    enqueueSnackbar('Ocorrência reportada com sucesso!', {
                        variant: 'success',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });

                    onOccurrence();
                }
            })
            .catch((error) => {
                logError(error);

                enqueueSnackbar('Verifique sua conexão ou se tem acesso a este recurso.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            });
    }

    function handleReference(date) {
        const formattedDate = new Date(date).toISOString();
        if (new Date().toISOString() > formattedDate) {
            enqueueSnackbar('Impossível fazer definir uma data passada para esta ação.', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
        } else {
            setReference(date);
        }
    }

    const allowSumbmit = useMemo(() => {
        const reg = new RegExp(/[\d.]/gi);

        const validCharge = charge ? Number(charge.match(reg).join('')) : 0;

        if (haveLostCargo) {
            return (
                !!occurrence && validCharge >= 0 && !!reference && !!comment.length > 0 && !!dock
            );
        } else {
            return !!occurrence && !!comment.length > 0;
        }
    }, [charge, comment.length, dock, haveLostCargo, occurrence, reference]);

    function handleSetCharge(value) {
        const reg = new RegExp(/[\d.]/gi);

        let result = value.match(reg);

        const dotCount = !!result && result.filter((i) => i === '.').length;

        if (result && dotCount < 2) {
            if (result[result.length - 1] !== '.' && result[result.length - 1] !== '0') {
                const toNumber = `R$${Number(result.join(''))}`;
                setCharge(toNumber);
            } else {
                setCharge(`R$${result.join('')}`);
            }
        } else {
            setCharge('R$0');
        }
    }

    return (
        <Container>
            <Title>Ocorrência</Title>
            <Order>Pedido: {order.alphaCode}</Order>
            <Select value={occurrence} onChange={(e) => setOccurrence(e.target.value)}>
                <Option hidden value={undefined}>
                    Ocorrência
                </Option>
                {occurrenceTypes.map((o) => (
                    <Option key={o.id} value={o.id}>
                        {o.occurrence}
                    </Option>
                ))}
            </Select>
            {haveLostCargo && (
                <>
                    <Input
                        type="text"
                        placeholder="Valor..."
                        value={charge}
                        onChange={(e) => handleSetCharge(e.target.value)}
                    />
                    <Label>Data de referência</Label>
                    <Input
                        style={{ margin: '0px', padding: '0px 10px 10px 0px' }}
                        type="date"
                        value={reference}
                        onChange={(e) => handleReference(e.target.value)}
                    />
                    <DockSelect
                        onChange={(e) => (isValid(e) ? setDock(e) : setDock(null))}
                        placeholder="Doca referência"
                    />
                </>
            )}
            <Textarea
                placeholder="Comente aqui..."
                style={{ marginTop: '10px' }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
            />
            <Button
                onClick={() => handleOccurrence()}
                style={{ marginTop: '10px' }}
                disabled={!allowSumbmit}>
                Registrar
            </Button>
        </Container>
    );
};

export default BackofficeOccurrence;
