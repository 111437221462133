import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Loader from '../../../../components/Loader';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../services/api';

const DeliveryModalities = ({ style, onChange = () => {} }) => {
    const { isLoading, data: modalities } = useQuery(
        ['operations/delivery/modalities'],
        async () => {
            const { data } = await api.get('/operations/delivery/modalities');

            return data;
        },
        {
            placeholderData: () => {
                return [];
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    return isLoading ? (
        <Loader />
    ) : (
        <FormControl defaultValue="" variant="filled">
            <InputLabel id="deliveryModality">Veículo</InputLabel>
            <Select
                defaultValue=""
                labelId="deliveryModality"
                id="deliveryModality"
                label="Veículo"
                onChange={(e) => onChange(e.target.value)}
                style={style}>
                {modalities &&
                    modalities.length &&
                    modalities.map((m) => (
                        <MenuItem value={m.id} key={m.id}>
                            {m.label || m.modality}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default DeliveryModalities;
