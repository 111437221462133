import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../../routes/Route';

import Collect from '../pages/Collect';
import CollectExternal from '../pages/CollectExternal';
import Dispatch from '../pages/Dispatch';
import Relocate from '../pages/Relocate';
import Reversing from '../pages/Reversing';
import ReversingConfirm from '../pages/ReversingConfirm';
import Transfer from '../pages/Transfer';
import Search from '../pages/Search';
import Storage from '../pages/Storage';
import PickUpAtStore from '../pages/PickUpAtStore';
import LateIntegrations from '../pages/LateIntegrations';
import Tags from '../pages/Tags';
import ListOfTransportedPackages from '../pages/ListOfTransportedPackages';
import BatchInteraction from '../pages/BatchInteraction';

const OperationRoutes = () => {
    return (
        <Switch>
            <Route path="/retira-na-loja" component={PickUpAtStore} isPrivate />
            <Route path="/coletar" component={Collect} isPrivate />
            <Route path="/coleta-externa" component={CollectExternal} isPrivate />
            <Route path="/despachar" component={Dispatch} isPrivate />
            <Route path="/realocar" component={Relocate} isPrivate />
            <Route path="/reverter" component={Reversing} isPrivate />
            <Route path="/confirmar-reversa" component={ReversingConfirm} isPrivate />
            <Route path="/buscar" component={Search} isPrivate />
            <Route path="/transbordo" component={Transfer} isPrivate />
            <Route path="/armazenar" component={Storage} isPrivate />
            <Route path="/integracoes-tardias" component={LateIntegrations} isPrivate />
            <Route path="/etiquetas-operacao" component={Tags} isPrivate />
            <Route path="/romaneio" component={ListOfTransportedPackages} isPrivate />
            <Route path="/interacao-em-lote" component={BatchInteraction} isPrivate />
        </Switch>
    );
};

export default OperationRoutes;
