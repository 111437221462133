import axios from 'axios';

const headers = {
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Opener-Policy': 'same-origin'
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers
});

export default api;
