import { Container, Total, Description, Footer } from './styles';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

const DoughnutChart = ({ data }) => {
    return (
        <Container>
            <Total>{data.total}</Total>
            <Chart type="doughnut" data={data.data} />
            <Footer>
                <Description>INTEGRADOS HOJE</Description>
            </Footer>
        </Container>
    );
};

export default DoughnutChart;
