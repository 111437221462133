import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
`;

export const DriverItem = styled.div`
    width: fit-content;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const DriverTitle = styled.div`
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 300;
    margin-left: 10px;
`;

export const PackageListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
`;

export const NoDriverSelectedWarning = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    margin-top: 20px;
`;

export const ConfirmTransferenceButton = styled.button`
    background: #1253da;
    border-radius: 3px;
    height: 36px;
    width: 139px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: ${(props) => props.theme.secondary};
    border: none;

    &:active {
        background-color: ${(props) => props.theme.primary};
    }

    &:disabled {
        opacity: 0.5;
    }
`;
