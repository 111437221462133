import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { Card, Input } from '../../../../components';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import DriverCard from '../../components/DriverCard';
import {
    CardContainer,
    Container,
    Header,
    Left,
    Right,
    TotalCardContainer,
    TotalCardLabel,
    TotalCardNumber
} from './styles';

const Monitoring = () => {
    const [search, setSearch] = useState('');
    const [filterBy, setFilterBy] = useState(null);
    const [late, setLate] = useState(0);
    const [ontime, setOntime] = useState(0);
    const [invalid, setInvalid] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function setMonitoringCounters(drivers) {
        setLate(0);
        setOntime(0);
        setInvalid(0);

        const isValid = Object.keys(drivers).length > 0;

        if (!isValid) {
            return false;
        }

        const deliveries =
            isValid &&
            drivers.reduce((acc, curr) => {
                acc.push(...curr.deliveries);

                return acc;
            }, []);

        const lateDeliveries = deliveries.filter(
            (d) => !!d.sla && new Date(d.sla).toISOString() < new Date().toISOString()
        ).length;

        const ontimeDeliveries = deliveries.filter(
            (d) => !!d.sla && new Date(d.sla).toISOString() > new Date().toISOString()
        ).length;

        const invalidDeliveries = deliveries.filter((d) => !d.sla).length;

        setInvalid(invalidDeliveries);
        setLate(lateDeliveries);
        setOntime(ontimeDeliveries);
    }

    const { isLoading, data } = useQuery(
        ['drivers/monitoring'],
        async () => {
            const { data } = await api.get('/drivers/monitoring');

            setMonitoringCounters(data.drivers);

            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Monitoramento atualizado...', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const lastUpdate = useMemo(() => {
        if (!data) return null;

        return new Date(data.updatedAt).toLocaleString('pt-BR');
    }, []);

    function handleFilterSlaType(type) {
        const valid = type === 'ontime' || type === 'late' || type === 'invalid';

        const newType = valid ? (type === filterBy ? null : type) : null;

        setFilterBy(newType);
    }

    function handleFilter(data) {
        function lateFilter(d) {
            return d.filter(
                (d) => !!d.sla && new Date(d.sla).toISOString() < new Date().toISOString()
            );
        }
        function ontimeFilter(d) {
            return d.filter(
                (d) => !!d.sla && new Date(d.sla).toISOString() > new Date().toISOString()
            );
        }

        function invalidFilter(d) {
            return d.filter((d) => !d.sla);
        }

        const slaFilter = (deliveries) => {
            if (!filterBy) return deliveries;

            if (filterBy === 'late') return lateFilter(deliveries);

            if (filterBy === 'ontime') return ontimeFilter(deliveries);

            if (filterBy === 'invalid') return invalidFilter(deliveries);
        };

        const filteredDrivers = data.map((d) => {
            return {
                ...d,
                deliveries: slaFilter(d.deliveries)
            };
        });

        const drivertsWithDeliveries = filteredDrivers.filter((d) => d.deliveries.length > 0);

        const searchFilters = drivertsWithDeliveries
            .filter(
                (d) =>
                    d.driver.toLowerCase().includes(search.toLowerCase()) ||
                    d.id.toLowerCase().includes(search.toLowerCase()) ||
                    d.deliveries.filter((del) =>
                        del.neighborhood.toLowerCase().includes(search.toLowerCase())
                    ).length >= 1 ||
                    d.deliveries.filter((del) =>
                        del.recipient.toLowerCase().includes(search.toLowerCase())
                    ).length >= 1 ||
                    d.deliveries.filter((del) =>
                        del.package.toLowerCase().includes(search.toLowerCase())
                    ).length >= 1
            )
            .sort((a, b) => {
                if (a.driver > b.driver) return 1;

                if (b.driver > a.driver) return -1;

                return 0;
            });

        return searchFilters;
    }

    if (!data || (!data.drivers && !isLoading))
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    if (isLoading) {
        return <Loader color="#000" />;
    }

    return (
        <Container>
            <Header>
                <Left>
                    <Input
                        placeholder="Digite para buscar..."
                        style={{ width: '90vw', maxWidth: '250px' }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Left>
                <Right>
                    <Card>
                        <TotalCardContainer onClick={() => handleFilterSlaType(null)}>
                            <TotalCardNumber>{data.drivers.length}</TotalCardNumber>
                            <TotalCardLabel>Motoristas</TotalCardLabel>
                        </TotalCardContainer>
                    </Card>
                    <Card>
                        <TotalCardContainer
                            onClick={() => handleFilterSlaType('ontime')}
                            selected={filterBy === 'ontime'}>
                            <TotalCardNumber>{!!ontime ? ontime : 0}</TotalCardNumber>
                            <TotalCardLabel>Total Pendente</TotalCardLabel>
                        </TotalCardContainer>
                    </Card>
                    <Card>
                        <TotalCardContainer
                            onClick={() => handleFilterSlaType('late')}
                            selected={filterBy === 'late'}>
                            <TotalCardNumber>{!!late ? late : 0}</TotalCardNumber>
                            <TotalCardLabel>Total Atrasado</TotalCardLabel>
                        </TotalCardContainer>
                    </Card>
                    {!!invalid && (
                        <Card>
                            <TotalCardContainer
                                onClick={() => handleFilterSlaType('invalid')}
                                selected={filterBy === 'invalid'}>
                                <TotalCardNumber>{!!invalid ? invalid : 0}</TotalCardNumber>
                                <TotalCardLabel>Não coletado</TotalCardLabel>
                            </TotalCardContainer>
                        </Card>
                    )}
                    {lastUpdate && (
                        <Card>
                            <TotalCardContainer onClick={() => handleFilterSlaType(null)}>
                                <TotalCardLabel>Atualizado em</TotalCardLabel>
                                <TotalCardNumber></TotalCardNumber>
                                {lastUpdate}
                            </TotalCardContainer>
                        </Card>
                    )}
                </Right>
            </Header>
            <CardContainer>
                {data.drivers && data.drivers.length > 0 ? (
                    handleFilter(data.drivers).map((d) => <DriverCard data={d} key={d.id} />)
                ) : isLoading ? (
                    <Loader color="#000" />
                ) : data.drivers.length === 0 ? (
                    <Alert
                        severity="success"
                        sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                        Nenhuma atualização
                    </Alert>
                ) : (
                    <Alert
                        severity="error"
                        sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                        Ocorreu um erro
                    </Alert>
                )}
            </CardContainer>
        </Container>
    );
};

export default Monitoring;
