import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../components/Loader";
import api from "../../../../services/api";


const DriverSupplierFilter = ({ onChange }) => {

  const { isLoading, data: drivers } = useQuery(
    [`operations/drivers/9`],
    async () => {
      const { data } = await api.get(
        `/operations/drivers/9`
      );

      return data;
    },
    {
      placeholderData: () => {
        return [];
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  );

  function handleUpdateDriver(field) {

    const newResponse = {
      ...field,
      supplier: 9
    }

    onChange(newResponse);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <Stack direction="column" spacing={2} justifyContent="start">
      <FormControl variant="outlined" sx={{ width: '300px' }} >
        <InputLabel id="driver">Motoristas</InputLabel>
        <Select
          defaultValue=""
          labelId="driver"
          id="driver"
          label="Motoristas"
          onChange={(e) => handleUpdateDriver({ driver: e.target.value })}
        >
          {!!drivers &&
            drivers.map((d) => (
              <MenuItem value={Number(d.id)} key={Number(d.id)}>
                {d.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  )
}

export { DriverSupplierFilter }
