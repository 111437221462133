import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
`;

export const Page = styled.div`
  /* must discount padding in paper width and height */
  width: 180mm;
  height: 50mm;
  padding: 24mm 0 0 30mm;
  text-align: left;
  margin: 0;
  page-break-after: always;
  * > & {
    text-transform: uppercase;
  }
`;
export const Tag = styled.div`
  width: 150mm;
  height: 49mm;
  border: 0.1mm solid #cccccc;
  margin: 0 0 0.1mm 0;
  border-radius: 2mm;
`;
export const Logo = styled.img`
  width: 30mm;
  margin-right: 5mm;
`;

export const Row = styled.div`
  display: block;
  margin: 2mm;
  font-size: 3mm;
  position: relative;
`;
export const BarcodeContainer = styled.div`
  position: absolute;
  right: 10mm;
  transform: translateY(-20px);
  float: left;
`;

