import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 70vh;
    flex: 1;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div`
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
`;
export const Text = styled.div`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const InfoTitle = styled.div`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 16px 0px 8px 0px;
    text-align: center;
`;

export const InfoText = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    margin: 5px 0px;
`;

export const Icon = styled.span`
    /* color: green; */
    font-size: 48px;
    color: ${(props) => props.theme[props.type]};
`;

export const Success = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
`;

export const ErrorList = styled.ol`
    max-width: 80vw;
    list-style-type: decimal-leading-zero;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ErrorItem = styled.li`
    text-align: left;
    padding: 16px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
export const ErrorTitle = styled.div`
    font-size: 1.4rem;
    font-family: Montserrat;
    font-weight: 500;
    color: ${(props) => props.theme.dark};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
export const ErrorDescriptionList = styled.ol`
    list-style: decimal;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ErrorDescriptionItem = styled.li`
    font-size: 1.2rem;
    font-family: Montserrat;
    font-weight: 400;
    color: ${(props) => props.theme.dark};
    padding: 4px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
