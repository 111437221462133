import { Box, Chip, CircularProgress } from '@mui/material';

import { Container } from './styles';

const TagStatus = ({ status }) => {

  function handleTagColorIfStatus(status) {
    if (status === 'Completed') return 'success'
    if (status === 'Processing') return 'warning'
    return 'error'
  }

  function handleTagLabelIfStatus(status) {
    if (status === 'Completed') return 'Finalizado'
    if (status === 'Processing')
      return <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
        Em processamento
        <CircularProgress sx={{ color: '#FFFF' }} size='1rem' />
      </Box>
    return 'Falha'
  }

  return (
    <Container>
      <Chip
        variant="filled"
        color={handleTagColorIfStatus(status)}
        size="small"
        label={handleTagLabelIfStatus(status)}
        sx={{
          m: '1mm',
          fontWeight: '500'
        }}
      />
    </Container>
  )

};

export { TagStatus };
