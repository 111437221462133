import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { Table } from '../../components/Table';
import { Container, themes } from './styles';

const DeliveriesMonth = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, data: kpis } = useQuery(
    ['kpis'],
    async () => {
      const { data } = await api.get('/kpis');

      return data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Dashboards atualizados!',
        { variant: 'success',
          action: (id) => handleCloseAction(id, closeSnackbar)
        });
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  );

  function compareOnTimeDeliveries(deliveries, onTime) {
    if (deliveries - onTime === 0) return 'good';
    return 'bad';
  }

  const deliveriesContracts = useMemo(() => {
    if (!kpis) return 0;
    const data = _.groupBy(kpis.items.deliveries.contracts, 'contract');
    return Object.keys(data).map((key) => {
      const deliveries = data[key].reduce(
        (total, { deliveries }) => total + parseFloat(deliveries),
        0
      );
      const onTime = data[key].reduce((total, { onTime }) => total + parseFloat(onTime), 0);
      const [{ contractedSla }] = data[key];
      return {
        contract: key,
        deliveries,
        onTime,
        sla: (onTime / deliveries) * 100,
        contractedSla
      };
    });
  }, [kpis]);

  const deliveriesCustomer = useMemo(() => {
    if (!kpis) return [];
    const data = _.groupBy(kpis.items.deliveries.customer, 'customer');
    return Object.keys(data).map((key) => {
      const deliveries = data[key].reduce(
        (total, { deliveries }) => total + parseFloat(deliveries),
        0
      );
      const onTime = data[key].reduce((total, { onTime }) => total + parseFloat(onTime), 0);
      const [{ contractedSla }] = data[key];
      return {
        customer: key,
        deliveries,
        onTime,
        sla: (onTime / deliveries) * 100,
        contractedSla
      };
    });
  }, [kpis]);

  if (!kpis && !isLoading)
    return (
      <Alert
        severity="success"
        sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
        Nenhuma atualização
      </Alert>
    );

  return isLoading ? (
    <Loader color="#000" />
  ) : (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>Contrato</th>
            <th>ENTREGAS (NO PRAZO)</th>
          </tr>
        </thead>
        <tbody>
          {deliveriesContracts.map((contract) => (
            <tr
              key={contract.contract}
              style={themes(
                compareOnTimeDeliveries(contract.deliveries, contract.onTime)
              )}>
              <td>{contract.contract}</td>
              <td>
                {new Intl.NumberFormat('pt-BR').format(contract.deliveries)}(
                {new Intl.NumberFormat('pt-BR').format(contract.onTime)})
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>SLA POR CONTRATO HOJE</td>
          </tr>
        </tfoot>
      </Table>
      <Table>
        <thead>
          <tr>
            <th>CLIENTE</th>
            <th>ENTREGAS (NO PRAZO)</th>
          </tr>
        </thead>
        <tbody>
          {deliveriesCustomer.map((contract) => (
            <tr
              key={contract.customer}
              style={themes(
                compareOnTimeDeliveries(contract.deliveries, contract.onTime)
              )}>
              <td>{contract.customer}</td>
              <td>
                {new Intl.NumberFormat('pt-BR').format(contract.deliveries)}(
                {new Intl.NumberFormat('pt-BR').format(contract.onTime)})
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>ENTREGAS POR CLIENTE</td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
};

export default DeliveriesMonth;
