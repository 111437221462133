import React from 'react';
import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from './globalStyle';
import { HashRouter } from 'react-router-dom';

const App = () => {
    return (
        <HashRouter>
            <AppProvider>
                <GlobalStyle />
                <Routes />
            </AppProvider>
        </HashRouter>
    );
};

export default App;
