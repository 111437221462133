import { DoNotDisturb, Download } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMemo } from "react";
import { StyledTableCell, StyledTableRow } from ".";
import api from "../../../../services/api";
import { TagFile } from "../TagFile";
import { TagStatus } from "../TagStatus";
import { TagType } from "../TagType";

const RowForTable = ({ row }) => {


  const TagTypeCte = useMemo(() => {
    if (!row.status) return <Typography width='100%' textAlign="center">N/D</Typography>
    return row.status.map((r, index) => (<TagType key={index} type={r} />));
  }, [row.status])



  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  function downloadXmlForCte(filename) {
    api.get(`cte/file/batch/${filename}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(function (response) {
        const blob = new Blob([response.data], {
          type: 'application/zip'
        });
        downloadBlob(blob, filename)
      })
      .catch(function (error) {
        console.log(error)
      });
  }



  const createdRowFormat = useMemo(() => format(new Date(row.createdAt), 'dd/MM/yyyy HH:mm'), [row.createdAt])
  const monthStartFormat = useMemo(() => format(new Date(row.monthStart), 'dd/MM/yyyy'), [row.monthStart])
  const monthEndFormat = useMemo(() => format(new Date(row.monthEnd), 'dd/MM/yyyy'), [row.monthEnd])


  return (
    <StyledTableRow>
      <StyledTableCell sx={{ fontWeight: 'bold' }}>{row.customerCnpj}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 'bold' }}>{createdRowFormat}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 'bold' }}>{monthStartFormat}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 'bold' }}>{monthEndFormat}</StyledTableCell>
      <StyledTableCell sx={{ fontWeight: 'bold' }}>{TagTypeCte}</StyledTableCell>
      <StyledTableCell><TagFile status={row.fileExtension} /></StyledTableCell>
      <StyledTableCell><TagStatus status={row.requestStatus} /></StyledTableCell>
      <StyledTableCell sx={{ cursor: 'pointer' }} onClick={() => downloadXmlForCte(row.filename)}>
        {row.requestStatus === "Completed" ? <Download color="success" /> : <DoNotDisturb color='error' />}
      </StyledTableCell>
    </StyledTableRow>
  )
}

export { RowForTable }
