
import { Delete } from "@mui/icons-material";
import { Box, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PackageTable = ({ packageList, deletePackage }) => {

  return (
    <TableContainer component={Paper} sx={{ height: '80vh', overflow: 'auto' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow >
            <StyledTableCell>N°</StyledTableCell>
            <StyledTableCell>Código do Pacote</StyledTableCell>
            <StyledTableCell>Contrato</StyledTableCell>
            <StyledTableCell>Bairro</StyledTableCell>
            <StyledTableCell>Cidade</StyledTableCell>
            <StyledTableCell>Estado</StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody >
          {packageList.map((row, index) => (
            <StyledTableRow key={row.code + row.count}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{row.code}</StyledTableCell>
              <StyledTableCell>{row.contractName}</StyledTableCell>
              <StyledTableCell>{row.neighborhood}</StyledTableCell>
              <StyledTableCell>{row.city}</StyledTableCell>
              <StyledTableCell>{row.state}</StyledTableCell>
              <StyledTableCell sx={{ cursor: 'pointer' }} onClick={() => deletePackage(row)}>
                <Delete sx={{ color: 'red' }} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { PackageTable }
