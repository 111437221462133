import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartProps,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    BarController
} from 'chart.js';

ChartProps.register(CategoryScale, LinearScale, BarElement, BarController, Title, Tooltip, Legend);

const options = {
    responsive: true,
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    }
};

const BarChart = ({ data }) => {
    return (
        <Chart
            type="bar"
            data={data}
            options={options}
            style={{ maxHeight: '85vh', marginTop: '16px' }}
        />
    );
};

export default BarChart;
