import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';

const Step2 = ({ onChange, data: { description } }) => {
    return (
        <Stack
            width="100%"
            height="100%"
            spacing={2}
            padding={4}
            direction="column"
            justifyContent="flex-start"
            alignItems="center">
            <Typography fontSize="2rem" fontWeight="600">
                Interação
            </Typography>

            <Typography fontSize="1rem" fontWeight="400" textAlign="center">
                Descreva a interação em detalhes, e lembre-se de que isso ficará registrado no
                histórico do pacote.
            </Typography>

            <TextField
                onChange={onChange}
                value={description}
                name="description"
                multiline
                minRows={8}
                maxRows={14}
                variant="filled"
                label="Descrição da interação..."
                fullWidth
            />
        </Stack>
    );
};

export default Step2;
