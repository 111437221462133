import React, { useState } from 'react';
import { Container, Title, Order, Input } from './styles';
import { Button } from '../../../../../components';
import DockSelect from '../../DockSelect';
import { useSnackbar } from 'notistack';
import api from '../../../../../services/api';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { logError } from '../../../../../utils/error';

const BackofficeStorePackage = ({ order, onInteract }) => {
    const [comment, setComment] = useState('');
    const [dock, setDock] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleStorePackage() {
        const storePackageObject = {
            dock,
            packages: [
                {
                    term: order.alphaCode,
                    data: {
                        description: comment
                    }
                }
            ]
        };

        api.post('/operations/storage', storePackageObject)
            .then((response) => {
                const { errors } = response.data;
                if (errors.length > 0) {
                    enqueueSnackbar('Não foi possível completar a ação', {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                } else {
                    enqueueSnackbar('Interação realizada com sucesso!', {
                        variant: 'success',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                    onInteract();
                }
            })
            .catch((error) => {
                logError(error);

                enqueueSnackbar('Verifique sua conexão ou se tem acesso a este recurso.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            });
    }

    return (
        <Container>
            <Title>Armazenar</Title>
            <Order>Pedido: {order.alphaCode}</Order>
            <DockSelect onChange={(e) => setDock(e)} required />
            <Input
                placeholder="Comente aqui..."
                style={{ marginTop: '10px' }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
            />
            <Button
                onClick={() => handleStorePackage()}
                style={{ marginTop: '10px' }}
                disabled={comment.length < 5 || !dock}>
                Registrar
            </Button>
        </Container>
    );
};

export default BackofficeStorePackage;
