import iconv from 'iconv-lite';
import React from 'react';
import { useCSVReader, usePapaParse } from 'react-papaparse';
import { notLatinChar } from '../../utils/charsets';
import { Container, DropContainer, DropText, DropTextImportant, UploadIcon } from './styles';
import { Button } from '../../../../components';

const FileInput = ({ handleFile, onRemove = () => { } }) => {
    const { readString } = usePapaParse();
    const { CSVReader } = useCSVReader();

    function decode(data, charset) {
        iconv.skipDecodeWarning = true;
        const decoded = iconv.decode(data, charset);

        const untranslatable = !!decoded.match(notLatinChar);

        if (untranslatable) {
            return null;
        }

        return decoded;
    }

    function handleOnDrop({ data }) {
        const keys = data[0];

        const csv = data.slice(1, data.length).map((item) => {
            const response = {};

            keys.forEach((k, i) => Object.assign(response, { [k]: item[i] }));

            return response;
        });

        const onComplete = (results, file) => {
            handleFile(csv);
        };

        const onError = (results, file) => {
            handleFile(null);
        };

        readString(JSON.stringify(csv), {
            worker: true,
            header: true,
            complete: onComplete,
            error: onError
        });
    }

    const styles = {
        csvReader: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
        },
        browseFile: {
            width: '20%'
        },
        acceptedFile: {
            border: '1px solid #ccc',
            height: 45,
            lineHeight: 2.5,
            paddingLeft: 10,
            width: '80%'
        },
        remove: {
            borderRadius: 0,
            padding: '0 20px'
        },
        progressBarBackgroundColor: {
            backgroundColor: 'red'
        }
    };

    return (
        <Container>
            {/* <CSVReader
                onUploadAccepted={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={onRemove}
                accept=".csv">
                <DropContainer>
                    <UploadIcon />
                    <DropText>Arraste e solte seu arquivo .CSV</DropText>
                    <DropText>OU</DropText>
                    <DropTextImportant>ESCOLHA DO COMPUTADOR</DropTextImportant>
                </DropContainer>
            </CSVReader> */}

            <CSVReader onUploadAccepted={handleOnDrop}>
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                    <>
                        <DropContainer {...getRootProps()}>
                            <UploadIcon />

                            {acceptedFile ? (
                                <>
                                    <DropTextImportant>{acceptedFile.name}</DropTextImportant>
                                    <Button {...getRemoveFileProps()} style={{ marginTop: '16px' }}>
                                        Remover arquivo
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <DropText>Arraste e solte seu arquivo .CSV</DropText>
                                    <DropText>OU</DropText>
                                    <DropTextImportant>ESCOLHA DO COMPUTADOR</DropTextImportant>
                                </>
                            )}
                        </DropContainer>
                        <ProgressBar />
                    </>
                )}
            </CSVReader>
        </Container>
    );
};

export default FileInput;
