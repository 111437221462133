import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > p {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 1200px;

  text-align: center;
`;
export const Caption = styled.caption`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 8px;
`;
export const THead = styled.thead`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.secondary};
`;

export const Tr = styled.tr`
  padding: 4px;

  tbody > & {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.dark};
  }
  tbody > &:nth-child(2n) {
    background-color: ${(props) => darken(0.2, props.theme.light)};
    color: ${(props) => props.theme.secondary};
  }
`;
export const Th = styled.th`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  padding: 4px;
`;

export const Td = styled.td`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  padding: 8px;
`;
