import { Alert, Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import ListItem from '../../../../components/ListItem';
import PrintTag from '../../../../components/PrintTag';
import { Container, Header, Icon, OrderList, ShowSelectedButton, Title } from './styles';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

const Tags = () => {
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedOnly, setSelectedOnly] = useState(false);
    const [showPrintPage, setShowPrintPage] = useState(false);

    const { isLoading, data: orders } = useQuery(
        ['retailer/tags'],
        async () => {
            const response = await api.get('/retailer/tags');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5 // 10 minutes
        }
    );

    function handleSelectOrder(code) {
        const alreadyExist = selected.includes(code);
        if (alreadyExist) {
            const newSelectedOrders = selected.filter((o) => o !== code);
            setSelected(newSelectedOrders);
        } else {
            setSelected([...selected, code]);
        }
    }

    function handleSelectAll() {
        if (!orders) return null;

        if (selected.length === orders.length) {
            setSelected([]);
        } else {
            setSelected(orders.map((o) => o.alphaCode));
        }
    }

    if (isLoading) {
        return (
            <Box sx={{ m: 4 }}>
                <Loader color="#000" />
            </Box>
        );
    }

    if (!orders || (orders.length === 0 && !isLoading))
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', maxWidth: '80%', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return (
        <Container>
            <Header>
                <Title>SELECIONADOS: {selected.length}</Title>
                <TextField
                    variant="filled"
                    label="Pesquisar"
                    onChange={(e) => setSearch(e.target.value)}
                />

                {!showPrintPage && (
                    <>
                        <ShowSelectedButton onClick={() => setSelectedOnly(!selectedOnly)}>
                            Apenas selecionados
                            <Icon className="material-icons" enable={selectedOnly}>
                                {selectedOnly ? 'toggle_on' : 'toggle_off'}
                            </Icon>
                        </ShowSelectedButton>
                        <Button variant="contained" onClick={() => handleSelectAll()}>
                            Selecionar todos
                        </Button>
                    </>
                )}
                <Button
                    variant="contained"
                    onClick={() => setShowPrintPage(!showPrintPage)}
                    disabled={selected.length === 0}>
                    {showPrintPage ? 'Voltar' : 'Confirmar'}
                </Button>
            </Header>
            {!showPrintPage ? (
                <OrderList>
                    {orders &&
                        orders.length > 0 &&
                        orders
                            .filter((o) =>
                                !selectedOnly
                                    ? String(o.code).toLowerCase().includes(search.toLowerCase()) ||
                                      o.contract.toLowerCase().includes(search.toLowerCase()) ||
                                      o.recipient.toLowerCase().includes(search.toLowerCase()) ||
                                      o.neighborhood.toLowerCase().includes(search.toLowerCase()) ||
                                      o.address.toLowerCase().includes(search.toLowerCase())
                                    : selected.includes(o.alphaCode)
                            )
                            .map((o) => (
                                <ListItem
                                    data={o}
                                    onSelect={(code) => handleSelectOrder(code)}
                                    key={o.alphaCode + Math.random()}
                                    selected={selected}
                                    initialState={selected.includes(o.alphaCode)}
                                />
                            ))}
                </OrderList>
            ) : (
                selected &&
                selected.length > 0 && (
                    <PrintTag orders={orders.filter((o) => selected.includes(o.alphaCode))} />
                )
            )}
        </Container>
    );
};

export default Tags;
