import {
    Box,
    Button,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { queryClient } from '../../../../services/queryClient';
import { TypesCheckBox } from '../TypesCheckBox';
import { logError } from '../../../../utils/error';

const CteRequestModal = ({ isOpen, onRequestClose }) => {
    function splitCodes(input) {
        const codesArray = input
            .trim()
            .split('\n')
            .map((i) => i.trim());

        const validCodes = codesArray.filter((c) => !!c);

        return validCodes;
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { mutate: requestFileFromCteMutation } = useMutation(
        async ({ startDate, endDate, customerCnpj, packages, status, fileExtension }) => {
            const formatRequest = {
                startDate: new Date(startDate).toISOString(),
                endDate: new Date(endDate).toISOString(),
                customerCnpj,
                packageCodes: splitCodes(packages),
                status,
                fileExtension
            };

            try {
                await api.post('/cte/file/batch', formatRequest);
            } catch (error) {
                logError(error);

                enqueueSnackbar('Não foi possível realizar Solicitação, tente novamente', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } finally {
                onRequestClose();
            }
        },
        {
            onSuccess: async (_) => {
                await queryClient.invalidateQueries(['/cte/file/batch']);
                enqueueSnackbar('Solicitação realizada com sucesso', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }
        }
    );

    const formik = useFormik({
        initialValues: {
            startDate: null,
            endDate: null,
            customerCnpj: '',
            packages: '',
            status: [],
            fileExtension: 'xml'
        },

        onSubmit: requestFileFromCteMutation
    });

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <Typography variant="h4" fontWeight="bold">
                Solicitação de Xml
            </Typography>
            <Box
                width="800px"
                height="500px"
                gap={3}
                display="flex"
                flex="1"
                alignItems="center"
                justifyContent="center"
                flexDirection="column">
                <Box
                    width="100%"
                    flex="1"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={3}>
                    <Box gap={4} display="flex" flexDirection="column">
                        <DesktopDatePicker
                            label="Data Inicial"
                            value={formik.values.startDate}
                            onChange={(e) => formik.setFieldValue('startDate', e)}
                            renderInput={(params) => (
                                <TextField sx={{ width: '250px' }} {...params} />
                            )}
                        />
                        <DesktopDatePicker
                            label="Data Final"
                            value={formik.values.endDate}
                            onChange={(e) => formik.setFieldValue('endDate', e)}
                            renderInput={(params) => (
                                <TextField sx={{ width: '250px' }} {...params} />
                            )}
                        />
                        <TextField
                            sx={{ width: '250px' }}
                            id="customerCnpj"
                            label="CNPJ do Cliente"
                            autoComplete="CNPJ"
                            value={formik.values.customerCnpj}
                            onChange={formik.handleChange('customerCnpj')}
                        />
                        <FormControl defaultValue="">
                            <InputLabel id="extension">Extensão do arquivo</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="extension"
                                id="extension"
                                label="Extensão do arquivo"
                                onChange={formik.handleChange('fileExtension')}>
                                <MenuItem value="*">XML e PDF</MenuItem>
                                <MenuItem value="xml">XML</MenuItem>
                                <MenuItem value="pdf">PDF</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box gap={3} display="flex" flexDirection="column">
                        <TypesCheckBox
                            typesChecked={formik.values.status}
                            setTypesChecked={formik.setFieldValue}
                        />
                        <TextField
                            id="outlined-multiline-static"
                            label="Pacotes"
                            multiline
                            rows={4}
                            onChange={formik.handleChange('packages')}
                            value={formik.values.packages}
                        />
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    sx={{ width: '250px' }}
                    type="button"
                    onClick={() => formik.handleSubmit()}>
                    Solicitar Download
                </Button>
            </Box>
        </Modal>
    );
};

export { CteRequestModal };
