import { encrypt, decrypt } from './crypto';

function setEncrypted(key, value) {
  const encrypted = encrypt(value);

  if (!encrypted) return null

  this.storage.setItem(key, encrypted);

  return encrypted
}

function getEncrypted(key) {

  const encrypted = this.storage.getItem(key);

  if (!encrypted) return null

  const decryptedData = decrypt(encrypted);

  if (!decryptedData) {
    this.removeEncrypted(key)
    return null
  }
  return decryptedData

}

function removeEncrypted(key) {
  this.storage.removeItem(key);
}

function clear() {
  this.storage.clear();
}

export const local = {
  storage: localStorage,
  setEncrypted,
  getEncrypted,
  removeEncrypted,
  clear,
};

export const session = {
  storage: sessionStorage,
  setEncrypted,
  getEncrypted,
  removeEncrypted,
  clear,
};

export const storage = {
  clear() {
    local.clear();
    session.clear();
  },
};
