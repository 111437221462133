import { Badge, Button, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const Results = ({ data: { packages, description }, handleReset }) => {
    return (
        <Stack
            width="100%"
            height="100%"
            spacing={2}
            padding={4}
            direction="column"
            justifyContent="flex-start"
            alignItems="center">
            <Typography fontSize="2rem" fontWeight="600">
                Interação realizada com sucesso
            </Typography>

            <Typography fontSize="1rem" fontWeight="400" textAlign="center">
                Confira os pedidos que foram afetados pela interação
            </Typography>

            <Typography fontSize="1rem" fontWeight="300" textAlign="center" fontStyle="italic">
                {description}
            </Typography>

            <TableContainer component={Paper} sx={{ width: 'fit-content', minWidth: '250px' }}>
                <Table sx={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Badge
                                    badgeContent={packages.length}
                                    color="primary"
                                    overlap="rectangular">
                                    Pacotes
                                </Badge>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflowY: 'auto' }}>
                        {packages.map((item) => (
                            <TableRow
                                key={item}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center">{item}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                fullWidth
                variant="contained"
                sx={{
                    maxWidth: '400px'
                }}
                onClick={() => handleReset()}>
                Reiniciar
            </Button>
        </Stack>
    );
};

export default Results;
