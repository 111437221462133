import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableItems } from './TableItems';

export const CollapsibleTable = ({packageRows}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{backgroundColor: '#1976d2'}}>
          <TableRow>
            <TableCell />
            <TableCell sx={{ color: 'white', fontSize: 'large'}}>
                Pedido
            </TableCell>
            <TableCell sx={{color: 'white',fontSize: 'large'}} align="center">
                Status Atual
            </TableCell>
            <TableCell sx={{color: 'white', fontSize: 'large'}} align="center">
                Data do Status
            </TableCell>
            <TableCell sx={{color: 'white', fontSize: 'large'}} align="center">
                Ocorrência
            </TableCell>
            <TableCell sx={{color: 'white', fontSize: 'large'}} align="center">
                Recebedor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packageRows.map((row) => (
            <TableItems key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
