const threshold = [
    { name: 'good', value: 98 },
    { name: 'attention', value: 95 },
    { name: 'bad', value: 0 }
];

function getStyle(sla) {
    if (isNaN(sla)) return null;
    const [{ name }] = threshold.filter(({ value }) => sla >= value);
    return name;
}

export default getStyle;
