import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { cloneElement, useMemo, useState } from 'react';
import api from '../../../../services/api';
import Finishing from './Finishing';
import InteractionDefinition from './InteractionDefinition';
import Results from './Results';
import SelectPackages from './SelectPackages';
import { logError } from '../../../../utils/error';

const steps = [
    { label: 'Selecionar Pacotes', component: <SelectPackages /> },
    { label: 'Definir interação', component: <InteractionDefinition /> },
    { label: 'Finalizar', component: <Finishing /> },
    { label: 'Resultado', component: <Results /> }
];

const BatchInteraction = () => {
    const [activeStep, setActiveStep] = useState(0);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleNext = () => {
        if (steps.length - 1 > activeStep) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const formik = useFormik({
        initialValues: {
            packages: [],
            description: ''
        },
        onSubmit: async ({ packages, description }) => {
            try {
                await api.post('/operations/interaction/batch', {
                    packages,
                    description
                });

                enqueueSnackbar('Interação realizada com sucesso!', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });

                setActiveStep(activeStep + 1);
            } catch (error) {
                logError(error);

                enqueueSnackbar('Erro ao realizar interação, tente novamente', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }
        }
    });

    const handleReset = () => {
        formik.resetForm();
        setActiveStep(0);
    };

    const canContinue = useMemo(() => {
        const havePackagesLoaded = formik.values.packages.length > 0;

        const isDescriptionProvided = String(formik.values.description).trim().length > 0;

        return [havePackagesLoaded, isDescriptionProvided];
    }, [formik.values]);

    const canGoBack = useMemo(() => {
        return [0, steps.length - 1].includes(activeStep);
    }, [formik.values, steps, activeStep]);

    return (
        <Stack
            width="100%"
            height="100%"
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            padding={4}>
            <Typography fontSize="2rem" fontWeight="300" marginBottom="16px">
                Interação em Lote
            </Typography>

            <Stepper
                activeStep={activeStep}
                sx={{
                    width: '100%',
                    maxWidth: '900px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                {steps.map(({ label }) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    maxWidth="800px"
                    height="100%"
                    flex="1">
                    <Stack
                        width="100%"
                        height="100%"
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        {cloneElement(steps[activeStep].component, {
                            key: activeStep,
                            data: formik.values,
                            onChange: formik.handleChange,
                            handleSubmit: formik.handleSubmit,
                            handleReset
                        })}
                    </Stack>
                    <Stack direction="row" width="100%" justifyContent="space-between">
                        <Button
                            variant="outlined"
                            disabled={canGoBack}
                            onClick={handleBack}
                            sx={{ mr: 1 }}>
                            Voltar
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={activeStep >= steps.length || !canContinue[activeStep]}>
                            Próximo
                        </Button>
                    </Stack>
                </Stack>
            }
        </Stack>
    );
};

export default BatchInteraction;
