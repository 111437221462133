import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px;
    width: 100%;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div`
    width: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const Order = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    margin: 10px 0px;
`;

export const Textarea = styled.textarea`
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    padding: 10px;
    background-color: ${(props) => props.theme.secondary}fafa;
    max-width: 350px;
    min-width: 250px;
    min-height: 300px;

    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
        &::placeholder {
            color: ${(props) => props.theme.dark};
            font-weight: 400;
        }
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Select = styled.select`
    width: 300px;
    background: #fafafa;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;

    color: ${(props) => props.theme.dark};

    &:active,
    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Option = styled.option`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 300;
    color: ${(props) => props.theme.dark};
`;

export const Input = styled.input`
    width: 300px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    padding: 10px;
    background-color: ${(props) => props.theme.secondary}fafa;

    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
        &::placeholder {
            color: ${(props) => props.theme.dark};
            font-weight: 400;
        }
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Label = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-align: left;
    width: 300px;
    padding: 16px 0px 0px 0px;
`;
