import {
    Alert,
    Button,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import PrintTag from './PrintTagComponent';
import api from '../../../../services/api';
import { notFoundOnSearch } from '../../../../utils/validations';
import handleCloseAction from '../../../../components/HandleCloseAction';

const Tags = () => {
    const [tags, setTags] = useState([]);
    const [message, setMessage] = useState(null);

    const { mutate: searchPackagesMutation, isLoading } = useMutation(
        async (codes) => {
            const searchObject = {
                packages: codes.map((item) => ({ term: item }))
            };

            const { data } = await api.post('/operations/search', searchObject);

            const notFoundCodes = notFoundOnSearch(codes, data);

            if (notFoundCodes.length > 0) {
                setMessage([
                    {
                        msg: 'Pedidos não encontrados',
                        data: notFoundCodes
                    }
                ]);
            }

            setTags(data);
        },
        {
            onError: (error) => {
                logError(error);

                enqueueSnackbar('Erro ao buscar pacotes', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            onSuccess: () => enqueueSnackbar('Pacotes encontrados',
                            { variant: 'success',
                              action: (id) => handleCloseAction(id, closeSnackbar)
                            })
        }
    );

    const formik = useFormik({
        initialValues: {
            text: ''
        },
        onSubmit: ({ text }) => {
            const codes = String(text)
                .trim()
                .split('\n')
                .map((item) => String(item).trim());

            searchPackagesMutation(codes);
        }
    });

    function handleReset() {
        formik.handleReset();
        setTags([]);
    }

    if (isLoading) return <Loader color="#000" />;

    return (
        <>
            <Modal isOpen={!!message} onRequestClose={() => setMessage(null)}>
                {!!message &&
                    message.map((m) => (
                        <Stack
                            key={m.msg}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Typography fontSize="1.4rem">{m.msg}</Typography>
                            {m.data.map((item) => (
                                <Typography key={item} fontSize="1rem">
                                    {item}
                                </Typography>
                            ))}
                        </Stack>
                    ))}
            </Modal>
            <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={4}
                paddingTop={4}>
                <Stack
                    spacing={2}
                    width="250px"
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Typography>Buscar</Typography>

                    <TextField
                        placeholder="Digite os códigos..."
                        multiline
                        minRows={4}
                        onChange={formik.handleChange}
                        value={formik.values.text}
                        name="text"
                        fullWidth
                    />
                    <Stack
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}>
                        <Button
                            variant="contained"
                            onClick={() => formik.handleSubmit()}
                            style={{ width: '150px' }}>
                            Buscar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleReset()}
                            style={{ width: '150px' }}>
                            Limpar
                        </Button>
                    </Stack>
                </Stack>

                <Stack
                    width="100%"
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center">
                    {tags.length === 0 ? (
                        <Alert severity="success">
                            Vazio por enquanto, busque ao lado para ver as etiquetas
                        </Alert>
                    ) : (
                        <PrintTag data={tags} />
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default Tags;
