import React, { useMemo } from 'react';
import { Container, Item, Label, Value, Section } from './styles';
import { dateFromISO, dateTimeFromISO } from '../../../../utils/formatting';

const CollectorDriverOrderCard = ({ data, onCheck, selected = false }) => {
    const order = useMemo(() => {
        const { alphaCode, location, customer, contract, packageDate, deliverySla, statusLabel } =
            data;
        const orderObject = {
            checked: selected,
            code: alphaCode,
            status: statusLabel,
            contract,
            location,
            customer,
            date: dateFromISO(packageDate),
            sla: !!deliverySla ? dateTimeFromISO(deliverySla) : 'Aguardando coleta'
        };

        return orderObject;
    }, [data, selected]);

    function handleCheck() {
        onCheck(order.code);
    }

    return (
        <Container onClick={() => handleCheck()} checked={order.checked}>
            <Section>
                <Item>
                    <Label>Cod. Pedido: </Label>
                    <Value>{order.code}</Value>
                </Item>
                <Item>
                    <Label>Status: </Label>
                    <Value>{order.status}</Value>
                </Item>
            </Section>

            <Section>
                <Item>
                    <Label>Cliente: </Label>
                    <Value>{order.customer}</Value>
                </Item>
                <Item>
                    <Label>Modalidade: </Label>
                    <Value>{order.contract}</Value>
                </Item>
            </Section>

            <Section>
                <Item>
                    <Label>Data do pedido: </Label>
                    <Value>{order.date}</Value>
                </Item>
                <Item>
                    <Label>Prazo de entrega: </Label>
                    <Value>{order.sla}</Value>
                </Item>
            </Section>

            <Item style={{ flex: 1 }}>
                <Label>Bairro: </Label>
                <Value>{order.location}</Value>
            </Item>
        </Container>
    );
};

export default CollectorDriverOrderCard;
