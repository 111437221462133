import { Chip } from '@mui/material';

import { Container } from './styles';

const TagType = ({ type }) => {

  function handleTagColorIfStatus(type) {
    if (type === 'A') return 'success'
    if (type === 'C') return 'error'
    return null
  }

  function handleTagLabelIfStatus(type) {
    if (type === 'A') return 'Autorizados'
    if (type === 'C') return 'Cancelados'
    return null
  }

  return (
    <Container>
      <Chip
        variant="filled"
        color={handleTagColorIfStatus(type)}
        size="small"
        label={handleTagLabelIfStatus(type)}
        sx={{
          m: '1mm',
          fontWeight: '500'
        }}
      />
    </Container>
  )

};

export { TagType };
