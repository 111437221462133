import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    [`&.${tableCellClasses.root}`]: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}));
