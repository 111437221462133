import { Alert, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { json2excel } from 'js2excel';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Button } from '../../../../components';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { Caption, Container, Table, Td, Th, THead, Tr } from './style';
import { logError } from '../../../../utils/error';
import handleCloseAction from '../../../../components/HandleCloseAction';

const hours = Array.from(new Array(14)).map((n, i) => (n = 8 + i));

const DeliveriesPerHour = () => {
    const [updatedAt, setUpdatedAt] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function deliveriesFormatting(orderList) {
        if (!orderList) return [];

        const drivers = Object.keys(groupBy(orderList.delivery.concat(orderList.drivers), 'driverId'));

        const deliveriesList = Object.values(
            drivers.map((d) => {
                const {driver} = (orderList.drivers).filter(del => del.driverId === d)[0]

                const driverDeliveries = (orderList.delivery).filter((del) => del.driverId === d);

                const deliveredByHour = hours.map((h) => {
                    const hourDeliveries = driverDeliveries.filter((hd) => {
                        const [hour] = new Date(hd.deliveryHour)
                            .toLocaleTimeString('pt-BR')
                            .split(':');

                        return Number(h) === Number(hour);
                    });

                    const deliveriesSum = hourDeliveries.reduce((acc, curr) => {
                        acc += Number(curr.deliveries);

                        return acc;
                    }, 0);

                    return deliveriesSum;
                });

                const {deliveries, pendent, occurrences} = (orderList.drivers).find((del) => del.driverId === d);

                const WorkHours = (((new Date().getHours()) - 8) < 1) ? 1 : (new Date().getHours()) - 8
                const projection = ((deliveries*13)/WorkHours).toFixed(2)
                const realProjection = ((projection - (Number(deliveries) + Number(pendent)))).toFixed(2)
                const driverDispatchs = (Number(deliveries) + Number(pendent))

                return { driver: driver,
                         total: driverDispatchs,
                         deliveries,
                         pendent,
                         occurrences,
                         deliveredByHour,
                         projection,
                         real: realProjection
                        };
            })
        ).sort((a, b) => {
            if (a.driver > b.driver) return 1;
            if (b.driver > a.driver) return -1;
            return 0;
        });
        return deliveriesList;
    }

    const { isLoading, data } = useQuery(
        ['kpis/panel'],
        async () => {
            const { data } = await api.get('/kpis/panel');
            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Entregas/Hora atualizado...', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
                setUpdatedAt(new Date().toLocaleString('pt-BR'));
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const deliveries = useMemo(() => {
        if (!data) return [];
        return deliveriesFormatting(data);
    }, [data]);

    function handleExport() {
        try {
            const formatted = deliveries.map((i) => {
                const row = { motorista: i.driver,
                              total: i.total,
                              deliveries: i.deliveries,
                              pendent: i.pendent,
                              occurrences: i.occurrences,
                              projection: i.projection,
                              real: i.real };

                const deliveryHours = hours.map((h, k) => {
                    const hrs = `${h}hrs`;

                    return { [hrs]: i.deliveredByHour[k] };
                });

                Object.assign(row, ...deliveryHours);

                return row;
            });
            formatted.forEach((i) => Object.values(i));

            json2excel({
                data: formatted,
                name: `entregas-hora-${Date.now()}`,
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);
        }
    }

    if (deliveries.length === 0 && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    if (isLoading) {
        return <Loader color="#000" />;
    }

    return (
        <Container>
            {!!updatedAt && <p>Atualizado em: {new Date(updatedAt).toLocaleString('pt-BR')}</p>}
            <Table>
                <Caption>
                    Entregas / hora
                    <Button
                        style={{ width: 'fit-content', marginLeft: '16px' }}
                        onClick={() => handleExport()}>
                        <span className="material-icons">file_download</span>
                    </Button>
                </Caption>

                <THead>
                    <TableRow>
                        <Th scope="col">Motoristas</Th>
                        <Th scope="col">Total</Th>
                        <Th scope="col">Entregue</Th>
                        <Th scope="col">Ocorrência</Th>
                        <Th scope="col">Pendente</Th>
                        {hours.map((h) => (
                            <Th scope="col" key={h}>
                                {h}hrs
                            </Th>
                        ))}
                        <Th scope="col">Projeção</Th>
                        <Th scope="col">Real</Th>
                    </TableRow>
                </THead>
                <tbody>
                    {deliveries.map((d) => (
                        <Tr key={d.driver} real={d.real} >
                            <Th scope="row">{d.driver}</Th>
                            <Th scope="row">{d.total}</Th>
                            <Th scope="row">{d.deliveries}</Th>
                            <Th scope="row">{d.occurrences}</Th>
                            <Th scope="row">{d.pendent}</Th>
                            {d.deliveredByHour.map((h, k) => (
                                <Td scope="row" key={d.driver + h + k}>
                                    {h}
                                </Td>
                            ))}
                            <Th scope="row">{d.projection}</Th>
                            <Th scope="row">{d.real}</Th>
                        </Tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default DeliveriesPerHour;
