import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Button, Textarea } from '../../../../components';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import terms from '../../../../utils/searchTerms.json';
import { local } from '../../../../utils/storage';
import { notFoundOnSearch, sortPackagesByInputOrder } from '../../../../utils/validations';
import SearchPackageCard from '../../components/SearchPackageCard';
import { Container, LowerSection, ResultsTitle, Title } from './styles';
import { logError } from '../../../../utils/error';

const Search = () => {
    const [message, setMessage] = useState(null);
    const [packages, setPackages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [term, setTerm] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const localInput = local.getEncrypted('@vaifacilbr_search_input');
        const localResult = JSON.parse(local.getEncrypted('@vaifacilbr_search_result'));

        if (localResult) {
            setPackages(localResult);
        }

        if (!!localInput) {
            setInput(localInput);
        }
    }, [input]);

    function cleanedCodes(input) {
        const codesArray = input
            .trim()
            .split('\n')
            .map((i) => i.trim());

        const validCodes = codesArray.filter((c) => !!c);

        const removedTrace = validCodes.map((c) => {
            if (c.includes('-')) {
                const newCode = c.split('-').join('');
                return newCode;
            }
            return c;
        });

        const limitResults = removedTrace.reduce((acc, curr) => {
            if (!acc.includes(curr) && acc.length <= 99) {
                acc.push(curr);
            }

            return acc;
        }, []);

        return limitResults;
    }

    function handleSearch() {
        if (input) {
            const codes = cleanedCodes(input);

            handleInputChange(codes.join('\n'));

            const packages = codes.map((c) => {
                return { term: c };
            });
            setLoading(true);

            const searchObject = {
                searchBy: term,
                packages
            };

            api.post('/operations/search', searchObject)
                .then((response) => {
                    const { data } = response;

                    const notFoundCodes = notFoundOnSearch(codes, data);

                    if (notFoundCodes.length > 0) {
                        setMessage([
                            {
                                msg: 'Pedidos não encontrados',
                                data: notFoundCodes
                            }
                        ]);
                    }

                    const sortedPackages = sortPackagesByInputOrder(codes, data);

                    setPackages(sortedPackages);
                    local.setEncrypted('@vaifacilbr_search_result', JSON.stringify(sortedPackages));
                })
                .catch((err) => {
                    logError(err);
                    enqueueSnackbar(
                        'Ocorreu um erro relacionado à conexão, verifique a internet ou se tem acesso a este recurso.',
                        {
                            variant: 'error',
                            action: (id) => handleCloseAction(id, closeSnackbar)
                        }
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function handleInputChange(value) {
        local.setEncrypted('@vaifacilbr_search_input', value);
        setInput(value);
    }

    function handleClearInput() {
        local.removeEncrypted('@vaifacilbr_search_input');
        local.removeEncrypted('@vaifacilbr_search_result');
        setInput('');
        setPackages([]);
    }

    return (
        <Container>
            <Modal isOpen={!!message} onRequestClose={() => setMessage(null)}>
                {!!message &&
                    message.map((m) => (
                        <Stack
                            key={m.msg}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Typography fontSize="1.4rem">{m.msg}</Typography>
                            {m.data.map((item) => (
                                <Typography key={item} fontSize="1rem">
                                    {item}
                                </Typography>
                            ))}
                        </Stack>
                    ))}
            </Modal>

            <Stack
                spacing={2}
                style={{ marginRight: '16px' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="center">
                <Title>Buscar</Title>

                <Textarea
                    placeholder="Digite os códigos..."
                    style={{
                        maxWidth: '300px',
                        maxHeight: '200px',
                        minWidth: '300px',
                        minHeight: '200px',
                        margin: '10px'
                    }}
                    onChange={(e) => handleInputChange(e.target.value)}
                    value={input}
                />
                <FormControl variant="filled" style={{ width: '300px' }} required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                    <Button onClick={() => handleSearch()} style={{ width: '150px' }}>
                        Buscar
                    </Button>
                    <Button onClick={() => handleClearInput()} style={{ width: '150px' }}>
                        Limpar
                    </Button>
                </div>
            </Stack>

            <LowerSection>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <ResultsTitle>Pacotes encontrados: {packages.length}</ResultsTitle>

                        {packages.map((o) => (
                            <SearchPackageCard
                                key={o.alphaCode}
                                data={o}
                                onUpdate={() => handleSearch()}
                            />
                        ))}
                    </>
                )}
            </LowerSection>
        </Container>
    );
};

export default Search;
