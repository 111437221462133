import styled from 'styled-components';

const cardBreakpoint = '520px';

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 0px;
`;

export const Label = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const Value = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.theme.light};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid grey;

    margin-top: 10px;
    padding-top: 10px;
`;

export const HistoryContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const HistoryItem = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 5px 0px;

    & + & {
        border-top: 1px solid black;
    }

    @media screen and (min-width: ${cardBreakpoint}) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
    }
`;

export const HistoryItemLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const HistoryItemInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 5px;

    @media screen and (min-width: ${cardBreakpoint}) {
        padding: 0px;
        /* width: 100%; */
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
`;

export const InfoLabel = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const InfoValue = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.light};
    padding: 1px;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const DeleteButton = styled.button`
    border: none;

    position: absolute;
`;

export const DeleteIcon = styled.span`
    color: ${(props) => props.theme.danger};
`;
