import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import Modal from '../../../../components/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import api from '../../../../services/api';
import { logError } from '../../../../utils/error';
import terms from '../../../../utils/searchTerms.json';
import { notFoundOnSearch, sortPackagesByInputOrder } from '../../../../utils/validations';

const SelectPackages = ({ onChange }) => {
    const [message, setMessage] = useState(null);
    const inputRef = useRef(null);
    const [term, setTerm] = useState(null);
    const [packages, setPackages] = useState([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { mutate: searchPackagesMutation, isLoading } = useMutation(
        async (codes) => {
            const searchObject = {
                searchBy: term,
                packages: codes.map((item) => ({ term: item }))
            };

            const { data } = await api.post('/operations/search', searchObject);

            const notFoundCodes = notFoundOnSearch(codes, data);

            if (notFoundCodes.length > 0) {
                setMessage([
                    {
                        msg: 'Pedidos não encontrados',
                        data: notFoundCodes
                    }
                ]);
            }
            const sortedPackages = sortPackagesByInputOrder(codes, data);

            const packageCodes = sortedPackages.map(({ alphaCode }) => alphaCode);

            onChange({ target: { value: packageCodes, name: 'packages' } });

            setPackages(data);
        },
        {
            onError: (error) => {
                logError(error);

                enqueueSnackbar('Erro ao buscar pacotes', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            onSuccess: () => enqueueSnackbar('Pacotes encontrados',
                            { variant: 'success',
                              action: (id) => handleCloseAction(id, closeSnackbar)
                            })
        }
    );

    function handleSearch() {
        if (!inputRef.current) return;

        const codes = String(inputRef.current.value)
            .trim()
            .split('\n')
            .map((item) => String(item).trim());

        searchPackagesMutation(codes);
    }

    function handleClearInput() {
        onChange({ target: { value: [], name: 'packages' } });

        setPackages([]);

        if (inputRef.current) {
            inputRef.current.value = '';
        }
    }

    return (
        <Stack
            width="100%"
            height="100%"
            padding={4}
            justifyContent="center"
            direction="row"
            spacing={4}>
            <Modal isOpen={!!message} onRequestClose={() => setMessage(null)}>
                {!!message &&
                    message.map((m) => (
                        <Stack
                            key={m.msg}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Typography fontSize="1.4rem">{m.msg}</Typography>
                            {m.data.map((item) => (
                                <Typography key={item} fontSize="1rem">
                                    {item}
                                </Typography>
                            ))}
                        </Stack>
                    ))}
            </Modal>
            <Stack
                spacing={2}
                style={{ marginRight: '16px' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="center">
                <Typography fontSize="1.5rem" fontWeight="400">
                    Buscar
                </Typography>
                <TextField
                    inputRef={inputRef}
                    multiline
                    minRows={8}
                    maxRows={14}
                    variant="filled"
                    label="Cód. dos Pacotes"
                    fullWidth
                />

                <FormControl variant="filled" style={{ width: '300px' }} required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                    <Button onClick={() => handleClearInput()} variant="outlined">
                        Limpar
                    </Button>
                    <Button onClick={() => handleSearch()} variant="contained" disabled={isLoading}>
                        Buscar
                    </Button>
                </div>
            </Stack>

            <Stack direction="column" justifyContent="flex-start" alignItems="center">
                <Typography fontSize="1.5rem" fontWeight="400">
                    {`Pacotes encontrados: ${packages.length}`}
                </Typography>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Pacote</TableCell>
                                <TableCell align="center">Motorista</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Bairro</TableCell>
                                <TableCell align="center">Cidade</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowY: 'auto' }}>
                            {isLoading ? (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center" colSpan="3">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : packages.length === 0 ? (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center" colSpan="4">
                                        Nenhum encontrado
                                    </TableCell>
                                </TableRow>
                            ) : (
                                packages.map((item) => (
                                    <TableRow
                                        key={item.alphaCode}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">{item.code}</TableCell>
                                        <TableCell align="center">{item.driver || '--'}</TableCell>
                                        <TableCell align="center">{item.statusLabel}</TableCell>
                                        <TableCell align="center">
                                            {item.recipient.address.location.neighborhood}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.recipient.address.location.city}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Stack>
    );
};

export default SelectPackages;
