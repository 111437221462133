import { Box, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import logoWhite from '../../../../assets/img/logo-white.png';



const CollectReport = ({ rows }) => {
  const currentDate = new Date().toLocaleDateString('pt-BR')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1253da',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Box display='flex' flexDirection='column' height='100%' flex='1' gap='1rem'>
      <Box display='flex' alignItems='center' justifyContent='flex-start' gap='1rem' >
        <img src={logoWhite} alt="logo" width="fit-content" height="50px" />
        <Typography variant="h4" fontWeight='bold' textAlign='center' m='0'>
          ROMANEIO
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Stack
        direction="column"
      >
        <Typography>
          Destinatário: <strong>Transportadora Vai Fácil</strong>
        </Typography>
        <Typography>Data da Coleta: <strong>{currentDate}</strong></Typography>
        <Typography>Total de Pedidos Coletados: <strong>{rows.length}</strong> </Typography>
      </Stack>



      <TableContainer component={Paper} sx={{ minHeight: 800 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Nº</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Codigo do Pacote</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Docka Origem</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} >Docka Destino</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                key={row.code}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >  <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.code}
                </TableCell>
                <TableCell >{row.Origin}</TableCell>
                <TableCell >{row.Destination}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box flex='1' height='100%' display='flex' alignItems='end' justifyContent='space-around' marginTop='1.5rem'>
        <Box display='flex' alignItems='center' justifyContent='center' gap='1rem' flexDirection='column' >
          <Typography variant="body1" component="h2" textAlign='center' >
            Assinatura Conferente
          </Typography>
          <Typography variant="body1" component="h2" textAlign='center'>
            _____________________________
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' gap='1rem' flexDirection='column'>
          <Typography variant="body1" component="h2" textAlign='center'>
            Assinatura Cliente
          </Typography>
          <Typography variant="body1" component="h2" textAlign='center'>
            _____________________________
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CollectReport;
