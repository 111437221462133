import styled, { css } from 'styled-components';
import { KeyboardArrowDown, KeyboardArrowUp, Print } from '@material-ui/icons';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    width: 300px;
    min-height: 170px;
    overflow-y: auto;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Name = styled.div`
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ToggleButton = styled.button`
    background: transparent;
    width: fit-content;
    height: fit-content;
    border: none;
    cursor: pointer;
`;

export const PrintIcon = styled(Print)``;

export const OpenIcon = styled(KeyboardArrowDown)``;

export const CloseIcon = styled(KeyboardArrowUp)``;

export const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => (!!props.late ? props.theme.danger : props.theme.dark)};
`;

export const InfoItemNumber = styled.div`
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const InfoItemLabel = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
`;

export const LastDelivered = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 4px 0px;
`;

export const LastDeliveredDate = styled.div`
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    width: 100%;
    text-align: center;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    padding: 4px;
`;

export const LastDeliveredLocation = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const LastDeliveredLabel = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
`;

export const OrdersContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;

    max-height: 260px;
    overflow-y: scroll;
`;

const slaStates = {
    ontime: css`
        background-color: ${(props) => props.theme.secondary};
    `,
    late: css`
        background-color: ${(props) => props.theme.danger};
        color: ${(props) => props.theme.secondary};
    `,
    invalid: css`
        background-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.secondary};
    `
};

export const OrdersItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-top: 0.5px solid #999;
    ${(props) => slaStates[props.slaState]};
`;

export const OrderCode = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderContract = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderLocation = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderCodeScheduleDate = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderReceiver = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
