import React, { useState } from 'react';
import { Container, Title, Order, Input } from './styles';
import { Button } from '../../../../../components';
import api from '../../../../../services/api';
import { useSnackbar } from 'notistack';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { logError } from '../../../../../utils/error';

const BackofficeInteracion = ({ order, onInteract }) => {
    const [comment, setComment] = useState('');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleInteraction() {
        const interactionObject = {
            packages: [
                {
                    term: order.alphaCode,
                    data: {
                        description: comment
                    }
                }
            ]
        };

        api.post('/operations/interaction', interactionObject)
            .then((response) => {
                const { errors } = response.data;
                if (errors.length > 0) {
                    enqueueSnackbar('Não foi possível completar a ação', {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                } else {
                    enqueueSnackbar('Ação realizada com sucesso!', {
                        variant: 'success',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                    onInteract();
                }
            })
            .catch((error) => {
                logError(error);

                enqueueSnackbar('Verifique sua conexão ou se tem acesso a este recurso.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            });
    }

    return (
        <Container>
            <Title>Interação do Backoffice </Title>
            <Order>Pedido: {order.alphaCode}</Order>
            <Input
                placeholder="Comente aqui..."
                style={{ marginTop: '10px' }}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
            />
            <Button
                onClick={() => handleInteraction()}
                style={{ marginTop: '10px' }}
                disabled={!comment.length > 0}>
                Registrar
            </Button>
        </Container>
    );
};

export default BackofficeInteracion;
