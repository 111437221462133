import { Box, Modal as ModalUI } from '@mui/material';

const style = {
  position: 'absolute',
  overflowY: 'scroll',
  overflowX: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  height: 'fit-content',
  bgcolor: 'background.paper',
  border: '2px solid rgba(0,0,0,0.2)',
  boxShadow: 24,
  p: 2
};

const Modal = ({ isOpen, onRequestClose, children }) => {
  return (
    <ModalUI
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '2',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
      closeAfterTransition
      open={isOpen}
      onClose={onRequestClose}>
      <Box sx={style}>{children}</Box>
    </ModalUI>
  );
};

export default Modal;
