import styled from "styled-components";

export const Container = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
padding: 3rem;
gap: 2.5rem;
`;

export function themes(theme) {
    if (theme === 'good') {
        return {
            background: '#32cd32',
            color: 'green',
        }
    }
    if (theme === 'bad') {
        return {
            background: 'red',
            color: '#ffff',
        }
    }
}
