import { Alert, Box, Button, Link } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import Dropzone from '../../components/Dropzone';
import {
    Container,
    ErrorDescriptionItem,
    ErrorDescriptionList,
    ErrorItem,
    ErrorList,
    ErrorTitle,
    Icon,
    InfoText,
    InfoTitle,
    Success,
    Text,
    Title
} from './styles';
import { logError } from '../../../../utils/error';

const requiredFields = [
    'Modalidade_Contratada',
    'NUMERO_DO_PEDIDO',
    'NOME_DESTINATARIO',
    'CPF_DESTINATARIO',
    'ENDERECO_DESTINATARIO',
    'NUMERO_DO_ENDERECO_DESTINATARIO',
    'COMPLEMENTO_ENDERECO_DESTINATARIO',
    'BAIRRO_ENDERECO_DESTINATARIO',
    'CIDADE_DESTINATARIO',
    'ESTADO_DO_DESTINATARIO',
    'CEP_DESTINATARIO',
    'EMAIL_DESTINATARIO',
    'TELEFONE_DESTINATARIO',
    'NFe_NUMERO',
    'NFe_SERIE',
    'NFe_VALOR_TOTAL',
    'NFe_CHAVE_DE_ACESSO'
];

const CSVUpload = () => {
    const [importErrors, setImportErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [importResponse, setImportResponse] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading, data: contracts } = useQuery(
        ['retailer/delivery-modalities'],
        async () => {
            const response = await api.get('/retailer/delivery-modalities');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 10 // 10 minutes
        }
    );

    const integrationKey = useMemo(() => {
        if (!contracts) return null;

        const [contract] = contracts;

        return contract.integrationKey;
    }, [contracts]);

    function isEmpty(order) {
        const haveWhiteSpaceExtra = Object.keys(order).length === 1;

        const isEmpty =
            Object.values(order).filter((f) => !!f && String(f).trim().length !== 0).length === 0;

        if (haveWhiteSpaceExtra || isEmpty) {
            enqueueSnackbar(
                'Seu CSV possui uma linha extra em branco que não será contabilizada.',
                {
                    variant: 'info',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                }
            );
            return true;
        } else {
            return false;
        }
    }

    function haveMissingHeader(order) {
        const validKeys = Object.keys(order).filter((k) => requiredFields.includes(k)).length;

        return validKeys === 0;
    }

    function haveMissingFields(order) {
        const missingFields = requiredFields.filter((f) => !Object.keys(order).includes(f));

        if (missingFields.length > 0 && !isEmpty(order)) {
            return true;
        } else {
            return false;
        }
    }

    function contractIsInvalid(order) {
        if (!contracts) return false;

        const availableServiceCodes = contracts.map((c) => c.serviceCode);

        const isValid = availableServiceCodes.includes(order.Modalidade_Contratada);

        if (!isValid && !isEmpty(order)) {
            return true;
        } else {
            return false;
        }
    }

    function haveParsedExtra(order) {
        const parsed = order.__parsed_extra;

        if (!parsed) {
            return false;
        }

        if (parsed.length === 1 && parsed[0].length === 0 && !isEmpty(order)) {
            return false;
        } else {
            return true;
        }
    }

    function invalidCep(order) {
        const cep = new RegExp(/\d{5}-?\d{3}\b/gm);

        if (!String(order.CEP_DESTINATARIO).match(cep)) return true;

        return false;
    }

    function invalidCpf(order) {
        const cpf = new RegExp(/^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/gm);

        if (!String(order.CPF_DESTINATARIO).match(cpf)) return true;

        return false;
    }

    function validateOrder(order) {
        if (
            invalidCep(order) ||
            invalidCpf(order) ||
            haveMissingHeader(order) ||
            haveMissingFields(order) ||
            contractIsInvalid(order) ||
            haveParsedExtra(order)
        ) {
            return false;
        }
        return true;
    }

    function removeDashFromCode(code) {
        const haveDash = String(code).includes('-');

        if (haveDash) {
            const newCode = code.split('-')[0];
            return newCode;
        } else {
            return code;
        }
    }

    function normalize(order) {
        const { CPF_DESTINATARIO, CEP_DESTINATARIO, NUMERO_DO_PEDIDO } = order;

        const cpf = String(CPF_DESTINATARIO).replace(/[.-]/g, '');
        const cep = String(CEP_DESTINATARIO).replace(/[.-]/g, '');

        order.CPF_DESTINATARIO = cpf;
        order.NUMERO_DO_PEDIDO = removeDashFromCode(NUMERO_DO_PEDIDO);
        order.CEP_DESTINATARIO = cep;

        if (!!order.__parsed_extra) {
            delete order.__parsed_extra;
        }
        return order;
    }

    async function sendOrders(orders) {
        const orderObject = {
            integrationKey,
            orders
        };

        try {
            const { data } = await api.post('/retailer/packages', orderObject);

            if (!data) return null;

            if (!!data.packages && data.packages.length > 0) {
                const succeed = data.packages
                    .filter((p) => p.status === 'success')
                    .map((p) => {
                        const { code } = p;

                        if (code.includes('-')) {
                            const [newCode] = code.split('-');
                            return newCode;
                        } else {
                            return code;
                        }
                    });

                const orderCodes = orders.map((o) => o.NUMERO_DO_PEDIDO);

                const failed = orderCodes.filter((c) => !succeed.includes(c));

                const newImportResponse = {
                    failed,
                    succeed
                };

                setImportResponse(newImportResponse);
            }
        } catch (error) {
            logError(error);

            const haveErrors =
                !!error.response && !!error.response.data && !!error.response.data.errors;

            if (haveErrors) {
                const failed = error.response.data.errors.map((e) => {
                    if (e.error === 'Address not covered for destination') {
                        e.error = 'Endereço de destino fora da área de serviço';

                        return e;
                    }

                    if (e.error === 'Address not covered for origin') {
                        e.error = 'Endereço de origem fora da área de serviço';
                        return e;
                    }

                    e.error = 'Erro na importação';
                    return e;
                });

                const newImportResponse = {
                    failed
                };

                setImportResponse(newImportResponse);
                return;
            }

            enqueueSnackbar(
                'Cheque sua internet, se este erro persistir, entre em contato com o suporte',
                {
                    variant: 'error',
                    action
                }
            );

            const message = {
                title: 'Erro de comunicação!',
                description: [
                    'Ocorreu um erro ao se comunicar com o servidor.',
                    'Este erro pode ocorrer devido a falta de permissões ou indisponibilidade de serviços ou de internet',
                    'Caso este erro persista, entre em contato com o suporte'
                ]
            };
            setImportErrors([message]);
            setShowErrors(true);
        }
    }

    function mountErrorMessage(order) {
        const code =
            order && order.NUMERO_DO_PEDIDO
                ? `Pedido ${order.NUMERO_DO_PEDIDO}`
                : 'Pedido não identificado';

        const message = {
            title: code,
            description: []
        };

        if (!order) {
            message.description.push(`Arquivo inválido ou impossível de ler.`);
            message.description.push(
                `Este erro ocorre devido a um ou mais campos da planilha estarem fora do padrão de liguagem e acentuação brasileira`
            );
            message.description.push(
                `Verifique as configurações de idioma do seu ambiente de trabalho e dos caracteres e acentuação utilizados na planilha.`
            );
            return message;
        }

        if (haveMissingHeader(order)) {
            message.description.push(
                `Cabeçalho não encontrado ou mal formatado, siga o padrão de cabeçalho do arquivo exemplo. (Cabeçalho refere-se a primeira linha da planilha que deve conter o nome dos campos.)`
            );
        }
        if (invalidCep(order)) {
            message.description.push(`CEP informado possui caracteres inválios ou má formatação.`);
        }
        if (invalidCpf(order)) {
            message.description.push(`CPF informado possui caracteres inválios ou má formatação.`);
        }
        if (haveMissingFields(order)) {
            message.description.push(`Um ou mais campos obrigatórios não informados.`);
        }
        if (contractIsInvalid(order)) {
            const description = !!order.Modalidade_Contratada
                ? `"${order.Modalidade_Contratada}" não é um contrato válido.`
                : 'Contrato inválido';
            message.description.push(description);
        }

        if (haveParsedExtra(order)) {
            message.description.push(
                'Foi identificada uma linha com um campo a mais, verifique a formatação do arquivo e tente novamente.'
            );
        }

        return message;
    }

    function handleFile(parsed) {
        resetForm();

        if (parsed.errors) {
            const message = parsed.errors.map((e) => `Linha:${e.line} - Erros: "${e.data}"`);

            setImportErrors([
                {
                    title: 'Erro no arquivo',
                    description: message
                }
            ]);
            setShowErrors(true);
            return;
        }

        try {
            const orders = parsed.filter((o) => !isEmpty(o));

            const invalid = orders.filter((o) => !validateOrder(o));

            const valid = orders.filter((o) => validateOrder(o)).map((o) => normalize(o));

            if (valid.length > 0 && invalid.length === 0) {
                sendOrders(valid);
            } else {
                const errors = invalid.map((o) => mountErrorMessage(o));

                setImportErrors(errors);
                setShowErrors(true);

                enqueueSnackbar(
                    'Ocorreram um ou mais problemas que impediram o prosseguimento da importação.',
                    {
                        variant: 'error',
                        action
                    }
                );
            }
        } catch (error) {
            logError(error);
        }
    }

    function resetForm() {
        setImportResponse(null);
        setImportErrors([]);
        setShowErrors(false);
    }

    const succeedMessage = useMemo(() => {
        if (!importResponse) return null;

        const succeed = importResponse.succeed && importResponse.succeed.length > 0;

        if (succeed) {
            const message = `Pedidos importados: ${importResponse.succeed.length}`;

            return { message };
        }

        return null;
    }, [importResponse]);

    const failedMessage = useMemo(() => {
        if (!importResponse) return null;

        const failed = importResponse.failed && importResponse.failed.length > 0;

        if (failed) {
            const message = `${importResponse.failed.length} pedido${
                importResponse.failed.length > 1 ? 's' : ''
            } não importado${importResponse.failed.length > 1 ? 's' : ''}`;

            const orders = importResponse.failed.map((o) => {
                const { error } = o;

                return {
                    error,
                    code: o.order || o.code || 'Não identificado'
                };
            });

            return { message, orders };
        }

        return null;
    }, [importResponse]);

    if (isLoading) {
        return (
            <Box sx={{ m: 4 }}>
                <Loader color="#000" />
            </Box>
        );
    }

    if (!contracts || (contracts.length === 0 && !isLoading))
        return (
            <Alert
                severity="warning"
                sx={{ margin: 4, width: 'fit-content', maxWidth: '80%', ml: 'auto', mr: 'auto' }}>
                Não encontramos nenhuma modalidade de entrega registrada, entre em contato com a
                equipe de integração para mais detalhes
            </Alert>
        );

    return (
        <Container>
            <Modal isOpen={showErrors} onRequestClose={() => setShowErrors(false)}>
                <h2>Problemas detectados:</h2>
                <ErrorList>
                    {showErrors &&
                        importErrors.map((e) => (
                            <ErrorItem key={e.title + Math.random()}>
                                <ErrorTitle>{e.title}</ErrorTitle>
                                <ErrorDescriptionList>
                                    {e.description.map((e) => (
                                        <ErrorDescriptionItem key={e}>{e}</ErrorDescriptionItem>
                                    ))}
                                </ErrorDescriptionList>
                            </ErrorItem>
                        ))}
                </ErrorList>
            </Modal>
            {importResponse ? (
                <Success>
                    <Title>Resultado da operação</Title>
                    {succeedMessage && (
                        <>
                            <Icon className="material-icons" type="success">
                                done_all
                            </Icon>
                            <Text>{succeedMessage.message}</Text>
                        </>
                    )}
                    {failedMessage && (
                        <>
                            <Icon className="material-icons" type="danger">
                                warning
                            </Icon>
                            <Text>
                                {failedMessage.message}
                                {failedMessage.orders.map((s) => (
                                    <Text key={s.order}>
                                        #{s.code} - {s.error}
                                    </Text>
                                ))}
                            </Text>
                        </>
                    )}
                    <Button variant="contained" onClick={() => resetForm()}>
                        Voltar
                    </Button>
                </Success>
            ) : (
                <>
                    <Dropzone handleFile={(f) => handleFile(f)} onRemove={() => resetForm()} />
                    {!showErrors && importErrors.length > 0 && (
                        <Button
                            variant="contained"
                            style={{ width: '200px', marginTop: '16px' }}
                            onClick={() => setShowErrors(true)}>
                            Ver erros detectados
                        </Button>
                    )}
                    <Link
                        underline="none"
                        href="https://vf-store.nyc3.digitaloceanspaces.com/assets/exemplo_importacao.csv">
                        <Button
                            variant="contained"
                            style={{ width: '100%', marginTop: '16px' }}
                            onClick={() => {}}>
                            Baixar arquivo exemplo
                        </Button>
                    </Link>
                    <InfoTitle>Requisitos</InfoTitle>
                    <InfoText>
                        O arquivo precisar ser do tipo <strong>CSV</strong>
                    </InfoText>
                    <InfoText>O arquivo CSV deve usar vírgula para separar seus valores</InfoText>
                    <InfoTitle>Links úteis</InfoTitle>
                    <Link
                        underline="none"
                        href="https://rockcontent.com/br/blog/csv/"
                        target="_new">
                        <Button
                            style={{ width: '300px', marginTop: '16px' }}
                            onClick={() => {}}
                            variant="outlined">
                            O que é um CSV?
                        </Button>
                    </Link>
                    <Link
                        underline="none"
                        href="https://support.microsoft.com/pt-br/office/importar-ou-exportar-arquivos-de-texto-txt-ou-csv-5250ac4c-663c-47ce-937b-339e391393ba"
                        target="_new">
                        <Button
                            style={{ width: '300px', marginTop: '16px' }}
                            onClick={() => {}}
                            variant="outlined">
                            CSV no Excel
                        </Button>
                    </Link>
                    <Link
                        underline="none"
                        href="https://help.loyverse.com/br/help/how-open-csv-file-google-sheets"
                        target="_new">
                        <Button
                            style={{ width: '300px', marginTop: '16px' }}
                            onClick={() => {}}
                            variant="outlined">
                            CSV nas Planilhas do Google
                        </Button>
                    </Link>
                    <Link
                        underline="none"
                        href="https://pt.wikihow.com/Criar-um-Arquivo-CSV"
                        target="_new">
                        <Button
                            style={{ width: '300px', marginTop: '16px' }}
                            onClick={() => {}}
                            variant="outlined">
                            Como editar um arquivo CSV
                        </Button>
                    </Link>
                </>
            )}
        </Container>
    );
};

export default CSVUpload;
