import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70mm;
  top: 8mm;
  right: 0; ;
`;
