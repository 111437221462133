import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    background: #1253da;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Logo = styled.img`
    width: 100%;
`;

export const Heading = styled.div`
    width: 100%;
    margin-top: 32px;
    margin-bottom: 24px;
`;

export const Title = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.dark};
`;

export const Text = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.dark};
`;

export const LoginOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 14px;
    margin-bottom: 24px;
`;

export const RememberMe = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;

    color: ${(props) => props.theme.dark};
`;
export const Checkbox = styled.input`
    width: fit-content;
    margin-right: 7px;
    border: 4px solid #dadada;

    background: #dadada;
`;

export const ForgotPassword = styled(Link)`
    width: fit-content;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;

    color: ${(props) => props.theme.primary};
`;
