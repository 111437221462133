export function haveApiErrors(payload) {
    const text = String(payload);

    return text.match(/(error|stack|rejections)/gi);
}

export function isValid(value) {
    if (!value) return false;

    if (value === 'false') return false;

    if (value === '') return false;

    return true;
}

export function notFoundOnSearch(searchCodes, responsePackages) {
    const pkgCodes = responsePackages.map((o) => String(o.alphaCode).toLowerCase());

    const nfNumbers = responsePackages.reduce((acc, curr) => {
        acc.push(...curr.invoices.map((i) => String(i.number).toLowerCase()));

        return acc;
    }, []);
    const nfKeys = responsePackages.reduce((acc, curr) => {
        acc.push(...curr.invoices.map((i) => String(i.key).toLowerCase()));

        return acc;
    }, []);

    const receivedCodes = [...pkgCodes, ...nfKeys, ...nfNumbers];

    const notFoundCodes = searchCodes.filter(
        (c) => !receivedCodes.includes(String(c).toLowerCase())
    );

    return notFoundCodes;
}

export function renameDuplicatedPackages(items) {
    return items.reduce((acc, curr) => {
        const existentCodes = acc.map((a) => a.alphaCode);

        if (existentCodes.includes(curr.alphaCode)) {
            const duplicateCount = existentCodes.filter((c) => c === curr.alphaCode).length;

            acc.push({
                ...curr,
                alphaCode: curr.alphaCode + ` - Duplicado nº ${duplicateCount}`
            });
        } else {
            acc.push(curr);
        }

        return acc;
    }, []);
}

export function sortPackagesByInputOrder(searchCodes, responsePackages) {
    return renameDuplicatedPackages(
        responsePackages.sort((a, b) => {
            const aI = searchCodes.indexOf(a.alphaCode);
            const bI = searchCodes.indexOf(b.alphaCode);
            if (aI > bI) {
                return 1;
            }
            if (aI < bI) {
                return -1;
            }
            return 0;
        })
    );
}
