import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal/index';
import api from '../../../../services/api';
import {
    MessageItem,
    MessageItemLabel,
    MessageItemValues,
    OrderContainer,
    SearchForm
} from './styles';
import { useAuth } from '../../../../hooks/auth';
import { useMemo } from 'react';
import { CollapsibleTable } from '../../components/Table/Table';
import { logError } from '../../../../utils/error';

const Tracking = () => {
    const [PackageRows, setPackageRows] = useState([]);
    const [input, setInput] = useState('');
    const [searchType, setSearchType] = useState('packages');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { user } = useAuth();

    function cleanedCodes(input) {
        const codesToArray = (text) =>
            text
                .trim()
                .split('\n')
                .map((i) => i.trim())
                .filter((i) => !!i);

        return codesToArray(input);
    }

    function fomartResquestBody(searchType, packages) {
        setInput(packages.join('\n'));

        if (!packages.length > 0) return null;

        if (searchType === 'orders') {
            return {
                orders: packages
            };
        }

        return { packages };
    }

    async function trackOrders() {
        setLoading(true);

        try {
            const packages = cleanedCodes(input);

            const requestBody = fomartResquestBody(searchType, packages);

            const response = await api.post('/retailer/tracking', requestBody);

            const foundPackages = response.data.map((p) => String(p.package).toLowerCase());

            if (searchType === 'packages') {
                const notFound = packages.filter(
                    (p) => !foundPackages.includes(String(p).toLowerCase())
                );

                if (notFound.length > 0) {
                    setMessage([
                        {
                            msg: 'Pedidos não encontrados',
                            data: notFound
                        }
                    ]);
                }
            }

            formatPakageRows(response.data);
        } catch (err) {
            logError(err);

            if (!err.response.data.message) {
                enqueueSnackbar('Não foi possível realizar esta consulta, tente novamente', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } else if (err.response.data.message.includes('maximum number')) {
                enqueueSnackbar(
                    'A quantidade de pedidos que tentou consultar ultrapassa o máximo permitido por vez.',
                    {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    }
                );
            } else {
                enqueueSnackbar(
                    'Tente novamente mais tarde, caso persista entre em contato com a equipe Vai Fácil',
                    {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    }

    function handleCloseModal() {
        setMessage(null);
    }

    function formatPakageRows(orders) {
        const formattedData = orders.map((o) => {
            const [lastEvent] = o.events.sort((a, b) => a.createdAt - b.createdAt).reverse();
            const date = new Date(lastEvent.createdAt).toLocaleString('pt-BR');
            const res = {
                packageCode: o.package,
                currentStatus: lastEvent.status,
                id: uuidV4(),
                statusDate: date,
                events: o.events
            };

            o.events.reverse().forEach((e) => {
                if (e.occurrence) Object.assign(res, { ocurrence: e.occurrence });
                if (e.receivedBy) Object.assign(res, { receiver: e.receivedBy });
            });

            return res;
        });

        setPackageRows(formattedData);
    }

    const showLast3MonthsOrders = useMemo(
        () => user.customers.length > 0 && user.customers[0].id === '1',
        []
    );

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingTop="2rem">
            <Modal isOpen={!!message} onRequestClose={handleCloseModal}>
                {!!message &&
                    message.map((m) => (
                        <MessageItem key={m.msg}>
                            <MessageItemLabel>{m.msg}</MessageItemLabel>
                            {m.data.map((item) => (
                                <MessageItemValues key={item}>{item}</MessageItemValues>
                            ))}
                        </MessageItem>
                    ))}
            </Modal>

            <Box
                display="flex"
                gap="2rem"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center">
                <Card
                    styles={{ width: '90%', maxWidth: '1000px' }}
                    variant="elevation"
                    elevation={5}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="h4">
                            Buscar pedidos
                        </Typography>
                        <Typography variant="body1" color="GrayText">
                            Insira um código por linha para consultar vários!
                        </Typography>
                        <Typography variant="body1" color="GrayText">
                            Limite de consulta: 500 pedidos
                        </Typography>
                        <TextField
                            sx={{ marginTop: '1rem' }}
                            label="Informe os Pedidos"
                            multiline
                            id="filled-multiline-static"
                            rows={4}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            variant="filled"
                        />
                        <FormControl variant="filled" sx={{ marginTop: '1rem' }}>
                            <InputLabel id="search-term">Tipo de Consulta</InputLabel>
                            <Select
                                labelId="search-term"
                                id="search-term"
                                value={searchType}
                                onChange={(e) => setSearchType(e.target.value)}
                                defaultValue="packages">
                                <MenuItem value="packages">Codigo do Pacote</MenuItem>
                                <MenuItem value="orders">Numero do Pedido</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            sx={{ marginTop: '0.5rem' }}
                            variant="contained"
                            onClick={() => trackOrders()}
                            style={{ width: '100%' }}
                            disabled={loading}>
                            PESQUISAR
                        </Button>

                        {PackageRows.length > 0 && <p>Pedidos encontrados: {PackageRows.length}</p>}
                    </CardContent>
                </Card>
                {showLast3MonthsOrders && (
                    <Card
                        styles={{ width: '90%', maxWidth: '1000px' }}
                        variant="elevation"
                        elevation={5}>
                        <SearchForm>
                            <Typography gutterBottom variant="h5">
                                Pedidos Últimos 3 meses
                            </Typography>
                            <Typography variant="body1" color="GrayText">
                                Gera um CSV Contendo os pedidos criados
                            </Typography>
                            <Typography variant="body1" color="GrayText">
                                {' '}
                                nos últimos 3 meses !
                            </Typography>
                            <strong>Atualização: a cada 24 horas</strong>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="https://vf-store.nyc3.cdn.digitaloceanspaces.com/report/renner/RennerReport.csv">
                                <Button
                                    style={{ width: '100%', marginTop: '16px' }}
                                    onClick={() => {}}
                                    variant="contained">
                                    Baixar Pedidos
                                </Button>
                            </a>
                        </SearchForm>
                    </Card>
                )}
            </Box>

            {loading ? (
                <Loader type="ThreeDots" color="#000" />
            ) : (
                <OrderContainer>
                    {PackageRows && <CollapsibleTable packageRows={PackageRows} />}
                </OrderContainer>
            )}
        </Box>
    );
};

export default Tracking;
