import styled, { css } from 'styled-components';
import { FilterList } from '@material-ui/icons';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    /* @media screen and (min-width: 912px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  } */
`;

export const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const Right = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
`;

export const FilterButton = styled.button`
    background: transparent;
    width: fit-content;
    height: fit-content;
    border: none;
`;

export const FilterIcon = styled(FilterList)`
    width: 30px;
`;

export const TotalCardContainer = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    cursor: pointer;
    border-radius: 4px;

    &:hover {
        transition: color 0.5s;
        color: ${(props) => props.theme.secondary};
        background-color: ${(props) => props.theme.light};
    }

    border: none;
    ${(props) =>
        !!props.selected &&
        css`
            color: ${(props) => props.theme.secondary};
            background-color: ${(props) => props.theme.light};
        `}
`;

export const TotalCardNumber = styled.div`
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 500;
`;

export const TotalCardLabel = styled.div`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 400;
    text-align: center;
`;
