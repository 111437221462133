import { Box } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import api from '../../../../services/api';
import { PackageCard } from './PackageCard';
import { PackageTable } from './PackageTable';
import MediaQuery from 'react-responsive';

import { HeaderElements } from './HeaderElements';
import { logError } from '../../../../utils/error';

const Romanian = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [packageList, setPackageList] = useState([]);

    async function searchPackage(term) {
        const data = {
            packages: [{ term }]
        };

        try {
            const {
                data: [
                    {
                        code,
                        contractName,
                        recipient: {
                            address: {
                                number,
                                location: { city, state, address, neighborhood }
                            }
                        }
                    }
                ]
            } = await api.post('/operations/search', data);
            const formatResponse = {
                count: packageList.length + 1,
                code,
                contractName,
                neighborhood,
                city,
                state,
                address,
                number
            };
            const packageExist = packageList.find((p) => p.code === formatResponse.code);

            if (packageExist) {
                return enqueueSnackbar('Pacote ja existe no Romaneio', {
                    variant: 'warning',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }

            setPackageList([...packageList, formatResponse]);
        } catch (error) {
            logError(error);
        }
    }

    function clearRomanian() {
        setPackageList([]);
    }

    function deletePackageForList(row) {
        const newPackages = Array.from(packageList);

        const indexToRemove = newPackages.indexOf(row);

        newPackages.splice(indexToRemove, 1);

        setPackageList(newPackages);

        enqueueSnackbar('Pacote removido do Romaneio', {
            variant: 'success',
            action: (id) => handleCloseAction(id, closeSnackbar)
        });
    }

    return (
        <Box>
            <HeaderElements
                totalRows={packageList.length}
                searchPackage={searchPackage}
                clearRomanian={clearRomanian}
                packageList={packageList}
            />

            <MediaQuery minWidth={1224}>
                <PackageTable packageList={packageList} deletePackage={deletePackageForList} />
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
                <Box display="flex" flexDirection="column" gap={3} marginBottom="3rem">
                    {packageList.map((p) => (
                        <PackageCard
                            data={p}
                            key={p.code + p.count}
                            deletePackage={deletePackageForList}
                        />
                    ))}
                </Box>
            </MediaQuery>
        </Box>
    );
};

export default Romanian;
