import styled from 'styled-components';
import MaterialCheckbox from '@mui/material/Checkbox';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const DriverContainer = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: 400px) {
        width: 400px;
    }
`;

export const DriverContainerHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
`;

export const FilterContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Checkbox = styled(MaterialCheckbox)`
    margin: 0px 3px 0px 0px !important;
    padding: 0px !important;
    width: fit-content !important;

    @media screen and (min-width: 400px) {
        margin: 0px !important;
        padding: 9px !important;
        /* width:100% !important; */
    }
`;

export const FilterButton = styled.button`
    width: 20px;
    height: 20px;
    border: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FilterIconDown = styled(KeyboardArrowDown)``;

export const FilterIconUp = styled(KeyboardArrowUp)``;

export const Label = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    @media screen and (min-width: 400px) {
        font-size: 18px;
    }
`;

export const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const Right = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
`;
