import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import LineChart from '../../components/LineChart';
import getColorsRandom from '../../utils/getColorsRandom';

const SlaEvolution15Days = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading, data: kpis } = useQuery(
        ['kpis'],
        async () => {
            const { data } = await api.get('/kpis');

            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Dashboards atualizados!',
                { variant: 'success',
                  action:(id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const chartData = useMemo(() => {
        if (!kpis) return null;

        const labels = kpis.items.slaEvolution
            .reduce((acc, curr) => {
                if (!acc.includes(curr.date)) acc.push(curr.date);
                return acc;
            }, [])
            .sort((a, b) => {
                if (new Date(a) > new Date(b)) {
                    return 1;
                }
                if (new Date(b) > new Date(a)) {
                    return -1;
                }
                return 0;
            });

        const slaByContracts = kpis.items.slaEvolution.reduce((acc, curr) => {
            const existent = acc.find((item) => item.name === curr.contract);
            const delivered = Number(curr.delivered);
            const onTime = Number(curr.onTime);
            const sla = (onTime / delivered) * 100;

            const { date } = curr;

            if (existent) {
                const contractIndex = acc.indexOf(existent);

                const currentDate = existent.slaDays.find((d) => d.date === date);

                const slaItemIndex = existent.slaDays.indexOf(currentDate);

                currentDate.sla = sla;

                existent.slaDays.splice(slaItemIndex, 1, currentDate);

                acc.splice(contractIndex, 1, existent);
            } else {
                const slaDays = labels.map((l) => {
                    return {
                        date: l,
                        sla: l === date ? sla : null
                    };
                });

                const newContract = {
                    name: curr.contract,
                    slaDays
                };

                acc.push(newContract);
            }
            return acc;
        }, []);

        const slaPerDate = kpis.items.slaEvolution.reduce((acc, curr) => {
            const existent = acc.find((d) => d.date === curr.date);
            const delivered = Number(curr.delivered);
            const onTime = Number(curr.onTime);

            if (existent) {
                const index = acc.indexOf(existent);

                existent.data.push({ delivered, onTime });

                acc.splice(index, 1, existent);
            } else {
                const newDateItem = {
                    date: curr.date,
                    data: [{ delivered, onTime }]
                };

                acc.push(newDateItem);
            }

            return acc;
        }, []);

        const totalSlaAvg = slaPerDate
            .map((contract) => {
                const { date } = contract;
                const deliveries = contract.data.reduce(
                    (total, { delivered }) => total + parseInt(delivered),
                    0
                );
                const onTime = contract.data.reduce(
                    (total, { onTime }) => total + parseInt(onTime),
                    0
                );
                const sla = (onTime / deliveries) * 100;
                return { date, sla };
            })
            .sort((a, b) => {
                if (new Date(a.date) > new Date(b.date)) {
                    return 1;
                }
                if (new Date(b.date) > new Date(a.date)) {
                    return -1;
                }
                return 0;
            });

        slaByContracts.push({
            name: 'Total',
            slaDays: totalSlaAvg
        });

        const datasets = slaByContracts.map((c) => {
            const data = c.slaDays.map((d) => d.sla);
            return {
                label: c.name,
                data: data,
                borderColor: getColorsRandom([data]),
                backgroundColor: 'white',
                tension: 0.4
            };
        });

        const data = {
            labels,
            datasets
        };
        return { data };
    }, [kpis]);

    if (!kpis && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return isLoading ? (
        <Loader type="ThreeDots" color="#000" />
    ) : !kpis && !isLoading ? (
        <Alert severity="success" sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
            Nenhuma atualização
        </Alert>
    ) : (
        <LineChart data={chartData.data} />
    );
};

export default SlaEvolution15Days;
