function getColorsRandom(interation) {
    const backgroundColors = []
    for (let i = 0; i < interation.length; i++) {
        const r = Math.floor((Math.random()) * 255)
        const g = Math.floor((Math.random()) * 255)
        const b = Math.floor((Math.random()) * 255)
        backgroundColors.push(`rgba(${r},${g},${b},1.0)`)
    }
    return backgroundColors
}

export default getColorsRandom
