import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    Legend
);

const LineChart = ({ data }) => {
    return <Chart type="line" data={data} style={{ maxHeight: '85vh', marginTop: '16px' }} />;
};

export default LineChart;
