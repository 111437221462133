import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import api from '../../../../services/api';
import { textToCodeArray } from '../../../../utils/formatting';
import terms from '../../../../utils/searchTerms.json';
import { isValid } from '../../../../utils/validations';
import DockSelect from '../../components/DockSelect';
import DriverSelect from '../../components/DriverSelect';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendBatchOrder, setBatchOrder } from '../../utils/OperationActions';
import { Container, LowerSection, Title } from './styles';
import { logError } from '../../../../utils/error';

const Reversing = () => {
    const [driver, setDriver] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const inputRef = useRef(null);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function handleBatchReversing() {
        try {
            if (!inputRef.current) return;

            const codes = textToCodeArray(inputRef.current.value);

            inputRef.current.value = '';

            const existentOrders = orders.filter((o) => {
                if (codes.includes(o.number)) {
                    return true;
                } else {
                    return false;
                }
            });

            if (existentOrders.length > 0) {
                existentOrders.forEach((o) => {
                    enqueueSnackbar(`Pedido "${o.number}" já foi registrado nesta tentantiva`, {
                        variant: 'info',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                });
                return;
            }

            const ordersList = setBatchOrder({
                dock: origin,
                destination,
                orders: codes,
                driver
            });

            if (term) {
                Object.assign(ordersList, { searchBy: term });
            }

            if (isValid(origin) && isValid(destination) && isValid(driver)) {
                const { data } = await api.post('/operations/list', ordersList);

                if (data && ordersList) {
                    Object.assign(ordersList, { list: data.id });
                }
            }
            if (ordersList) {
                const responseList = await sendBatchOrder({
                    payload: ordersList,
                    operation: 'reversing'
                });

                const toasts = responseList.filter((r) => r.type === 'toast');

                toasts.forEach((response) => {
                    enqueueSnackbar(response.data,
                      {
                       action: (id) => handleCloseAction(id, closeSnackbar)
                      });
                });

                const ordersResponse = responseList
                    .filter((r) => r.type !== 'toast')
                    .reduce((acc, curr) => {
                        acc.push(...curr.data);
                        return acc;
                    }, []);

                const newOrders = [...ordersResponse, ...orders];

                setOrders(newOrders);
            }
        } catch (error) {
            logError(error);
        }
    }

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }
    return (
        <Container>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Title>REVERTER</Title>
                <DriverSelect onChange={setDriver} />
                <DockSelect onChange={setOrigin} placeholder="Origem" required />
                <DockSelect
                    disabled={!origin && !!driver}
                    onChange={setDestination}
                    placeholder="Destino"
                />

                <FormControl
                    variant="filled"
                    sx={{ m: 1, minWidth: 300 }}
                    disabled={!origin || !driver}
                    required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    inputRef={inputRef}
                    disabled={!origin}
                    id="outlined-multiline-flexible"
                    label="Digite aqui para buscar..."
                    style={{ width: '300px' }}
                    multiline
                    rows={4}
                />

                <Button
                    type="button"
                    style={{ width: '300px' }}
                    variant="contained"
                    onClick={() => handleBatchReversing()}>
                    Enviar
                </Button>
            </Stack>

            <LowerSection>
                <OperationPackagesFeedback
                    orders={orders}
                    handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                />
            </LowerSection>
        </Container>
    );
};

export default Reversing;
