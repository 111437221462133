import styled from 'styled-components';
import { FilterList } from '@material-ui/icons';
import { darken } from 'polished';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const Title = styled.div`
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
`;

export const UpperSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const LowerSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const CardList = styled.div`
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 100px;
`;

export const Header = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
`;
export const HeaderItem = styled.div`
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    /* margin: 0px 10px; */
`;
export const HeaderItemLabel = styled.div`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 400;
    text-align: center;
`;
export const HeaderItemValue = styled.div`
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 500;
`;

export const MessageItem = styled.div`
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
`;
export const MessageItemLabel = styled.div`
    font-size: 18px;
    font-family: Montserrat;
    margin-bottom: 1rem;

    font-weight: 400;
    text-align: center;
`;
export const MessageItemValues = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const FilterButton = styled.button`
    background: transparent;
    border: none;
    transform: translateX(-10px);
    z-index: 10;
`;

export const FilterIcon = styled(FilterList)``;

export const InputFilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`;

export const Select = styled.select`
    background: #fafafa;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    width: 300px;

    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};
    color: ${(props) => props.theme.dark};

    &:active,
    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Option = styled.option`
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 300;
    color: ${(props) => props.theme.dark};
`;
