
import { Box, Button } from '@mui/material';
import { useMemo, useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import { Tag } from './Tag';


const PrintTag = ({ data }) => {
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const toPrint = useMemo(() => {
        const paginatedTags = data.reduce(
            (acc, curr) => {
                if (acc[acc.length - 1].length > 4) {
                    acc.push([curr]);
                } else {
                    acc[acc.length - 1].push(curr);
                }
                return acc;
            },
            [[]],
        );

        return paginatedTags;
    }, [data]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}>
            <Button
                variant="contained"
                onClick={handlePrint}
            >
                Imprimir
            </Button>
            <div
                ref={componentRef}
                style={{
                    display: 'block',
                    flex: 1,
                    width: '100%',
                }}
            >
                {toPrint.map(tag => (
                    <Tag data={tag} key={tag[0].alphaCode} />
                ))}
            </div>
        </Box>
    );
};

export default PrintTag;
