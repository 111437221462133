import { Help } from '@material-ui/icons';
import { Alert, AlertTitle, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import handleCloseAction from '../../../../../components/HandleCloseAction';

const variants = {
    success: 'success',
    error: 'error',
    offCoverage: 'warning'
};

const ListItem = ({ order, handleUpdatedPackageOnTrack = () => {} }) => {
    const isOutOfTrack = useMemo(() => String(order.description).includes('esteira'), [order]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleUpdated() {
        handleUpdatedPackageOnTrack(order.number);
        enqueueSnackbar('Atualização solicitada! Refaça a operação em alguns instantes', {
            variant: 'success',
            action: (id) => handleCloseAction(id, closeSnackbar)
        });
    }

    return (
        <Alert
            sx={{
                width: '100%'
            }}
            severity={variants[order.status] || 'error'}>
            <AlertTitle>{order.number}</AlertTitle>

            <Stack
                width="100%"
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}>
                {order.description}

                {isOutOfTrack && (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            marginTop: 1
                        }}>
                        <Button onClick={() => handleUpdated()} variant="contained">
                            Atualizar na esteira
                        </Button>

                        <Tooltip title="Esta ação não vai realizar a operação por você. Após atualizar tente novamente realizar a operação desejada.">
                            <IconButton>
                                <Help />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>
        </Alert>
    );
};

export default ListItem;
