import React, { useMemo, useState } from 'react';
import {
    Container,
    LastDelivered,
    Name,
    LastDeliveredDate,
    LastDeliveredLocation,
    LastDeliveredLabel,
    Info,
    CloseIcon,
    OpenIcon,
    ToggleButton,
    Header,
    OrderCode,
    OrderCodeScheduleDate,
    OrderContract,
    OrderLocation,
    OrdersContainer,
    OrdersItem,
    OrderReceiver,
    InfoItem,
    InfoItemLabel,
    InfoItemNumber
} from './styles';
import { Card } from '../../../../components';
import { DriverPackagesDownload } from '../DriverPackagesDownload'

const DriverCard = ({ data }) => {
    const [showOrders, setShowOrders] = useState(false);

    const driver = useMemo(() => {
        const { driver, id, deliveries, lastDelivery } = data;

        const invalid = deliveries.filter((d) => !d.sla).length;

        const late = deliveries.filter(
            (d) => !!d.sla && new Date(d.sla).toISOString() < new Date().toISOString()
        ).length;

        const ontime = deliveries.filter(
            (d) => !!d.sla && new Date(d.sla).toISOString() > new Date().toISOString()
        ).length;

        const lastDeliveryObj = {
            date: lastDelivery.deliveredAt
                ? new Date(lastDelivery.deliveredAt).toLocaleString('pt-BR')
                : 'Nenhuma',
            neighborhood: lastDelivery.neighborhood || null,
            order: lastDelivery.package || null
        };

        const formattedDeliverires = deliveries.map((d) => {
            const slaDate = new Date(d.sla).toISOString();

            const isLate = slaDate < new Date().toISOString();

            const slaState = !d.sla ? 'invalid' : isLate ? 'late' : 'ontime';

            const sla = !!d.sla ? new Date(d.sla).toLocaleString('pt-BR') : 'Não coletado';

            const formattedPackage = d.package.includes('-')
                ? d.package.split('-').join('')
                : d.package;

            const order = {
                slaState,
                sla,
                package: formattedPackage,
                recipient: d.recipient,
                neighborhood: d.neighborhood,
                contract: d.contract
            };

            return order;
        });

        return {
            name: driver,
            id,
            ontime,
            late,
            invalid,
            lastDelivery: lastDeliveryObj,
            deliveries: formattedDeliverires
        };
    }, [data]);

    return (
        <Card>
            <Container>
                <Header>
                    <Name>{driver.name}</Name>
                    <DriverPackagesDownload deliveries={driver.deliveries} />
                    <ToggleButton onClick={() => setShowOrders(!showOrders)}>
                        {showOrders ? <CloseIcon /> : <OpenIcon />}
                    </ToggleButton>
                </Header>
                <Info>
                    <InfoItem>
                        <InfoItemLabel>Pendentes</InfoItemLabel>
                        <InfoItemNumber>{driver.ontime}</InfoItemNumber>
                    </InfoItem>
                    <InfoItem>
                        <InfoItemLabel>Atrasados</InfoItemLabel>
                        <InfoItemNumber>{driver.late}</InfoItemNumber>
                    </InfoItem>
                    {!!driver.invalid && (
                        <InfoItem>
                            <InfoItemLabel>Não coletado</InfoItemLabel>
                            <InfoItemNumber>{driver.invalid}</InfoItemNumber>
                        </InfoItem>
                    )}
                </Info>
                <LastDelivered>
                    <LastDeliveredLabel>Última Entrega</LastDeliveredLabel>
                    <LastDeliveredLocation>
                        {driver.lastDelivery.neighborhood}
                    </LastDeliveredLocation>
                    <LastDeliveredDate>{driver.lastDelivery.date}</LastDeliveredDate>
                </LastDelivered>
                {showOrders && (
                    <OrdersContainer>
                        {driver.deliveries.map((d) => (
                            <OrdersItem key={d.package} slaState={d.slaState}>
                                <OrderCode>Cód: {d.package}</OrderCode>
                                <OrderContract>Contrato: {d.contract}</OrderContract>
                                <OrderLocation>Local: {d.neighborhood}</OrderLocation>
                                <OrderCodeScheduleDate>Prazo:{d.sla}</OrderCodeScheduleDate>
                                <OrderReceiver>Dest.: {d.recipient}</OrderReceiver>
                            </OrdersItem>
                        ))}
                    </OrdersContainer>
                )}
            </Container>
        </Card>
    );
};

export default DriverCard;
