import styled from 'styled-components';

const cardBreakpoint = '520px';

export const Container = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 5px 0px;

    & + & {
        border-top: 1px solid black;
    }

    @media screen and (min-width: ${cardBreakpoint}) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
    }
`;

export const HistoryItemLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const HistoryItemInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 5px;

    @media screen and (min-width: ${cardBreakpoint}) {
        padding: 0px;
        /* width: 100%; */
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
`;

export const InfoLabel = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const InfoValue = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.light};
    padding: 1px;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const DeleteButton = styled.button`
    border: none;
    background: transparent;
    width: fit-content;
`;

export const DeleteIcon = styled.span`
    color: ${(props) => props.theme.danger};
`;

export const DeleteConfirmDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    & > h3 {
        margin: 4px 0px;
        padding: 0px;
    }
    & > p {
        margin: 4px 0px;
        padding: 0px;
    }
`;
