import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > p {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 1200px;

  text-align: center;
`;
export const Caption = styled.caption`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 8px;
`;
export const THead = styled.thead`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.secondary};
`;

export const Tr = styled.tr`
  padding: 4px;

  background-color: ${(props) => (Number(props.real) >= 0) ? '#b0f16e' : '#f17b6e'};
  color: ${(props) => props.theme.dark};

`;
export const Th = styled.th`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  padding: 4px;

  min-width: 20px;

  thead > & {
    padding: 8px;
    background-color: aliceblue;
  }
`;

export const Td = styled.td`
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  padding: 8px;
`;
