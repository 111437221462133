import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { json2excel } from 'js2excel';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMemo } from 'react';
import { Button } from '../../../../components';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { Caption, Container, Table, Td, Th, THead, Tr } from './style';
import { logError } from '../../../../utils/error';

const DriversSummary = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function driversFormatting(driverList) {
        if (!driverList) return [];
        const formatted = driverList.map((d) => {
            const empty = Number(d.deliveries) === 0;

            const percent = `${
                !empty ? Number((Number(d.onTime) / Number(d.deliveries)) * 100).toFixed(2) : 0
            }%`;

            return { ...d, percent };
        });

        return formatted.sort((a, b) => {
            if (a.driver > b.driver) return 1;
            if (b.driver > a.driver) return -1;
            return 0;
        });
    }

    const { isLoading, data } = useQuery(
        ['kpis/panel'],
        async () => {
            const { data } = await api.get('/kpis/panel');
            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Resumo dos motoristas atualizado...', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const drivers = useMemo(() => {
        if (!data) return [];

        return driversFormatting(data.drivers);
    }, [data]);

    function handleExport() {
        try {
            const formatted = drivers.map((d) => {
                return {
                    motorista: d.driver,
                    pendentes: d.pendent,
                    entregas_no_prazo: `${d.deliveries} (${d.onTime} - ${d.percent})`,
                    ocorrencias: d.occurrences
                };
            });

            json2excel({
                data: formatted,
                name: `resumo-motoristas-${Date.now()}`,
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);
        }
    }

    if (drivers.length === 0 && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    if (isLoading) {
        return <Loader color="#000" />;
    }

    return (
        <Container>
            <Table>
                <Caption>
                    Resumo dos Motoristas
                    <Button
                        style={{ width: 'fit-content', marginLeft: '16px' }}
                        onClick={() => handleExport()}>
                        <span className="material-icons">file_download</span>
                    </Button>
                </Caption>

                <THead>
                    <Tr>
                        <Th scope="col">Motoristas</Th>
                        <Th scope="col">Pendentes</Th>
                        <Th scope="col">Entregas no Prazo</Th>
                        <Th scope="col">Ocorrências</Th>
                    </Tr>
                </THead>
                <tbody>
                    {drivers.map((d) => (
                        <Tr key={d.driverId}>
                            <Th scope="row">{d.driver}</Th>
                            <Td>{d.pendent}</Td>
                            <Td>
                                {d.deliveries} ({d.onTime} - {d.percent})
                            </Td>
                            <Td>{d.occurrences}</Td>
                        </Tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default DriversSummary;
