import { Container, theme } from './styles';
import { Table } from '../Table'
import status from '../../utils/status.json';
import { useMemo } from 'react';

const StockByStatus = ({ footer, children, data }) => {
    const items = useMemo(() => {
        const statusList = Object.keys(status);

        return data
            .map((s) => {
                return { ...s, theme: theme[status[s.status]] };
            })
            .sort((a, b) => {
                if (statusList.indexOf(a.status) > statusList.indexOf(b.status)) {
                    return 1;
                }

                if (statusList.indexOf(b.status) > statusList.indexOf(a.status)) {
                    return -1;
                }

                return 0;
            });
    }, [data]);

    return (
        <Container>
            <Table>
                <thead>
                    <tr>{children}</tr>
                </thead>
                <tbody>
                    {items.map((s) => (
                        <tr style={s.theme} key={s.status}>
                            <td>{s.status}</td>
                            <td>{s.quantity}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>{footer}</td>
                    </tr>
                </tfoot>
            </Table>
        </Container>
    );
};

export { StockByStatus };
