export function dateFromISO(date) {
    return new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        new Date(date).getHours() + 3
    ).toLocaleDateString('pt-BR');
}
export function dateTimeFromISO(date) {
    return new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        new Date(date).getHours() + 3
    ).toLocaleString('pt-BR');
}

export function toCurrency(number) {
    let value = String(number);

    const currencyPrefix = new RegExp(/[R$]/gm);

    if (value.match(currencyPrefix)) {
        value = value.replace(currencyPrefix, '');
    }

    value = value.replace(',', '.');
    const nonSignificantPoint = new RegExp(/\.+(\d{2}.)/gm);

    if (value.match(nonSignificantPoint)) {
        value = value.replace('.', '');
    }

    return value;
}

export function capitalize(n) {
    const fitstWord = [...n][0].toUpperCase();

    const remainings = [...n].splice(1);

    const result = [fitstWord, ...remainings].join('');

    return result;
}

export function coordinatesAvg(coordList) {
    const coordsSum = coordList.reduce(
        (acc, curr) => {
            acc.lat += curr.lat;
            acc.lng += curr.lng;

            return acc;
        },
        { lat: 0, lng: 0 }
    );

    const avg = {
        lat: coordsSum.lat / coordList.length,
        lng: coordsSum.lng / coordList.length
    };

    return avg;
}

export function linesToArray(lines) {
    const text = String(lines).trim();

    const toArray = text
        .split('\n')
        .map((i) => i.trim())
        .filter((c) => !!c);

    return toArray;
}

export function textToCodeArray(text) {
    const array = linesToArray(text);

    const removeTrace = array.map((c) => {
        if (c.includes('-')) {
            const newCode = c.split('-').join('');
            return newCode;
        }
        return c;
    });

    return removeTrace;
}

export const deliveriesFormattingForBlockedDeliveriesPanel = (deliveries) =>
    deliveries.reduce((acc, curr) => {
        const requestIndex = acc.findIndex((pkg) => pkg.code === curr.code);

        if (requestIndex === 1) {
            acc[requestIndex].curr.occurrence;
            acc[requestIndex].history.push({
                index: acc[requestIndex].history.length + 1,
                occurrence: curr.occurrence,
                requestDate: curr.requestDate,
                slaResolution: curr.slaResolution
            });
        }

        if (requestIndex === -1) {
            acc.push({
                code: curr.code,
                status: curr.status,
                driver: curr.driver,
                phone: curr.phone,
                currentOccurrence: curr.occurrence,
                history: [
                    {
                        index: 1,
                        occurrence: curr.occurrence,
                        requestDate: curr.requestDate,
                        slaResolution: curr.slaResolution
                    }
                ]
            });
        }

        return acc;
    }, []);
