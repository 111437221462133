import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

const DriverSelect = ({
    disabled = false,
    onChange,
    placeholder = 'Motorista',
    style = { width: '300px' },
    pickupDriver = false
}) => {
    const { isLoading, data: drivers } = useQuery(
        ['operations/drivers'],
        async () => {
            const { data } = await api.get('/operations/drivers');

            return data;
        },
        {
            placeholderData: () => {
                return [];
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const availableDrivers = useMemo(() => {
        if (!drivers) return [];

        if (pickupDriver) {
            return drivers
                .filter((d) => d.pickupDriver === true)
                .map((d) => ({ label: d.name, id: d.id }));
        }

        return drivers.map((d) => ({ label: d.name, id: d.id }));
    }, [drivers, pickupDriver]);

    return isLoading ? (
        <Loader />
    ) : (
        <Autocomplete
            openOnFocus
            style={style}
            disabled={disabled}
            defaultValue=""
            id="driver-selector"
            label={placeholder}
            options={availableDrivers}
            renderInput={(params) => <TextField {...params} label={placeholder} />}
            onChange={(_, { id }) => onChange(id)}
            noOptionsText="Nenhuma correspondência"
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};

export default DriverSelect;
