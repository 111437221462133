import { useMemo } from 'react';
import logo from '../../../assets/img/logo-white.png';
import Barcode from '../../Barcode';
import {
    Container,
    TagUI,
    TagBody,
    TagHeader,
    Text,
    Logo,
    Title,
    TextContainer,
} from './styles';


const Tag = ({ data }) => {
    const tags = useMemo(() => {
        return data;
    }, [data]);

    return (
        <Container>
            {tags.map(tag => (
                <TagUI variant="elevation" key={tag.alphaCode}>
                    <TagHeader>
                        <Logo src={logo} alt="logo" />
                        <Title variant="h6">{tag.customer}</Title>
                    </TagHeader>
                    <TagBody>
                        <TextContainer>
                            <Title>{tag.recipient}</Title>
                            <Text>
                                {tag.address}, {tag.addressNumber}
                            </Text>
                            <Text>{tag.neighborhood}</Text>
                            <Text>
                                {tag.city}/{tag.state}
                            </Text>
                            <Text>Telefone: {tag.phone}</Text>
                        </TextContainer>
                    </TagBody>
                    <Barcode code={tag.alphaCode} />
                </TagUI>
            ))}
        </Container>
    );
};

export { Tag };
