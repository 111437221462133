import { Alert, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { Button, Input } from '../../../../components';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { isValid } from '../../../../utils/validations';
import CollectorDriverOrderCard from '../../components/CollectorDriverOrderCard';
import DeliveryModalities from '../../components/DeliveryModalities';
import DockSelect from '../../components/DockSelect';
import DriverSelect from '../../components/DriverSelect';
import {
    CardList,
    Container,
    Header,
    MessageItem,
    MessageItemLabel,
    MessageItemValues,
    Title
} from './styles';
import { useSnackbar } from 'notistack';
import { logError } from '../../../../utils/error';

function orderFiltering(orderList, contract, search, neighborhood) {
    const filteredOrders = orderList
        .filter((o) => (!!contract ? o.contractId === contract : true))
        .filter((o) => (!!neighborhood ? o.neighborhood.toLowerCase()  === neighborhood : true ))
        .filter((o) => o.alphaCode.toLowerCase().includes(search.toLowerCase()));
    return filteredOrders;
}

const CollectExternal = () => {
    const [customer, setCustomer] = useState(null);
    const [neighborhood, setNeighborhood] = useState(null);
    const [validNeighborhoods, setValidNeighborhoods] = useState([]);
    const [contract, setContract] = useState(null);
    const [driver, setDriver] = useState(null);
    const [destination, setDestination] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [search, setSearch] = useState('');
    const [message, setMessage] = useState([]);
    const [selected, setSelected] = useState([]);
    const [deliveryModality, setDeliveryModality] = useState('154');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading: isOrdersLoading, data: orderList } = useQuery(
        [`operations/collect${customer ? `/${customer}` : ''}`],
        async () => {
            if (!customer) return [];

            const response = await api.get(`/operations/collect/${customer}`);

            setContract(null);
            setSearch('');
            setSelected([]);
            setNeighborhood(null)

            return response.data;
        },
        {
            staleTime: 5000 // 5 seconds
        }
    );

    const { isLoading: isCustomersLoading, data: customersList } = useQuery(
        ['customers'],
        async () => {
            const response = await api.get('/customers');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const customers = useMemo(() => {
        if (!customersList) return [];

        const validCustomers = customersList.filter((cust) => {
            const validContracts = cust.contracts.filter((contr) => !!contr.requireCollect);

            if (validContracts.length > 0) return true;

            return false;
        });

        return validCustomers;
    }, [customersList]);

    const contracts = useMemo(() => {
        if (!customers || !customer) return [];

        const selectedCustomer = customers.find((c) => c.id === customer);

        const validContracts = selectedCustomer.contracts.filter(
            (c) => !c.deletedAt && !!c.requireCollect
        );

        return validContracts;
    }, [customer, customers]);



    const orders = useMemo(() => {
        if (!orderList || orderList.length === 0) return [];

        const customerRelated = customers.find((c) => c.id === customer);

        const neighborhoods = orderList.map((o) => o.neighborhood.toLowerCase());

        const uniqueValues = [...new Set(neighborhoods)];

        setValidNeighborhoods(uniqueValues);

        const collectRequiredContracts = customerRelated.contracts
            .filter((c) => !!c.requireCollect)
            .map((c) => c.id);

        const validOrders = orderList.filter((o) =>
            collectRequiredContracts.includes(o.contractId)
        );

        return orderFiltering(validOrders, contract, search, neighborhood);
    }, [orderList, contract, search, neighborhood]);

    function resetForm() {
        setCustomer(null);
        setContract(null);
        setNeighborhood(null)
        setSearch('');
        setSelected([]);
    }

    async function handleChangeCustomer(id) {
        if (!isValid(id)) {
            resetForm();
            return;
        }

        const customerId = String(id);

        setCustomer(customerId);
    }

    function handleSelectOrder(code) {
        const alreadyExist = selected.includes(code);

        if (alreadyExist) {
            const newSelectedOrders = selected.filter((o) => o !== code);

            setSelected(newSelectedOrders);
        } else {
            setSelected([...selected, code]);
        }
    }


    function handleSelectAll() {
      const filteredOrders = orderFiltering(orders, contract, search, neighborhood);
      const filteredOrderCodes = filteredOrders.map((o) => o.alphaCode);

      const selectedAll =
        !!filteredOrders && filteredOrders.length > 0 && selected.length === filteredOrders.length;

      if (selectedAll) {
        setSelected([]);
      } else {
        setSelected(filteredOrderCodes);
      }
    }



    async function handleAssignToDriver() {
        if (selected.length > 0) {
            const assignmentObject = {
                driver,
                deliveryModality,
                packages: selected.map((s) => {
                    return {
                        term: s
                    };
                })
            };

            if (destination) {
                Object.assign(assignmentObject, { destination });
            }

            if (!!destination && !!driver) {
                const listObj = {
                    destination,
                    driver
                };

                const { data } = await api.post('/operations/list', listObj);

                if (data && assignmentObject) {
                    Object.assign(assignmentObject, { list: data.id });
                }
            }

            api.post('/operations/collect', assignmentObject)
                .then((response) => {
                    const { errors } = response.data;

                    const errorCodes = errors.map((e) => e.term) || [];

                    let messages = [];
                    if (errorCodes.length > 0) {
                        messages.push({
                            msg: 'Erros detectados',
                            data: errors
                        });

                        if (selected.length > errorCodes.length) {
                            const succeedPackages = selected.filter((s) => !errorCodes.includes(s));

                            enqueueSnackbar(`${succeedPackages.length} pacotes atribuidos`, {
                                variant: 'success',
                                action: (id) => handleCloseAction(id, closeSnackbar)
                            });
                            resetForm();
                        }
                    } else {
                        const succeedPackages = selected.filter((s) => !errorCodes.includes(s));

                        messages.push({
                            msg: 'Pacotes Atribuidos',
                            data: succeedPackages
                        });

                        enqueueSnackbar(`${selected.length} pacotes atribuidos`, {
                            variant: 'success',
                            action: (id) => handleCloseAction(id, closeSnackbar)
                        });

                        resetForm();
                    }

                    setMessage(messages);
                    setIsOpenModal(true);
                    setSearch('');
                })
                .catch((err) => logError(err));
        }
    }

    function handleCloseModal() {
        setIsOpenModal(false);
    }

    return (
        <Container>
            <Modal isOpen={isOpenModal} onRequestClose={handleCloseModal}>
                {!!message &&
                    message.length > 0 &&
                    message.map((m) => (
                        <MessageItem key={m.msg}>
                            <MessageItemLabel>{m.msg}</MessageItemLabel>
                            {m.data &&
                                m.data.map((item) => (
                                    <MessageItemValues key={item.term}>
                                        {item.term} {!!item.err && ` - ${item.err}`}
                                    </MessageItemValues>
                                ))}
                        </MessageItem>
                    ))}
            </Modal>
            <Title>Coleta Externa</Title>
            <Header>
                <Stack
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center">
                    <DriverSelect onChange={setDriver} style={{ width: '300px' }} pickupDriver />
                    <DeliveryModalities
                        style={{ width: '300px' }}
                        onChange={(v) => {
                            setDeliveryModality(v);
                        }}
                    />
                    <DockSelect
                        onChange={(e) => setDestination(e)}
                        placeholder="Destino"
                        style={{ width: '300px' }}
                    />
                    {
                        <FormControl variant="filled" style={{ width: '300px' }}>
                            <InputLabel id="customerSelector">Cliente</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="customerSelector"
                                id="customerSelector"
                                label="Cliente"
                                disabled={isCustomersLoading}
                                onChange={(e) => handleChangeCustomer(e.target.value)}>
                                {customers.map((c) => (
                                    <MenuItem value={c.id} key={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }

                    {contracts && contracts.length > 0 && (
                        <FormControl variant="filled" style={{ width: '300px' }}>
                            <InputLabel id="contractSelector">Modalidade</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="contractSelector"
                                id="contractSelector"
                                label="Modalidade"
                                onChange={(e) =>
                                    setContract(isValid(e.target.value) ? e.target.value : null)
                                }>
                                {contracts.map((c) => (
                                    <MenuItem value={c.id} key={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                  {validNeighborhoods && validNeighborhoods.length > 0  && (
                    <FormControl variant="filled" style={{ width: '300px' }}>
                      <InputLabel id="neighborhoodSelector">Bairro</InputLabel>
                      <Select
                        defaultValue=""
                        value={neighborhood}
                        labelId="neighborhoodSelector"
                        id="neighborhoodSelector"
                        label="Bairro"
                        onChange={(e) => setNeighborhood(e.target.value)}
                      >
                        {validNeighborhoods.map((n) => (
                          <MenuItem value={n} key={n}>
                            {n}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                    {orders && orders.length > 0 && (
                        <>
                            <Input
                                placeholder="Pesquisar cod. pacote..."
                                style={{ width: '300px' }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button
                                style={{ marginTop: '20px', width: 'fit-content' }}
                                onClick={() => handleSelectAll()}>
                                Selecionar todos
                            </Button>
                        </>
                    )}
                    <Button
                        style={{ marginTop: '20px' }}
                        onClick={() => handleAssignToDriver()}
                        disabled={!driver || selected.length === 0}>
                        Atribuir
                    </Button>
                </Stack>
            </Header>
            <CardList>
                {isOrdersLoading ? (
                    <Loader color="#000" />
                ) : !isOrdersLoading && orders.length === 0 ? (
                    <Alert
                        severity="success"
                        sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                        Nenhuma atualização
                    </Alert>
                ) : (
                    orders.map((o, i) => (
                        <CollectorDriverOrderCard
                            key={o.alphaCode + i}
                            data={o}
                            selected={selected.includes(o.alphaCode)}
                            onCheck={(v) => handleSelectOrder(v)}
                        />
                    ))
                )}
            </CardList>
        </Container>
    );
};

export default CollectExternal;
