import { useState } from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMemo } from 'react';

const statusColor = {
  'Pendente':'#808080',
  'Aguardando Coleta':'#0000ff',
  'Coletado':'#0000ff',
  'Armazenado':'#ffa500',
  'Transferência entre Unidades':'#ffa500',
  'Entrega em Andamento': '#42ffad',
  'Entregue':'#00691b',
  'Pronto para retirada':'#00691b',
  'Pedido Cancelado':'#800080',
  'Aguardando Devolução': '#ffa500',
  'Devolvido':'#800080',
  'Extraviado':'#800080',
}

const options = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timezone: 'America/Sao_Paulo'
}

const TableItems = ({row:{packageCode,currentStatus, statusDate, ocurrence, receiver, events}}) => {
  const [open, setOpen] = useState(false)

  const dateFormat = ({createdAt})=>(
    new Intl.DateTimeFormat('pt-BR', options).format(new Date(createdAt))
  )

  const eventHistory = useMemo( () =>
    events.map(e => ({
      ...e,
      statusColor:statusColor[e.status] || '#ffa600',
      dateFormated:dateFormat(e)
    }))
  ,[events])

  return(
    <>
    <TableRow sx={{backgroundColor: '#e7f1fb', '& > *': { borderBottom: 'unset' }}}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell sx={{fontSize: 'medium'}} component="th" scope="row">
        {packageCode}
      </TableCell>
      <TableCell sx={{fontSize: 'medium'}} align="center">
            {currentStatus}
      </TableCell>
      <TableCell sx={{fontSize: 'medium'}} align="center">{statusDate}</TableCell>
      <TableCell sx={{fontSize: 'medium'}} align="center">{ocurrence}</TableCell>
      <TableCell sx={{fontSize: 'medium'}} align="center">{receiver}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography gutterBottom component="div">
              Histórico
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell >
                    <Typography >
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography >
                      Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventHistory.map((event) => (
                  <TableRow key={event.createdAt}>
                    <TableCell sx={{width: '500px'}}scope="row">
                      <Typography
                        sx={{
                          color: 'white',
                          textAlign: 'center',
                          backgroundColor: event.statusColor,
                          borderRadius: '15px',
                          maxWidth: '150px',
                          width: '100%',
                          padding: '5px'
                        }}>
                          {event.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {event.dateFormated}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow></>
  )
}

export { TableItems }
