import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { json2excel } from 'js2excel';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import api from '../../../../services/api';
import terms from '../../../../utils/searchTerms.json';
import { local } from '../../../../utils/storage';
import CollectReport from '../../components/CollectReport';
import DockSelect from '../../components/DockSelect';
import DriverSupplier from '../../components/DriverSupplier';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendOrder, setOrder } from '../../utils/OperationActions';
import { Container, LowerSection, Title } from './styles';
import { logError } from '../../../../utils/error';

const Collect = () => {
    const [driver, setDriver] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const [log, setLog] = useState(() => {
        const localPackagesCollected = local.getEncrypted('@vaifacilbr_packages_collected');

        if (localPackagesCollected) {
            return JSON.parse(localPackagesCollected);
        }

        return [];
    });
    const [openModal, setOpenModal] = useState(false);
    const componentRef = useRef(null);

    const inputRef = useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data: docks } = useQuery(
        ['docks'],
        async () => {
            const response = await api.get('/docks');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    async function handleCollect(target) {
        try {
            if (!target.value) return;

            const orderCode = target.value;

            target.value = '';
            target.focus();

            if (orders.map((o) => o.number).includes(orderCode)) {
                enqueueSnackbar(`Pedido "${orderCode}" já foi registrado nesta tentantiva`, {
                    variant: 'info',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
                return;
            }

            const orderObject = setOrder({
                dock: origin,
                destination,
                order: orderCode
            });

            if (term) {
                Object.assign(orderObject, { searchBy: term });
            }

            if (!!driver) {
                Object.assign(orderObject, driver);
            }

            if (!!origin && !!destination && !!driver && !!driver.driver) {
                const listObj = {
                    origin,
                    destination,
                    isRemunerated: true
                };

                if (!!driver) {
                    Object.assign(listObj, driver);
                }

                const { data } = await api.post('/operations/list', listObj);

                if (data && orderObject) {
                    Object.assign(orderObject, { list: data.id });
                }
            }

            if (orderObject) {
                const response = await sendOrder({
                    payload: orderObject,
                    operation: 'collect'
                });

                if (response.type === 'toast') {
                    enqueueSnackbar(response.data, {
                      action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                } else {
                    setOrders([...response.data, ...orders]);
                }

                if (response.type === 'success') {
                    const { dock: Origin } = docks.find((d) => d.id === origin);
                    const { dock: Destination } =
                        destination !== null
                            ? docks.find((d) => d.id === destination)
                            : { id: '0', dock: 'N/D' };
                    const data = {
                        code: response.data[0].number,
                        Origin,
                        Destination
                    };
                    setLog([data, ...log]);
                    local.setEncrypted(
                        '@vaifacilbr_packages_collected',
                        JSON.stringify([data, ...log])
                    );
                }
            }
        } catch (error) {
            logError(error);
        }
    }

    async function handleExport() {
        try {
            json2excel({
                data: log,
                name: `coletados-${Date.now()}`,
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);

            enqueueSnackbar('Falha ao gerar arquivo, tente novamente', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    function handleReset() {
        setOrders([]);
        setLog([]);
        local.removeEncrypted('@vaifacilbr_packages_collected');
    }

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Container>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Title>COLETA</Title>
                <DriverSupplier onChange={(e) => setDriver(e)} noLabor externalTransfer />
                <DockSelect onChange={setOrigin} placeholder="Origem" required />
                <DockSelect
                    onChange={setDestination}
                    placeholder="Destino"
                    disabled={!origin || !driver}
                />
                <FormControl
                    variant="filled"
                    sx={{ m: 1, minWidth: 300 }}
                    disabled={!origin || !driver}
                    required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    ref={inputRef}
                    disabled={!origin || !driver}
                    label="Digite aqui para buscar..."
                    style={{ width: '300px' }}
                    variant="standard"
                    onKeyDown={(event) => event.key === 'Enter' && handleCollect(event.target)}
                />

                <Stack direction="row" justifyContent="space-evenly" width="100%">
                    <Button
                        variant="contained"
                        style={{ margin: '8px' }}
                        onClick={() => handleExport()}
                        disabled={log.length === 0}>
                        Exportar
                    </Button>

                    <Button
                        variant="contained"
                        style={{ margin: '8px' }}
                        onClick={() => setOpenModal(true)}
                        disabled={log.length === 0}>
                        Romaneio
                    </Button>

                    <Button
                        variant="contained"
                        style={{ margin: '8px' }}
                        onClick={() => handleReset()}>
                        Limpar
                    </Button>
                </Stack>
            </Stack>

            <LowerSection>
                <OperationPackagesFeedback
                    orders={orders}
                    handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                />
            </LowerSection>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Box
                    height="80%"
                    width="80%"
                    overflow="auto"
                    bgcolor="white"
                    display="flex"
                    flexDirection="column"
                    padding="1rem">
                    <Button
                        variant="contained"
                        style={{ margin: '8px' }}
                        onClick={() => handlePrint()}>
                        Gerar Romaneio
                    </Button>
                    <Box
                        padding="1rem"
                        height="100%"
                        flex="1"
                        ref={componentRef}
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <CollectReport rows={log} />
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default Collect;
