import { useMemo } from 'react';
import logo from '../../../../../../assets/img/logo-white.png';
import Barcode from '../../../../../../components/Barcode';
import { Container, TagUI, TagBody, TagHeader, Text, Logo, Title, TextContainer } from './styles';

const Tag = ({ data }) => {
    const tags = useMemo(() => {
        return data;
    }, [data]);

    return (
        <Container>
            {tags.map((tag) => (
                <TagUI variant="elevation" key={tag.alphaCode}>
                    <TagHeader>
                        <Logo src={logo} alt="logo" />
                        <Title variant="h6">{tag.customer}</Title>
                    </TagHeader>
                    <TagBody>
                        <TextContainer>
                            <Title>{tag.recipient.name}</Title>
                            <Text>
                                {tag.recipient.address.location.address},
                                {tag.recipient.address.number}
                            </Text>
                            <Text>{tag.recipient.address.location.neighborhood} - CEP {tag.recipient.address.location.zipcode}</Text>
                            <Text>
                                {tag.recipient.address.location.city}/
                                {tag.recipient.address.location.state}
                            </Text>
                            {tag.recipient.phones.length > 0 && (
                                <Text>Telefone: {tag.recipient.phones[0].number}</Text>
                            )}
                        </TextContainer>
                    </TagBody>
                    <Barcode code={tag.alphaCode} />
                </TagUI>
            ))}
        </Container>
    );
};

export { Tag };
