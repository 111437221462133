import { Button, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useState } from 'react';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const Step3 = ({ handleSubmit = () => {}, data: { packages, description } }) => {
    const [open, setOpen] = useState(false);

    function submit() {
        handleSubmit();
        setOpen(false);
    }

    return (
        <Stack
            width="100%"
            height="100%"
            spacing={2}
            padding={4}
            direction="column"
            justifyContent="flex-start"
            alignItems="center">
            <Typography fontSize="2rem" fontWeight="600">
                Revisão
            </Typography>

            <Typography fontSize="1rem" fontWeight="400" textAlign="center">
                Revise aqui os detalhes sobre esta interação
            </Typography>

            <Typography
                fontStyle="italic"
                color="crimson"
                fontSize="1rem"
                fontWeight="400"
                textAlign="center">
                Certifique-se dos detalhes pois está realizando uma operação em lote, erros neste
                tipo de operação podem afetar negativamente seu trabalho.
            </Typography>

            <Stack
                direction="row"
                width="100%"
                height="100%"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={4}
                sx={{
                    overflowY: 'auto'
                }}>
                <TableContainer component={Paper} sx={{ width: 'fit-content', minWidth: '250px' }}>
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Pacotes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowY: 'auto' }}>
                            {packages.map((item) => (
                                <TableRow
                                    key={item}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">{item}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack
                    direction="column"
                    width="100%"
                    height="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    paddingLeft={4}
                    borderLeft="1px solid rgba(0,0,0,0.1)">
                    <Typography fontSize="1.4rem" fontWeight="600" textAlign="center">
                        Descrição da interação
                    </Typography>

                    <Typography
                        width="100%"
                        fontStyle="italic"
                        fontSize="1.2rem"
                        fontWeight="400"
                        textAlign="left">
                        {description}
                    </Typography>
                </Stack>
            </Stack>

            <Button fullWidth variant="contained" onClick={() => setOpen(true)}>
                Confirmar
            </Button>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title">
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Confirmar interação
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você está prestes a confirmar uma interação em lote, ao clicar em confirmar,
                        você assume total responsabilidade pela alterações realizadas por esta ação
                        e que tem plena ciência dos impactos.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => submit()}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default Step3;
