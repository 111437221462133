import React, { useMemo, useState } from 'react';
import { Container, Title, Order, Input } from './styles';
import { Button } from '../../../../../components';
import DockSelect from '../../DockSelect';
import { useSnackbar } from 'notistack';
import api from '../../../../../services/api';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { logError } from '../../../../../utils/error';

const CancelOrder = ({ order, onInteract }) => {
    const [description, setDescription] = useState('');
    const [dock, setDock] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleInteraction() {
        const interactionObject = {
            dock,
            packages: [
                {
                    term: order.alphaCode,
                    isReverse: order.isReverse,
                    data: {
                        description
                    }
                }
            ]
        };

        api.post('/operations/cancel', interactionObject)
            .then((response) => {
                const { errors } = response.data;
                if (errors.length > 0) {
                    enqueueSnackbar('Não foi possível completar a ação', {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                } else {
                    enqueueSnackbar('Ação realizada com sucesso!',
                    { variant: 'success',
                      action: (id) => handleCloseAction(id, closeSnackbar)
                    });

                    onInteract();
                }
            })
            .catch((error) => {
                logError(error);

                enqueueSnackbar('Verifique sua conexão ou se tem acesso a este recurso.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            });
    }

    const disableSend = useMemo(() => {
        const noDescription = description.trim().length < 5;

        return !dock || noDescription;
    }, [description, dock]);

    return (
        <Container>
            <Title>Cancelamento de pedido</Title>
            <Order>Pedido: {order.alphaCode}</Order>
            <DockSelect onChange={(e) => setDock(e)} required />
            <Input
                placeholder="Comente aqui..."
                style={{ marginTop: '10px' }}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
            />
            <Button
                onClick={() => handleInteraction()}
                style={{ marginTop: '10px' }}
                disabled={disableSend}>
                Registrar
            </Button>
        </Container>
    );
};

export default CancelOrder;
