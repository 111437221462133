import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const Title = styled.div`
    width: 100%;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 400;
    margin: 20px 0px;
    text-align: center;
`;

export const OrderList = styled.div`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e0e0e0;
    margin-top: 16px;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 16px;
`;

export const ShowSelectedButton = styled.button`
    background: transparent;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    margin: 20px 0px;
`;

export const Icon = styled.span`
    font-size: 30px;
    margin-left: 8px;
    color: ${(props) => (props.enable ? props.theme.success : props.theme.danger)};
`;
