import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Box, Input, InputAdornment, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/auth';
import { getMenu, toggleDrawer } from '../../../store/menuSlice';
import { menuModules } from '../../../utils/menu';
import MenuItem from './MenuItem';
import PageItem from './MenuItem/PageItem';
import { Drawer, DrawerHeader } from './styles';
const Menu = () => {
    const [search, setSearch] = useState('');
    const theme = useTheme();

    const { getAuthorization } = useAuth();

    const { drawer: open } = useSelector(getMenu());

    const dispatch = useDispatch();

    function handleToggle() {
        dispatch(toggleDrawer());
    }

    const menu = useMemo(() => {
        const getAllowedMenuItems = (items) => {
            return items.filter((item) => {
                const allowedPages = item.pages.filter((page) => getAuthorization(page.name));

                const allowedSubItems = getAllowedMenuItems(item.subitems);

                return allowedPages.length > 0 || allowedSubItems.length > 0;
            });
        };

        const authorizedItems = getAllowedMenuItems(menuModules);

        return authorizedItems;
    }, [search]);

    const searchResults = useMemo(() => {
        if (search.length === 0) return null;

        const getItemPages = (items) => {
            return items.reduce((acc, curr) => {
                curr.pages.forEach((page) => acc.push(page));

                const subItemPages = getItemPages(curr.subitems);

                subItemPages.forEach((page) => acc.push(page));

                return acc;
            }, []);
        };

        const pages = getItemPages(menu);

        const filteredPages = pages.filter((page) =>
            String(page.label).toLowerCase().includes(String(search).toLowerCase())
        );

        return filteredPages;
    }, [search]);

    return (
        <Drawer variant="permanent" open={open}>
            <a href='https://operacao.vaifacil.com/' target="_blank" style={{ textDecoration: 'none', color: 'initial' }}>
            <ListItemButton
                sx={{ background: 'white'}}>
                <ListItemIcon>
                   <OpenInNewIcon/>
                </ListItemIcon>
                <ListItemText primary='App de Operações' />
            </ListItemButton>
            </a>
            <DrawerHeader>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    variant="standard">
                    <Input
                        placeholder="Buscar..."
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        id="search-input-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Box>
                <IconButton onClick={() => handleToggle()}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {!!searchResults ? (
                    searchResults.length === 0 ? (
                        <Box
                            sx={{
                                flex: 1,
                                padding: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Alert
                                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}
                                severity="info">
                                Nenhum resultado
                            </Alert>
                        </Box>
                    ) : (
                        searchResults.map((page) => <PageItem item={page} key={page.name} />)
                    )
                ) : (
                    menu.map((item) => <MenuItem item={item} key={item.name} />)
                )}
            </List>
        </Drawer>
    );
};

export default Menu;
