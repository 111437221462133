import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { CronJob } from 'cron';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import api from '../../../../../services/api';

const EditAddress = ({ order, onSave = () => {} }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const mountAddress = useCallback(
        (orderObj) => {
            const { complement, id, location, number, locationId } = orderObj.recipient.address;

            const { address, city, neighborhood, state, zipcode } = location;

            const baseAddress = {
                address,
                city,
                neighborhood,
                state,
                zipcode,
                complement,
                addressId: id,
                number,
                locationId,
                packageId: order.id,
                recipientId: order.recipient.id
            };

            return baseAddress;
        },
        [order.id, order.recipient.id]
    );

    const [address, setAddress] = useState(mountAddress(order));
    const [lockZip, setLockZip] = useState(false);
    const [loading, setLoading] = useState(false);

    const original = useMemo(() => mountAddress(order), [mountAddress, order]);

    const zipcodeChanged = useMemo(() => {
        if (!address || !original) return false;

        const baseZip = original.zipcode;
        const currentZip = address.zipcode;

        return baseZip !== currentZip;
    }, [address, original]);

    function handleSave() {
        const equalOriginal = JSON.stringify(original) === JSON.stringify(address);

        if (equalOriginal) {
            enqueueSnackbar('Não é possível salvar um registro que não foi modificado.', {
                variant: 'info',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        if (!address.number) {
            enqueueSnackbar('Informe o número do endereço', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }
        if (!address.zipcode) {
            enqueueSnackbar('Informe o CEP do endereço', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        const {
            city,
            neighborhood,
            state,
            zipcode,
            complement,
            addressId,
            number,
            locationId,
            packageId
        } = address;

        const updateObj = {
            number,
            complement,
            addressId,
            packageId
        };

        if (zipcodeChanged) {
            Object.assign(updateObj, {
                city,
                neighborhood,
                state,
                zipcode,
                address: address.address
            });
        } else {
            Object.assign(updateObj, {
                locationId
            });
        }

        api.put(`/operations/recipients/${original.recipientId}`, updateObj)
            .then((response) => {
                onSave(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function consultZipcode(zipcode) {
        setLoading(true);

        const { data } = await api.get(`/operations/zipcode/${zipcode}`);

        setAddress({
            ...address,
            ...data,
            number: '',
            complement: null
        });
        setLoading(false);

        setLockZip(true);

        const job = new CronJob(
            '*/1 * * * *',
            function () {
                setLockZip(false);
            },
            null,
            true,
            'America/Sao_Paulo'
        );
        job.start();
    }

    function handleUpdateZipcode(zipcode) {
        const newZipcode = String(zipcode).replace(/\D/gi, '');

        setAddress({
            ...address,
            zipcode: newZipcode,
            number: '',
            complement: null
        });

        const isValidZip = new RegExp(/\b\d{8}\b/gm);

        if (String(zipcode).match(isValidZip)) {
            consultZipcode(newZipcode);
        }
    }

    if (loading) {
        return (
            <Stack
                width="100%"
                minWidth="300px"
                height="100%"
                minHeight="300px"
                direction="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress size="4rem" color="inherit" />
            </Stack>
        );
    }

    return (
        <Stack
            width="100%"
            height="100%"
            maxHeight="80vh"
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            padding={2}>
            <Typography fontSize="1.5rem" fontWeight="300">
                Editar endereço
            </Typography>
            <Typography fontSize="1rem" fontWeight="800">
                Pacote: {order.alphaCode}
            </Typography>

            <TextField
                variant="standard"
                label="CEP"
                onChange={(e) => handleUpdateZipcode(e.target.value)}
                value={address.zipcode}
                disabled={lockZip}
            />

            <Stack
                width="100%"
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}>
                <TextField
                    onChange={(e) => setAddress({ ...address, city: e.target.value })}
                    variant="standard"
                    label="Cidade"
                    value={address.city}
                    disabled
                />
                <TextField
                    onChange={(e) => setAddress({ ...address, state: e.target.value })}
                    variant="standard"
                    label="Estado"
                    value={address.state}
                    disabled
                />
            </Stack>
            <TextField
                onChange={(e) => setAddress({ ...address, neighborhood: e.target.value })}
                variant="standard"
                label="Bairro"
                value={address.neighborhood}
                disabled
                fullWidth
            />

            <Stack
                width="100%"
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}>
                <TextField
                    onChange={(e) => setAddress({ ...address, address: e.target.value })}
                    variant="standard"
                    label="Logradouro"
                    value={address.address}
                    disabled
                    fullWidth
                />
                <TextField
                    width="100px"
                    variant="standard"
                    label="Número"
                    value={address.number}
                    required={!address.number}
                    onChange={(e) =>
                        setAddress({
                            ...address,
                            number: String(e.target.value).replace(/\D/gi, '')
                        })
                    }
                />
            </Stack>

            <TextField
                variant="standard"
                label="Complemento"
                value={address.complement || ''}
                multiline
                minRows={2}
                fullWidth
                onChange={(e) =>
                    setAddress({
                        ...address,
                        complement: (!!String(e.target.value).trim() && e.target.value) || null
                    })
                }
            />

            <Button onClick={() => handleSave()} style={{ marginTop: '16px' }}>
                Salvar
            </Button>
        </Stack>
    );
};

export default EditAddress;
