import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"

const CheckBoxItems = ({ xmlType, typesChecked, updateTypesChecked }) => {

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => updateTypesChecked(xmlType.initial)}
        dense
      >
        <ListItemIcon>
          <Checkbox
            placeholder={xmlType.name}
            key={xmlType.name}
            id={xmlType.name}
            edge="start"
            checked={typesChecked.includes(xmlType.initial)}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={xmlType.name} />
      </ListItemButton>
    </ListItem>
  )
}

export { CheckBoxItems }
