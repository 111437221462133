import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../../routes/Route';

import BlockedDeliveriesPanel from '../pages/BlockedDeliveriesPanel';
import DeliveriesPerHour from '../pages/DeliveriesPerHour';
import DriversSummary from '../pages/DriversSummary';
import Monitoring from '../pages/Monitoring';

const OperationRoutes = () => {
    return (
        <Switch>
            <Route path="/monitoramento" component={Monitoring} isPrivate />
            <Route path="/resumo-motoristas" component={DriversSummary} isPrivate />
            <Route path="/entregas-hora" component={DeliveriesPerHour} isPrivate />
            <Route path="/entregas-bloqueadas" component={BlockedDeliveriesPanel} isPrivate />
        </Switch>
    );
};

export default OperationRoutes;
