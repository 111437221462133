import { IconButton } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const handleCloseAction = (snackbarId, closeAction) => (
  <>
      <IconButton
          aria-label="close"
          onClick={() => {
              closeAction(snackbarId);
          }}>
          <CloseRoundedIcon />
      </IconButton>
  </>
)

export default handleCloseAction
