import { useState } from 'react';
import { Container, Heading, Logo, Text, Title } from './styles';
import { Button, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import logo from '../../../assets/img/logo-white.png';
import { Card } from '../../../components';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/auth';

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório')
});

const Login = () => {
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema,
        onSubmit: Logon
    });

    const [loading, setLoading] = useState(false);

    const { signIn } = useAuth();
    const history = useHistory();

    async function Logon({ username, password }) {
        setLoading(true);

        await signIn({ username, password });

        history.push('/inicio');

        setLoading(false);
    }

    const cardStyles = {
        maxWidth: '95vw'
    };

    return (
        <Container>
            {loading ? (
                <Loader style={{ width: '100vw', height: '100vh' }} size="5rem" color="white" />
            ) : (
                <Card
                    style={cardStyles}
                    onKeyDown={(e) => e.key === 'Enter' && formik.handleSubmit()}>
                    <Stack
                        style={{ maxWidth: '350px', minWidth: '300px', padding: '20px' }}
                        spacing={2}
                        gap={1}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Logo src={logo} />
                        <Heading>
                            <Title>Plataforma Vai Fácil,</Title>
                            <Text>utilize suas credenciais para continuar.</Text>
                        </Heading>
                        <TextField
                            label="Usuário"
                            onChange={formik.handleChange}
                            value={formik.username}
                            id="username"
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                            fullWidth
                            autoFocus
                        />
                        <TextField
                            fullWidth
                            label="Senha"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.password}
                            id="password"
                            style={{ marginTop: '10px' }}
                            autoComplete="current-password"
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={() => formik.handleSubmit()}>
                            ENTRAR NA PLATAFORMA
                        </Button>
                    </Stack>
                </Card>
            )}
        </Container>
    );
};

export default Login;
