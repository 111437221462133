export const operationBatchPages = [
    {
        active: true,
        endpoint: '/interacao-em-lote',
        label: 'Interação em Lote',
        name: 'batch-interactions',
        icon: 'PushPin'
    }
];

export const operationPages = [
    {
        active: true,
        endpoint: '/buscar',
        label: 'Buscar',
        name: 'search',
        icon: 'Search'
    },
    {
        active: true,
        endpoint: '/coleta-externa',
        label: 'Coleta Externa',
        name: 'collectExternal',
        icon: 'RvHookup'
    },
    {
        active: true,
        endpoint: '/coletar',
        label: 'Coletar',
        name: 'collect',
        icon: 'LocalShipping'
    },
    {
        active: true,
        endpoint: '/despachar',
        label: 'Despachar',
        name: 'dispatch',
        icon: 'LocalShipping',
        style: {
            transform: 'rotateY(180deg)'
        }
    },
    {
        active: true,
        endpoint: '/reverter',
        label: 'Reverter',
        name: 'reversing',
        icon: 'Replay'
    },
    {
        active: true,
        endpoint: '/confirmar-reversa',
        label: 'Confirmar Reversa',
        name: 'reversingConfirm',
        icon: 'CheckCircle'
    },
    {
        active: true,
        endpoint: '/realocar',
        label: 'Realocar',
        name: 'relocate',
        icon: 'SyncAlt'
    },
    {
        active: true,
        endpoint: '/transbordo',
        label: 'Transbordo',
        name: 'transfer',
        icon: 'SettingsEthernet'
    },
    {
        active: true,
        endpoint: '/integracoes-tardias',
        label: 'Integrações Tardias',
        name: 'late-integration',
        icon: 'Restore'
    },
    {
        active: true,
        endpoint: '/armazenar',
        label: 'Armazenar',
        name: 'storage',
        icon: 'Archive'
    },
    {
        active: true,
        endpoint: '/etiquetas-operacao',
        label: 'Etiquetas',
        name: 'backoffice-tags',
        icon: 'Label'
    },
    {
        active: true,
        endpoint: '/retira-na-loja',
        label: 'Retira na Loja',
        name: 'pick-up-at-store',
        icon: 'ShoppingBag'
    },
    {
        active: true,
        endpoint: '/romaneio',
        label: 'Romaneio',
        name: 'romanian',
        icon: 'Assignment'
    }
];

export const dashboardPages = [
    {
        active: true,
        endpoint: '/dashboard-estoque',
        label: 'Estoque',
        name: 'dashboard-stock',
        icon: 'Inventory'
    },
    {
        active: true,
        endpoint: '/dashboard-sla-eficacia',
        label: 'SLA/Eficácia',
        name: 'dashboard-sla-efficiency',
        icon: 'GridView'
    },
    {
        active: true,
        endpoint: '/dashboard-sla-por-contrato',
        label: 'SLA Por Contrato',
        name: 'dashboard-sla-contract',
        icon: 'Gavel'
    },
    {
        active: true,
        endpoint: '/dashboard-entregas-mes',
        label: 'Entregas (Mês)',
        name: 'dashboard-deliveries-month',
        icon: 'LocalShipping'
    },
    {
        active: true,
        endpoint: '/dashboard-sla-evolucao',
        label: 'SLA (evolução 15 dias)',
        name: 'dashboard-sla-evolution',
        icon: 'ShowChart'
    },
    {
        active: true,
        endpoint: '/dashboard-estoque-evolucao',
        label: 'Estoque (evolução 15 dias)',
        name: 'dashboard-stock-evolution',
        icon: 'StackedBarChart'
    }
];

export const panelPages = [
    {
        active: true,
        endpoint: '/monitoramento',
        label: 'Monitoramento',
        name: 'monitoring',
        icon: 'Traffic'
    },
    {
        active: true,
        endpoint: '/resumo-motoristas',
        label: 'Resumo dos motoristas',
        name: 'drivers-summary',
        icon: 'PersonSearch'
    },
    {
        active: true,
        endpoint: '/entregas-hora',
        label: 'Entregas/hora',
        name: 'deliveries-per-hour',
        icon: 'PendingActions'
    },
    {
      active: true,
      endpoint: '/entregas-bloqueadas',
      label: 'Entregas/Bloqueadas',
      name: 'blocked-deliveries',
      icon: 'PanTool'
  }
];

export const retailerPages = [
    {
        active: true,
        endpoint: '/importar-pedido',
        label: 'Importar Pedidos',
        name: 'retailer-csv',
        icon: 'ListAlt'
    },
    {
        active: true,
        endpoint: '/rastreamento',
        label: 'Rastreamento',
        name: 'retailer-tracking',
        icon: 'Timeline'
    },
    {
        active: true,
        endpoint: '/etiquetas',
        label: 'Etiquetas',
        name: 'retailer-tags',
        icon: 'Label'
    }
];

export const financialPages = [
    {
        active: true,
        endpoint: '/xml-cte',
        label: 'Download XML CTes',
        name: 'downloadXmlCtes',
        icon: 'ReceiptLong'
    }
];

export const menuModules = [
    {
        active: true,
        name: 'dashboard',
        label: 'Dashboard',
        icon: 'Dashboard',
        priority: 1,
        subitems: [],
        pages: dashboardPages
    },
    {
        active: true,
        name: 'operation',
        label: 'Operação',
        icon: 'Engineering',
        priority: 2,
        subitems: [
            {
                active: true,
                name: 'batch',
                label: 'Operações em Lote',
                icon: 'Queue',
                priority: 0,
                pages: operationBatchPages,
                subitems: []
            }
        ],
        pages: operationPages
    },
    {
        active: true,
        name: 'panel',
        label: 'Painel',
        icon: 'MonitorHeart',
        priority: 4,
        subitems: [],
        pages: panelPages
    },
    {
        active: true,
        name: 'retailer',
        label: 'Varejista',
        icon: 'SentimentVerySatisfied',
        priority: 5,
        subitems: [],
        pages: retailerPages
    },
    {
        active: true,
        name: 'financial',
        label: 'Financeiro',
        icon: 'Payments',
        priority: 6,
        subitems: [],
        pages: financialPages
    }
];

export const allPages = [
    ...operationPages,
    ...operationBatchPages,
    ...retailerPages,
    ...panelPages,
    ...dashboardPages,
    ...financialPages
];
