import * as material from '@mui/icons-material';
import { Icon } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const PageItem = ({ item }) => {
    const location = useLocation();

    const isSelected = useMemo(() => {
        return location.pathname === item.endpoint;
    }, [location]);

    return (
        <Link to={item.endpoint} style={{ textDecoration: 'none', color: 'initial' }}>
            <ListItemButton
                sx={{ pl: 4, background: 'white', filter: isSelected && 'brightness(0.7)' }}>
                <ListItemIcon>
                    <Icon as={material[item.icon]} />
                </ListItemIcon>
                <ListItemText primary={item.label} />
            </ListItemButton>
        </Link>
    );
};

export default PageItem;
