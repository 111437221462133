import { Typography } from '@mui/material';
import { useState } from 'react';

import { useAuth } from '../../../../hooks/auth';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { useEffect } from 'react';
import { Container } from './styles';

const Welcome = () => {
    const { user } = useAuth();
    const [file, setFile] = useState(null);

    useEffect(() => {
        const haveCustumer = user.customers.length > 0;

        const docURL = haveCustumer
            ? 'https://vf-store.nyc3.digitaloceanspaces.com/assets/customerWelcome.md'
            : 'https://vf-store.nyc3.digitaloceanspaces.com/assets/welcome.md';

        axios
            .get(docURL, {
                mode: 'no-cors'
            })
            .then((response) => setFile(response.data));
    }, []);

    return (
        <Container
            sx={{
                flex: 1,
                width: '100%',
                height: '100%',
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                justifyIcont: 'flex-start',
                alignItems: 'center',
                paddingTop: '16px'
            }}>
            <Typography variant="h6">Olá {user.name}, é muito bom te ver aqui novamente</Typography>
            <ReactMarkdown children={file} />,
        </Container>
    );
};

export default Welcome;
