import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import ListItemExpandable from '../../components/ListItemExpandable';
import { Container, List, Title } from './styles';

const LateIntegrations = () => {
    const { isLoading, data: orders } = useQuery(
        ['operations/tags'],
        async () => {
            const response = await api.get('/operations/tags');

            return response.data;
        },
        {
            placeholderData: () => {
                return [];
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    return (
        <Container>
            <Title>Pedidos com integração tardia</Title>

            {isLoading ? (
                <Loader />
            ) : orders.length > 0 ? (
                <List>
                    {orders.map((o) => (
                        <ListItemExpandable data={o} key={o.alphaCode} />
                    ))}
                </List>
            ) : (
                <Alert
                    severity="success"
                    sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                    Nenhuma atualização
                </Alert>
            )}
        </Container>
    );
};

export default LateIntegrations;
