import { Alert, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import {
    Checkbox,
    DriverContainer,
    DriverContainerHeader,
    FilterContainer,
    Label,
    Left,
    List,
    ListItem,
    Right
} from './styles';

const DriverPackagesList = ({ driver, onChange }) => {
    const [checked, setChecked] = useState([]);

    const { isLoading, data: packages } = useQuery([`drivers/${driver}/wallet`], async () => {
        const response = await api.get(`/drivers/${driver}/wallet`);

        return response.data;
    });

    const availablePackages = useMemo(() => {
        if (!packages) return [];

        const packagesList = packages.map((p) => {
            const { alphaCode, lastAssociationDatetime, statusLabel, statusId } = p;

            return {
                alphaCode,
                operationDate: new Date(lastAssociationDatetime)
                    .toLocaleString('pt-BR')
                    .substr(0, 16),
                statusLabel,
                statusId
            };
        });

        return packagesList;
    }, [packages]);

    function handleCheckPackages(id) {
        const exists = checked.includes(id);

        if (!exists) {
            const newChecked = [...checked, id];
            setChecked(newChecked);
            onChange(newChecked);
        } else {
            const newChecked = checked.filter((o) => o !== id);
            setChecked(newChecked);
            onChange(newChecked);
        }
    }

    function handleCheckAll() {
        const allTrue = checked.length === availablePackages.length;

        if (allTrue) {
            onChange([]);
            setChecked([]);
        } else {
            const newChecked = availablePackages.map((o) => o.alphaCode);
            setChecked(newChecked);
            onChange(newChecked);
        }
    }

    if (isLoading) {
        return (
            <Box sx={{ m: 4 }}>
                <Loader color="#000" />
            </Box>
        );
    }

    if (availablePackages.length === 0 && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return (
        <DriverContainer>
            <h2>Pedidos selecionados: {checked.length}</h2>
            <DriverContainerHeader>
                <FilterContainer>
                    <Checkbox
                        onClick={() => handleCheckAll()}
                        checked={availablePackages.length === checked.length}
                    />
                    <Label>Cod. Pedido</Label>
                </FilterContainer>
                <FilterContainer style={{ paddingRight: '20px' }}>
                    <Label>Status</Label>
                </FilterContainer>
            </DriverContainerHeader>
            <List>
                {availablePackages.map((o) => (
                    <ListItem key={o.alphaCode} onClick={() => handleCheckPackages(o.alphaCode)}>
                        <Left>
                            <Checkbox checked={checked.includes(o.alphaCode)} />
                            <Label>{o.alphaCode}</Label>
                        </Left>
                        <Right>
                            <Label>{o.statusLabel}</Label>
                        </Right>
                    </ListItem>
                ))}
            </List>
        </DriverContainer>
    );
};

export default DriverPackagesList;
