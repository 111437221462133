import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    drawer: false
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleDrawer: (state) => {
            state.drawer = !state.drawer;
        }
    }
});

export const { toggleDrawer } = menuSlice.actions;

export const getMenu = () => (state) => state.menu;

export default menuSlice.reducer;
