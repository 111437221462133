import { Switch } from 'react-router-dom';
import Route from '../../../routes/Route';
import { DownloadXmlCte } from '../pages/DownloadXmlCte'


const FinancialRoutes = () => {
  return (
    <Switch>
      <Route path="/xml-cte" component={DownloadXmlCte} isPrivate />
    </Switch>
  );
};

export default FinancialRoutes;
