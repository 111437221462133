import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../../routes/Route';

import CSVUpload from '../pages/CSVUpload';
import Tags from '../pages/Tags';
import Tracking from '../pages/Tracking';

const RetailerRoutes = () => {
    return (
        <Switch>
            <Route path="/importar-pedido" component={CSVUpload} isPrivate />
            <Route path="/etiquetas" component={Tags} isPrivate />
            <Route path="/rastreamento" component={Tracking} isPrivate />
        </Switch>
    );
};

export default RetailerRoutes;
