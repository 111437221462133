import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import BlockedDeliveriesRows from './BlockedDeliveriesRows';
import { StyledTableCell } from './styles';

const BlockedDeliveriesTable = ({ blockedDeliveries }) => {
    return (
        <TableContainer
            component={Paper}
            sx={{ height: '800px', maxWidth: '1020px', minWidth: '1020px' }}>
            <Table size="small" aria-label="a dense table">
                <TableHead sx={{ height: '45px', position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>Notificação Atual</StyledTableCell>
                        <StyledTableCell>Codigo do Pacote</StyledTableCell>
                        <StyledTableCell align="left">Status Atual</StyledTableCell>
                        <StyledTableCell align="left">Motorista</StyledTableCell>
                        <StyledTableCell align="left">Contato Motorista</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {blockedDeliveries.map((row) => (
                        <BlockedDeliveriesRows row={row} key={row.code} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BlockedDeliveriesTable;
