import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import BarChart from '../../components/BarChart';
import getColorsRandom from '../../utils/getColorsRandom';

const StockEvolution = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading, data: kpis } = useQuery(
        ['kpis'],
        async () => {
            const { data } = await api.get('/kpis');

            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Dashboards atualizados!',
                { variant: 'success',
                  action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const chartData = useMemo(() => {
        if (!kpis) return null;

        const labels = kpis.items.evolutionPackages15
            .reduce((acc, curr) => {
                if (!acc.includes(curr.date)) acc.push(curr.date);
                return acc;
            }, [])
            .sort((a, b) => {
                if (new Date(a) > new Date(b)) {
                    return 1;
                }
                if (new Date(b) > new Date(a)) {
                    return -1;
                }
                return 0;
            });

        const evolutionStock = kpis.items.evolutionPackages15.reduce((acc, curr) => {
            const existent = acc.find((c) => c.name === curr.contract);
            const { quantity, date } = curr;
            if (existent) {
                const index = acc.indexOf(existent);

                const currentDate = existent.stocks.find((d) => d.date === date);

                const stockItemIndex = existent.stocks.indexOf(currentDate);

                currentDate.quantity = quantity;

                existent.stocks.splice(stockItemIndex, 1, currentDate);

                acc.splice(index, 1, existent);
            } else {
                const stocks = labels.map((l) => {
                    return {
                        date: l,
                        quantity: l === date ? Number(quantity) : 0
                    };
                });

                const newStocks = {
                    name: curr.contract,
                    stocks
                };
                acc.push(newStocks);
            }
            return acc;
        }, []);

        const datasets = evolutionStock.map((c) => {
            const data = c.stocks.map((q) => q.quantity);
            return {
                label: c.name,
                data: data,
                backgroundColor: getColorsRandom([data])
            };
        });

        const data = {
            labels,
            datasets
        };
        return { data };
    }, [kpis]);

    if (!kpis && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return isLoading ? (
        <Loader type="ThreeDots" color="#000" />
    ) : !kpis && !isLoading ? (
        <Alert severity="success" sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
            Nenhuma atualização
        </Alert>
    ) : (
        <BarChart data={chartData.data} />
    );
};

export default StockEvolution;
