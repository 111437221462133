import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { useAuth } from '../../../../hooks/auth';
import { textToCodeArray } from '../../../../utils/formatting';
import terms from '../../../../utils/searchTerms.json';
import DockSelect from '../../components/DockSelect';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendBatchOrder, sendOrder, setBatchOrder, setOrder } from '../../utils/OperationActions';
import { Container, Icon, IconButton, LowerSection, Title } from './styles';
import { logError } from '../../../../utils/error';

const Storage = () => {
    const [origin, setOrigin] = useState(null);
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const [batchMode, setBatchMode] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const inputRef = useRef(null);

    const { user } = useAuth();

    const allowBatch = useMemo(() => {
        if (user.roles.includes('1')) {
            return true;
        }
        if (user.roles.includes('2')) {
            return true;
        }
        if (user.roles.includes('3')) {
            return true;
        }

        return false;
    }, [user]);

    async function handleBatchStore() {
        if (!inputRef.current) return;

        const codes = textToCodeArray(inputRef.current.value);

        inputRef.current.value = '';

        const existentOrders = orders.filter((o) => {
            if (codes.includes(o.number)) {
                return true;
            } else {
                return false;
            }
        });

        if (existentOrders.length > 0) {
            enqueueSnackbar(`"${existentOrders.length}" pedidos duplicados`, {
                variant: 'info',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });

            return;
        }

        const ordersList = setBatchOrder({
            dock: origin,
            orders: codes,
            term
        });

        if (ordersList) {
            const responseList = await sendBatchOrder({
                payload: ordersList,
                operation: 'storage'
            });

            const toasts = responseList.filter((r) => r.type === 'toast');

            toasts.forEach((response) => {
                enqueueSnackbar(response.data, {
                    variant: 'info',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            });

            const ordersResponse = responseList
                .filter((r) => r.type !== 'toast')
                .reduce((acc, curr) => {
                    acc.push(...curr.data);
                    return acc;
                }, []);

            const newOrders = [...ordersResponse, ...orders];

            setOrders(newOrders);
        }
    }

    async function handleStore(target) {
        if (!target.value) return;

        const orderCode = target.value;

        target.value = '';
        target.focus();

        if (orders.map((o) => o.number).includes(orderCode)) {
            enqueueSnackbar(`Pedido "${orderCode}" já foi registrado nesta tentantiva`, {
                variant: 'info',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            return;
        }

        const orderObject = setOrder({
            dock: origin,
            order: orderCode
        });

        if (term) {
            Object.assign(orderObject, { searchBy: term });
        }

        if (orderObject) {
            const response = await sendOrder({
                payload: orderObject,
                operation: 'storage'
            });
            if (response.type === 'toast') {
                enqueueSnackbar(response.data, {
                    variant: 'info',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } else {
                setOrders([...response.data, ...orders]);
            }
        }
    }

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Container>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Title>ARMAZENAR</Title>
                {!!allowBatch && (
                    <IconButton onClick={() => setBatchMode(!batchMode)}>
                        Operação em lote
                        <Icon className="material-icons" enable={batchMode}>
                            {batchMode ? 'toggle_on' : 'toggle_off'}
                        </Icon>
                    </IconButton>
                )}
                <DockSelect onChange={setOrigin} placeholder="Local" required />

                <FormControl variant="filled" style={{ width: '300px' }} required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {batchMode ? (
                    <>
                        <TextField
                            inputRef={inputRef}
                            disabled={!origin}
                            id="outlined-multiline-flexible"
                            label="Digite aqui para buscar..."
                            style={{ width: '300px' }}
                            multiline
                            rows={4}
                        />

                        <Button
                            type="button"
                            style={{ width: '300px' }}
                            variant="contained"
                            onClick={() => handleBatchStore()}>
                            Enviar
                        </Button>
                    </>
                ) : (
                    <TextField
                        disabled={!origin}
                        label="Digite aqui para buscar..."
                        style={{ width: '300px' }}
                        onKeyDown={(event) => event.key === 'Enter' && handleStore(event.target)}
                    />
                )}
            </Stack>

            <LowerSection>
                <OperationPackagesFeedback
                    orders={orders}
                    handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                />
            </LowerSection>
        </Container>
    );
};

export default Storage;
