import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding: 2rem;
`;

export const Table = styled.table`
    width: 720px;
    display: flex;
    font-weight: 700;
    font-size: 2rem;
    flex-direction: column;
    border: 1px solid #e1e1e0;
    border-radius: 0.5rem;
    border-spacing: 0;
    justify-content: space-between;
    border-spacing: 0.5rem;

    text-transform: uppercase;

    tbody {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    th {
        text-align: start;
        width: 100%;
        padding: 0.5rem;
        color: #ccc;
    }

    td {
        text-align: start;
        width: 100%;
    }

    tfoot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.5rem;
        color: #ccc;
    }
`;

export const theme = {
    inProgress: {
        background: '#32cd32',
        color: 'green',
        border: '1px solid #32cd32'
    },
    Collected: {
        background: darken(0.05, '#32cd32'),
        color: 'green',
        border: '1px solid #32cd01'
    },
    Pending: {
        background: 'orange',
        color: '#ff0',
        border: '1px solid orange'
    },
    AwaitingCollection: {
        background: darken(0.05, 'orange'),
        color: '#ff0',
        border: '1px solid orange'
    },
    Tranfer: {
        background: 'red',
        color: '#fff',
        border: '1px solid red'
    },
    Stored: {
        background: darken(0.05, 'red'),
        color: '#fff',
        border: '1px solid red'
    },
    AwaitingReturn: {
        background: '#9370db',
        color: '#fff',
        border: '1px solid #9370db'
    }
};
