import CryptoJS from 'crypto-js';
const secret = process.env.REACT_APP_CRYPTO_KEY || null;

export function haveSecrect() {
    if (!secret) throw new Error('Encryption Secret environment variable not defined');
}

export function encrypt(value) {
    // if (process.env.NODE_ENV !== 'production') {
    //     return value;
    // }

    haveSecrect();
    try {
        const ensureStringfy = value.toString();
        const ciphertext = CryptoJS.AES.encrypt(ensureStringfy, secret).toString();
        return ciphertext;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export function decrypt(encrypted) {
    // if (process.env.NODE_ENV !== 'production') {
    //     return encrypted;
    // }
    haveSecrect();
    try {
        const bytes = CryptoJS.AES.decrypt(encrypted, secret);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (error) {
        console.log(error);
        return null;
    }
}
