import { Logout, Menu as MenuIcon, MoreVert, SyncAlt } from '@mui/icons-material';
import { Alert, Avatar, Divider, Stack, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pkg from '../../../../package.json';
import logoWhite from '../../../assets/img/logo-color.png';
import { useAuth } from '../../../hooks/auth';
import { getMenu, toggleDrawer } from '../../../store/menuSlice';

const drawerWidth = 304;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Appbar = () => {
    const { drawer: collapsed } = useSelector(getMenu());
    const { signOut, user, updateUser } = useAuth();

    const username = String(user.name).split(' ').slice(0, 2).join(' ');

    const userInitials = useMemo(() => {
        return String(username)
            .split(' ')
            .map((i) => i[0])
            .slice(0, 2)
            .join('');
    }, [user]);

    const envName = String(process.env.NODE_ENV).toUpperCase();

    const dispatch = useDispatch();

    const showChangeCustomer = useMemo(() => {
        if (!user) return false;

        if (!!user.currentCustomer && user.customers.length > 1) return true;

        return false;
    }, [user]);

    const handleChangeCustomer = React.useCallback(() => {
        const newUser = user;

        newUser.currentCustomer = null;

        updateUser(newUser);
    }, [updateUser]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const profileMenuItems = (
        <Box>
            <MenuItem>
                <Typography width="100%" textAlign="center" variant="p">
                    Olá {username}
                </Typography>
            </MenuItem>

            <Divider />

            {showChangeCustomer && (
                <>
                    <MenuItem onClick={handleChangeCustomer}>
                        <SyncAlt sx={{ mr: 2 }} />
                        <Typography variant="p" fontWeight="bold" fontSize="1rem">
                            Trocar loja
                        </Typography>
                    </MenuItem>
                    <Divider />
                </>
            )}

            <MenuItem onClick={signOut}>
                <Logout sx={{ mr: 2 }} />
                <Typography variant="p" fontWeight="bold" fontSize="1.1rem">
                    Sair
                </Typography>
            </MenuItem>

            <Divider />

            {process.env.NODE_ENV === 'production' ? (
                <MenuItem
                    onClick={() => window.location.assign('https://stable.app.vaifacilbr.com/')}>
                    <Stack spacing={0}>
                        <Typography variant="p" fontWeight="thin" fontSize="0.9rem">
                            App Vai Fácil v{pkg.version}
                        </Typography>

                        <Typography variant="p" fontWeight="thin" fontSize="0.9rem">
                            Ir para Versão anterior
                        </Typography>
                    </Stack>
                </MenuItem>
            ) : (
                <MenuItem
                    sx={{
                        background: 'yellow'
                    }}>
                    <Typography width="100%" textAlign="center" variant="p" fontWeight="bold">
                        {envName}
                    </Typography>
                </MenuItem>
            )}
        </Box>
    );

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            {profileMenuItems}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}>
            {profileMenuItems}
        </Menu>
    );

    return (
        <>
            <AppBar position="fixed" open={collapsed}>
                 <Alert
                   sx={{ borderRadius: '0', alignItems: 'center', justifyContent: 'center' , display: 'flex'}} severity="error" variant='filled'>
                    Este sistema se tornará obsoleto em breve e as novas funcionalidades estarão disponíveis somente no novo sistema. {" "}
                    <a href='https://operacao.vaifacil.com/' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <strong>Clique aqui e conheça.</strong>
                     </a>  {" "}Comece a usar a nova plataforma !
                  </Alert>
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => dispatch(toggleDrawer())}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(collapsed && { display: 'none' })
                        }}>
                        <MenuIcon />
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <img src={logoWhite} alt="Vai Fácil" width="150px" />
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit">
                            <Avatar sx={{ color: '#2143a8', fontWeight: 'bold', bgcolor: '#fff' }}>
                                {userInitials}
                            </Avatar>
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit">
                            <MoreVert />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </>
    );
};

export default Appbar;
