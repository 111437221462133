import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    * {
    box-sizing: border-box;
    font-family: 'Montserrat';
    color: ${(props) => props.theme.light}

    svg {
        color: ${(props) => props.theme.secondary}
    }
}

    *:focus {
    outline: none;
    }

    *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    }
    *::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 3px;
    }
    *::-webkit-scrollbar-thumb:hover {
    background: #969696;
    }
    *::-webkit-scrollbar-track {
    background: #d4d4d4;
    border-radius: 4px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
    }

    body,
    html {
    width: 100vw;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: ${(props) => props.theme.secondary};
    }

    h1 {
    margin: 0px;
    padding: 0px;
    color: ${(props) => props.theme.dark}
    }

.react-modal-orverlay {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-modal-content{
    width: fit-content;
    height: fit-content;
    max-height: 90vh;
    max-width: 95vw;
    padding: 3rem;
    position: relative;
    border-radius: 0.25rem;
    background: #fafafa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 1px 10px rgba(0, 0, 0, 0.2);

   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   text-align: center;

   overflow: 'hidden';
}

.print {
  display: none;
}

@media print {
    .print {
      display: flex;
      align-items: center;
      justify-content: center;
    }
}


`;

export default GlobalStyle;
