import handleCloseAction from '../../../../components/HandleCloseAction';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { json2excel } from 'js2excel';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Button } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import terms from '../../../../utils/searchTerms.json';
import { isValid } from '../../../../utils/validations';
import DeliveryModalities from '../../components/DeliveryModalities';
import DockSelect from '../../components/DockSelect';
import DriverSupplier from '../../components/DriverSupplier';
import { DriverSupplierFilter } from '../../components/DriverSupplierFilter';
import { OperationPackagesFeedback } from '../../components/OperationPackagesFeedback';
import { sendOrder, setOrder } from '../../utils/OperationActions';
import { Container, LowerSection, Title } from './styles';
import { logError } from '../../../../utils/error';

const Dispatch = () => {
    const [driver, setDriver] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [term, setTerm] = useState(null);
    const [orders, setOrders] = useState([]);
    const [log, setLog] = useState([]);
    const [deliveryModality, setDeliveryModality] = useState('154');

    const { data: drivers } = useQuery(
        ['drivers'],
        async () => {
            const response = await api.get('/drivers');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const { data: docks } = useQuery(
        ['docks'],
        async () => {
            const response = await api.get('/docks');

            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const { user } = useAuth();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function handleDispatch(target) {
        try {
            if (!target.value) return;

            const orderCode = target.value;

            target.value = '';
            target.focus();

            if (orders.map((o) => o.number).includes(orderCode)) {
                enqueueSnackbar(`Pedido "${orderCode}" já foi registrado nesta tentantiva`, {
                    variant: 'info',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
                target.value = '';
                return;
            }

            const orderObject = setOrder({
                deliveryModality,
                dock: origin,
                order: orderCode
            });

            if (term) {
                Object.assign(orderObject, { searchBy: term });
            }

            if (!!driver) {
                Object.assign(orderObject, driver);
            } else {
                enqueueSnackbar('Motorista não selecionado', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
                return;
            }

            if (!!origin && !!driver) {
                const listObj = {
                    origin
                };

                Object.assign(listObj, driver);

                const { data } = await api.post('/operations/list', listObj);

                if (data && orderObject) {
                    Object.assign(orderObject, { list: data.id });
                }
            }

            if (orderObject) {
                const response = await sendOrder({
                    payload: orderObject,
                    operation: 'dispatch'
                });

                const currentDriver = drivers.find(
                    (d) => String(d.id) === String(driver.driver ? driver.driver : driver)
                );

                const currentDock = docks.find((d) => String(d.id) === String(origin));

                const newLog = {
                    driver: currentDriver.name,
                    dock: currentDock.dock,
                    order: orderCode,
                    createdAt: new Date().toISOString()
                };
                if (response.type === 'toast') {
                    enqueueSnackbar(response.data, {
                      action: (id) => handleCloseAction(id, closeSnackbar)
                    });

                    setLog([newLog, ...log]);
                } else {
                    setOrders([...response.data, ...orders]);
                    setLog([newLog, ...log]);
                }
            }
        } catch (error) {
            logError(error);
        }
    }

    function handleExport() {
        try {
            const data = log.map((l) => {
                l.pedido = l.order;
                l.cd = l.dock;
                l.motorista = l.driver;
                l.data = new Date(l.createdAt).toLocaleString('pt-BR');

                delete l.order;
                delete l.dock;
                delete l.driver;
                delete l.createdAt;

                return l;
            });
            json2excel({
                data,
                name: `despachados-${Date.now()}`,
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);
            enqueueSnackbar('Falha ao gerar arquivo, tente novamente', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
        }
    }

    function handleReset() {
        setOrders([]);
        setLog([]);
    }

    const IsSupplier = useMemo(() => (user.id === '1373' ? true : false), [user.id]);

    async function handleUpdatedPackageOnTrack(code) {
        try {
            const newOrders = orders.filter((order) => order.number !== code);
            setOrders(newOrders);

            await api.get(`/packages/recache/${code}`);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Container>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Title>DESPACHO</Title>

                {IsSupplier ? (
                    <DriverSupplierFilter onChange={(e) => setDriver(isValid(e) ? e : null)} />
                ) : (
                    <DriverSupplier onChange={(e) => setDriver(isValid(e) ? e : null)} />
                )}

                <DeliveryModalities
                    style={{ width: '300px' }}
                    onChange={(v) => {
                        setDeliveryModality(v);
                    }}
                />
                <DockSelect
                    onChange={setOrigin}
                    placeholder="Origem"
                    disabled={!isValid(driver)}
                    startSelected
                    required
                />

                <FormControl
                    variant="filled"
                    sx={{ m: 1, minWidth: 300 }}
                    disabled={!origin || !driver}
                    required>
                    <InputLabel id="search-term">Termo de busca</InputLabel>
                    <Select
                        defaultValue={Object.keys(terms)[0]}
                        labelId="search-term"
                        id="search-term"
                        label="Termo de busca"
                        onChange={(e) => setTerm(terms[e.target.value] || null)}>
                        {Object.keys(terms).map((c) => (
                            <MenuItem value={String(c)} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    disabled={!origin || !isValid(driver)}
                    label="Digite aqui para buscar..."
                    style={{ width: '300px' }}
                    onKeyDown={(event) => event.key === 'Enter' && handleDispatch(event.target)}
                />

                <Stack direction="row" justifyContent="space-evenly" width="100%">
                    <Button
                        style={{ margin: '8px' }}
                        onClick={() => handleExport()}
                        disabled={log.length === 0}>
                        Exportar
                    </Button>

                    <Button style={{ margin: '8px' }} onClick={() => handleReset()}>
                        Limpar
                    </Button>
                </Stack>
            </Stack>

            <LowerSection>
                <OperationPackagesFeedback
                    orders={orders}
                    handleUpdatedPackageOnTrack={handleUpdatedPackageOnTrack}
                />
            </LowerSection>
        </Container>
    );
};

export default Dispatch;
