import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { isValid } from '../../../../utils/validations';

const DriverSupplier = ({
    disabled = false,
    onChange = () => {},
    style = { width: '300px' },
    noVehicle = false
}) => {
    const [supplier, setSupplier] = useState(null);
    const [response, setResponse] = useState(null);

    const { isLoading: isDriverLoading, data: driversData } = useQuery(
        [`operations/drivers${supplier ? `/${supplier.id}` : ''}`],
        async () => {
            const { data } = await api.get(
                `/operations/drivers${supplier ? `/${supplier.id}` : ''}`
            );

            return data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const drivers = !driversData
        ? []
        : driversData.map((d) => ({
              label: `${d.name}\n - CPF:${d.cpf}`,
              id: Number(d.id)
          }));

    const { isLoading: isSuppliersLoading, data: suppliersListData } = useQuery(
        ['operations/suppliers'],
        async () => {
            const { data } = await api.get('operations/suppliers');

            return data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const suppliers =
        !suppliersListData || (suppliersListData && suppliersListData.length === 0)
            ? null
            : suppliersListData;

    const { isLoading: isModalsLoading, data: modalsData } = useQuery(
        [`operations/modals${supplier ? `/${supplier.id}` : ''}`],
        async () => {
            if (!supplier) return [];

            const { data } = await api.get(
                `/operations/modals${supplier ? `/${supplier.id}` : ''}`
            );

            return data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const modals = !modalsData ? [] : modalsData;

    const { isLoading: isVehiclesLoading, data: vehiclesData } = useQuery(
        [`operations/vehicles${supplier ? `/${supplier.id}` : ''}`],
        async () => {
            if (!supplier) return [];

            const { data } = await api.get(
                `/operations/vehicles${supplier ? `/${supplier.id}` : ''}`
            );

            return data;
        },
        {
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const vehicles = !vehiclesData ? [] : vehiclesData;

    const isLoading = isDriverLoading || isSuppliersLoading || isModalsLoading || isVehiclesLoading;

    function handleReset() {
        setSupplier(null);
        setResponse(null);
    }

    function handleChangeSupplier(id) {
        onChange(null);
        if (!isValid(id)) {
            handleReset();
            return;
        }

        const supplierObj = suppliers.find((s) => s.id === String(id));

        setSupplier(supplierObj);
        setResponse({ supplier: id });
    }

    function checkSubmissionRequirements(responseObj) {
        const keys = Object.keys(responseObj);

        const haveSupplier = keys.includes('supplier');
        const haveModal = keys.includes('modal');
        const haveVehicle = keys.includes('vehicle');
        const haveDriver = keys.includes('driver');

        const needSupplier = !!supplier;
        const needDriver = !!drivers && drivers.length > 0;
        const needModal = !!modals && modals.length > 0;
        const needVehicle = !noVehicle && !!vehicles && vehicles.length > 0;

        const supplierOk = !needSupplier || (needSupplier && haveSupplier);
        const modalOk = !needModal || (needModal && haveModal);
        const vehicleOk = !needVehicle || (needVehicle && haveVehicle);
        const driverOk = !needDriver || (needDriver && haveDriver);

        return modalOk && vehicleOk && driverOk && supplierOk;
    }

    function handleUpdateForm(field) {
        if (!field) return;

        const newResponse = { ...response, ...field };

        const [value] = Object.values(field);

        if (!isValid(value)) {
            const [key] = Object.keys(field);

            delete newResponse[key];
        }

        setResponse(newResponse);

        const meetRequirements = checkSubmissionRequirements(newResponse);

        if (meetRequirements) {
            onChange(newResponse);
        } else {
            onChange(null);
        }
    }

    return isLoading ? (
        <Loader />
    ) : (
        <Stack direction="column" spacing={2} justifyContent="start">
            <FormControl variant="outlined" style={style}>
                <InputLabel id="driver">Forma de entrega</InputLabel>
                <Select
                    disabled={disabled}
                    defaultValue=""
                    labelId="driver"
                    id="driver"
                    label="Forma de entrega"
                    onChange={(e) => handleChangeSupplier(e.target.value)}>
                    <MenuItem value={0} hidden>
                        Motorista Parceiro
                    </MenuItem>
                    {suppliers.map((d) => (
                        <MenuItem value={Number(d.id)} key={Number(d.id)}>
                            {d.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl variant="filled" style={style}>
                <Autocomplete
                    id="driver"
                    options={drivers}
                    blurOnSelect
                    renderInput={(params) => <TextField {...params} label="Motorista" />}
                    onChange={(_, value) => handleUpdateForm({ driver: value ? value.id : null })}
                    noOptionsText="Nenhuma correspondência"
                    disabled={disabled}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            </FormControl>
            {modals.length > 0 && (
                <FormControl variant="filled" style={style}>
                    <InputLabel id="driver">Modalidade</InputLabel>
                    <Select
                        disabled={disabled}
                        defaultValue=""
                        labelId="driver"
                        id="driver"
                        label="Modalidade"
                        onChange={(e) => handleUpdateForm({ modal: e.target.value })}>
                        {modals.map((m) => (
                            <MenuItem value={Number(m.id)} key={Number(m.id)}>
                                {m.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {!noVehicle && vehicles.length > 0 && (
                <FormControl variant="filled" style={style}>
                    <InputLabel id="driver">Veículo</InputLabel>
                    <Select
                        disabled={disabled}
                        defaultValue=""
                        labelId="driver"
                        id="driver"
                        label="Veículo"
                        onChange={(e) => handleUpdateForm({ vehicle: e.target.value })}>
                        {vehicles.map((v) => (
                            <MenuItem value={Number(v.id)} key={Number(v.id)}>
                                {v.licensePlate}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Stack>
    );
};

export default DriverSupplier;
