import { Alert, AlertTitle, Box, LinearProgress, Tooltip } from '@mui/material';
import { addDays, intervalToDuration } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { v4 } from 'uuid';
import { Button, Icon } from '../../../../components';
import Modal from '../../../../components/Modal';
import reverseStatusById from '../../../../data/reverseStatusById.json';
import variants from '../../../../data/searchCardVariants.json';
import { useAuth } from '../../../../hooks/auth';
import CancelOrder from '../SearchActions/CancelOrder';
import EditAddress from '../SearchActions/EditAddress';
import BackofficeInteraction from '../SearchActions/Interaction';
import BackofficeOccurrence from '../SearchActions/Occurrence';
import PrintTag from '../SearchActions/PrintTag';
import BackofficeStorePackage from '../SearchActions/StorePackage';
import Details from './Details';
import {
    ButtonsContainer,
    CollapseIcon,
    Container,
    ExpandIcon,
    Item,
    Label,
    Status,
    StatusText,
    ToggleButton,
    Value,
    WarningIcon
} from './styles';

const SearchPackageCard = ({ data, onUpdate, style }) => {
    const [expanded, setExpanded] = useState(false);
    const [interaction, setInteraction] = useState(false);
    const [occurrence, setOccurrence] = useState(false);
    const [storePackage, setStorePackage] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [showSignature, setShowSignature] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);

    const { getAuthorization, user } = useAuth();

    const searchTagRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => searchTagRef.current
    });

    const order = useMemo(() => {
        const {
            deliverySla,
            events,
            alphaCode,
            statusLabel,
            status,
            recipient,
            order,
            driver,
            invoices,
            statusId,
            isReverse
        } = data;

        const sla = !!deliverySla
            ? new Date(deliverySla).toLocaleString(['pt-BR'])
            : 'Não informado';

        const clearEvents = events.filter(
            (e) => e.description !== 'Recriação do cache para o pacote'
        );
        const history = clearEvents.map((e) => {
            if (e.fromCache) {
                const historyItem = {
                    fromCache: true,
                    date: new Date().toLocaleString('pt-BR'),
                    user: 'Processando',
                    action: e.statusLabel,
                    id: v4(),
                    statusId: v4()
                };
                return historyItem;
            }

            const historyItem = {
                date: new Date(e.createdAt).toLocaleString(['pt-BR']),
                user: e.createdBy,
                action: e.statusLabel,
                id: e.eventId,
                statusId: e.statusId
            };

            if (e.description) {
                Object.assign(historyItem, {
                    description: e.description
                });
            }
            if (e.dock) {
                Object.assign(historyItem, {
                    dock: e.dock
                });
            }
            if (e.occurrence) {
                Object.assign(historyItem, {
                    occurrence: e.occurrence
                });
            }
            if (e.driver) {
                Object.assign(historyItem, {
                    driver: e.driver
                });
            }

            return historyItem;
        });

        const invoicesObject = invoices.map((i) => {
            const { number, key, series, value } = i;

            const invoice = {
                number,
                key,
                value: new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }).format(value)
            };

            if (series) {
                Object.assign(invoice, { series });
            }

            return invoice;
        });

        const address = [];

        if (recipient.address) {
            function addIfExists(item) {
                if (!!item) {
                    address.push(item);
                }
            }

            const { location, number } = recipient.address;

            const newZipcode = [...location.zipcode];
            newZipcode.splice(5, 0, '-');

            addIfExists(location.address);
            addIfExists(`nº${number}`);
            addIfExists(location.neighborhood);
            addIfExists(`${newZipcode.join('')}`);
        }

        const variant = variants[statusId];

        const validDocument = (doc) => {
            const text = String(doc).trim();

            if (text.length > 0) return text;

            return 'Não informado';
        };

        const receiver = data.receiver && {
            ...data.receiver,
            document: !!data.receiver && validDocument(data.receiver.document)
        };

        const reverseStatus = reverseStatusById[statusId] || statusLabel;

        const statusText = isReverse ? reverseStatus : statusLabel;

        return {
            sla,
            events,
            code: alphaCode,
            status: statusText,
            statusId: status,
            contract: order.integration.contract,
            history,
            driver,
            variant,
            recipient: {
                address: address.join(' - '),
                street: recipient.address && recipient.address.location.address,
                number: recipient.address && recipient.address.number,
                neighborhood: recipient.address && recipient.address.location.neighborhood,
                zipcode: recipient.address && recipient.address.location.zipcode,
                complement: recipient.address && recipient.address.complement,
                email: recipient.email,
                phones: recipient.phones,
                name: recipient.name.toUpperCase() || 'Não informado'
            },
            receiver,
            invoices: invoicesObject
        };
    }, [data]);

    const isBackoffice = useMemo(() => {
        const { roles } = user;

        const validRole = roles.includes('1') || roles.includes('2') || roles.includes('3');

        return validRole;
    }, [user]);

    const allowOccurrence = useMemo(() => {
        if (!isBackoffice) return false;
        return getAuthorization('occurrence-on-search');
    }, [getAuthorization, isBackoffice]);

    const allowInteraction = useMemo(() => {
        if (!isBackoffice) return false;
        return getAuthorization('interaction-on-search');
    }, [getAuthorization, isBackoffice]);

    const allowCancel = useMemo(() => {
        if (!isBackoffice) return false;
        return getAuthorization('cancel-on-search');
    }, [getAuthorization, isBackoffice]);

    const allowStore = useMemo(() => {
        if (!isBackoffice) return false;
        const allowedStatus = ['1', '2', '4', '61', '66'];

        const validStatus = allowedStatus.includes(order.statusId);
        const haveAuthorization = getAuthorization('store-on-search');

        return validStatus && haveAuthorization;
    }, [getAuthorization, isBackoffice, order.statusId]);

    function handleAction() {
        setInteraction(false);
        setStorePackage(false);
        setOccurrence(false);
        setEditAddress(false);
        onUpdate();
    }

    const checkDeliveryAvailability = useMemo(() => {
        const occurrencesBlockDeliveries = order.events.filter((e) => e.occurrenceId === '243');

        const finalizingEvents = order.events.filter((e) =>
            ['9', '133', '6', '5'].includes(e.statusId)
        );

        if (occurrencesBlockDeliveries.length > 0 && finalizingEvents.length === 0) {
            const deliveryTimeLimit = addDays(new Date(occurrencesBlockDeliveries[0].createdAt), 2);

            deliveryTimeLimit.setHours(deliveryTimeLimit.getHours() - 3);
            const adjustedCreatedAt = new Date(occurrencesBlockDeliveries[0].createdAt);
            adjustedCreatedAt.setHours(adjustedCreatedAt.getHours() - 3);

            const interval = intervalToDuration({ start: new Date(), end: deliveryTimeLimit });

            const days = `${interval.days}  dia e`;

            return {
                result: true,
                remainingDuration: `${interval.days > 0 && days} ${interval.hours}:${
                    interval.minutes
                }h`
            };
        }

        return { result: false, remainingDuration: '00:00h' };
    }, [order.events]);

    const haveOccurrenceToday = useMemo(() => {
        const occurrences = order.events.filter((e) => e.status === 'Occurrence');

        if (occurrences.length > 0) {
            occurrences.sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1;
                }
                if (b.createdAt > a.createdAt) {
                    return 1;
                }

                return 0;
            });

            const { createdAt } = occurrences[0];

            const today = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            ).toLocaleDateString();
            const occurrenceDate = new Date(
                new Date(createdAt).getFullYear(),
                new Date(createdAt).getMonth(),
                new Date(createdAt).getDate(),
                new Date(createdAt).getHours() + 3
            ).toLocaleDateString();

            if (occurrenceDate === today) {
                return true;
            }
        }

        return false;
    }, [order.events]);

    const shouldShowSignature = useMemo(() => {
        if (!isBackoffice) return false;

        if (!order.receiver) return false;

        return !!order.receiver.signature;
    }, [isBackoffice, order.receiver]);

    const fromCacheEvent = useMemo(() => {
        const event = order.events.find((e) => !!e.fromCache);

        if (!event) return false;

        return event;
    }, [order]);

    return (
        <Container style={style}>
            {interaction && (
                <Modal isOpen={interaction} onRequestClose={() => setInteraction(false)}>
                    <BackofficeInteraction order={data} onInteract={() => handleAction()} />
                </Modal>
            )}
            {storePackage && (
                <Modal isOpen={storePackage} onRequestClose={() => setStorePackage(false)}>
                    <BackofficeStorePackage order={data} onInteract={() => handleAction()} />
                </Modal>
            )}
            {occurrence && (
                <Modal isOpen={occurrence} onRequestClose={() => setOccurrence(false)}>
                    <BackofficeOccurrence order={data} onOccurrence={() => handleAction()} />
                </Modal>
            )}
            {editAddress && (
                <Modal isOpen={editAddress} onRequestClose={() => setEditAddress(false)}>
                    <EditAddress order={data} onSave={() => handleAction()} />
                </Modal>
            )}
            {showSignature && (
                <Modal isOpen={showSignature} onRequestClose={() => setShowSignature(false)}>
                    <h1>Assinatura do recebedor {order.receiver.name}</h1>
                    <img
                        style={{ width: '100%' }}
                        src={order.receiver.signature}
                        alt="assinatura do recebedor"
                    />
                </Modal>
            )}
            {cancelOrder && (
                <Modal isOpen={cancelOrder} onRequestClose={() => setCancelOrder(false)}>
                    <CancelOrder order={data} onInteract={() => handleAction()} />
                </Modal>
            )}
            {checkDeliveryAvailability.result && (
                <Item>
                    <Alert
                        severity="warning"
                        sx={{ width: '100%', marginBottom: '0.25rem', color: 'black' }}>
                        <AlertTitle>Notificação de Barrar Entrega</AlertTitle>
                        Tempo limite para entrega —{' '}
                        <strong>{checkDeliveryAvailability.remainingDuration}</strong>
                    </Alert>
                </Item>
            )}
            <Status style={{ justifyContent: fromCacheEvent ? 'space-between' : 'flex-end' }}>
                {haveOccurrenceToday && (
                    <WarningIcon className="material-icons">warning</WarningIcon>
                )}
                <StatusText variant={order.variant}>{order.status}</StatusText>
                {fromCacheEvent && (
                    <>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>

                        <Tooltip title="Este evento está em processamento mas você pode seguir a operação normalmente">
                            <StatusText variant="fromCache">
                                {fromCacheEvent.statusLabel}
                            </StatusText>
                        </Tooltip>
                    </>
                )}
            </Status>
            <Item>
                <Label>Cod. Pacote</Label>
                <Value>{order.code}</Value>
            </Item>
            <Item>
                <Label>Modalidade</Label>
                <Value>{order.contract}</Value>
            </Item>

            <Item>
                <Label>SLA</Label>
                <Value>{order.sla} </Value>
            </Item>

            <Item>
                <Label>Destinatário</Label>
                <Value>{order.recipient.name}</Value>
            </Item>
            <Item>
                <Label>Endereço do Destinatário</Label>
                <Value>{order.recipient.address}</Value>
            </Item>
            {order.recipient.complement && (
                <Item>
                    <Label>Complemento</Label>
                    <Value>{order.recipient.complement}</Value>
                </Item>
            )}
            <Item>
                <Label>Telefone</Label>
                {order.recipient.phones.length > 0 &&
                    order.recipient.phones.map((p) => <Value key={p.id}>{p.number}</Value>)}
            </Item>

            {order.receiver && (
                <>
                    <Item>
                        <Label
                            onClick={() => shouldShowSignature && setShowSignature(true)}
                            style={{ cursor: shouldShowSignature && 'pointer' }}>
                            Recebedor
                            {!!order.receiver.signature && (
                                <Icon className="material-icons">assignment_turned_in</Icon>
                            )}
                        </Label>
                        <Value>{order.receiver.name}</Value>
                    </Item>
                    <Item>
                        <Label>Documento do Recebedor</Label>
                        <Value>{order.receiver.document}</Value>
                    </Item>
                </>
            )}

            <Item>
                <Label>Motorista</Label>
                <Value>{order.driver || 'Não informado'}</Value>
            </Item>

            <ButtonsContainer>
                {allowInteraction && (
                    <Button onClick={() => setInteraction(true)} style={{ width: 'fit-content' }}>
                        <span className="material-icons">touch_app</span>
                    </Button>
                )}

                {allowStore && (
                    <Button
                        variant="warning"
                        onClick={() => setStorePackage(true)}
                        style={{ width: 'fit-content' }}>
                        <span className="material-icons">archive</span>
                    </Button>
                )}

                {allowOccurrence && (
                    <Button
                        variant="danger"
                        onClick={() => setOccurrence(true)}
                        style={{ width: 'fit-content' }}>
                        <span className="material-icons">feedback</span>
                    </Button>
                )}

                {isBackoffice && (
                    <Button onClick={() => setEditAddress(true)} style={{ width: 'fit-content' }}>
                        <span className="material-icons">edit_location_alt</span>
                    </Button>
                )}
                {isBackoffice && (
                    <>
                        <Button style={{ width: 'fit-content' }} onClick={() => handlePrint()}>
                            <span className="material-icons">print</span>
                        </Button>

                        <PrintTag data={data} printRef={searchTagRef} />
                    </>
                )}
                {allowCancel && (
                    <Button
                        variant="danger"
                        onClick={() => setCancelOrder(true)}
                        style={{ width: 'fit-content' }}>
                        <span className="material-icons">block</span>
                    </Button>
                )}
            </ButtonsContainer>
            {expanded && <Details isBackoffice={isBackoffice} order={order} onUpdate={onUpdate} />}
            <ToggleButton onClick={() => setExpanded(!expanded)}>
                {!expanded ? <ExpandIcon /> : <CollapseIcon />}
            </ToggleButton>
        </Container>
    );
};

export default SearchPackageCard;
