import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './auth';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../store';
import themes from '../utils/themes.json';
import { queryClient } from '../services/queryClient';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import '../services/elastic-apm';

const persistor = persistStore(store);

const AppProvider = ({ children }) => {
    return (
        <SnackbarProvider
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
                <ThemeProvider theme={themes['default']}>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools initialIsOpen={false} />
                        <ReduxProvider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <AuthProvider>{children}</AuthProvider>
                            </PersistGate>
                        </ReduxProvider>
                    </QueryClientProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </SnackbarProvider>
    );
};

export default AppProvider;
