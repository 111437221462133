import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const Title = styled.div`
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
`;

export const List = styled.div`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e0e0e0;
    margin-top: 16px;
`;
