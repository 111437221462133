import styled from 'styled-components';
import { darken } from 'polished';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

export const OrderListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: ${(props) =>
        !!props.open ? darken(0.2, props.theme.secondary) : props.theme.secondary};
    color: ${(props) => props.theme.light};
`;

export const ToggleButton = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export const Icon = styled.span`
    font-size: 30px;
    margin-left: 8px;
`;

export const OrderListItemBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderListItemNumber = styled.div`
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: ${(props) => props.theme.light};
    margin-right: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const OrderDate = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-align: center;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ToggleCardButton = styled.button`
    border: none;
    background: transparent;
    margin-left: 0px;
`;

export const DetailOpenIcon = styled(KeyboardArrowDown)`
    color: ${(props) => props.theme.light};
    font-size: 25px;
`;

export const DetailCloseIcon = styled(KeyboardArrowUp)`
    color: ${(props) => props.theme.light};
    font-size: 25px;
`;

export const OrderListItemDetailsBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 16px;
`;

export const DetailsItem = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 0px;
    margin: 0px 8px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const DetailsItemLabel = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.25px;

    color: ${(props) => props.theme.light};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const DetailsItemContent = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    color: ${(props) => props.theme.light};
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
