import { Add } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, styled, TextField, Typography } from "@mui/material"
import { useState } from "react";
import DockSelector from "../../components/DockSelect"
import DriverSupplier from "../../components/DriverSupplier"
import { PrintButton } from "./PrintButton";
import useMediaQuery from '@mui/material/useMediaQuery';


const HeaderElements = ({ totalRows, searchPackage, clearRomanian, packageList }) => {
  const [driverData, setDriverData] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [input, setInput] = useState('');
  const matches = useMediaQuery('(min-width:1441px)');

  return (
    <Box
      display='flex'
      padding='1rem'
      flexDirection={matches ? 'row' : 'column'}
      justifyContent='flex-start'
      alignItems='center'
      gap={2}
    >
      <DriverSupplier onChange={(e) => setDriverData(e)} noLabor externalTransfer />
      <Box display='flex' flexDirection='column'>
        <DockSelector onChange={setOrigin} placeholder="Origem" required />
        <DockSelector
          onChange={setDestination}
          placeholder="Destino"
        />
      </Box>

      <Box
        display='flex'
        gap={1}
        flex='1'
        height='100%'
        justifyItems='flex-start'
        alignItems='center'>
        <TextField
          label="Digite aqui para adicionar..."
          id='search'
          name="search"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && searchPackage(event.target.value)}
          autoFocus
        />
        <IconButton
          onClick={() => searchPackage(input)}
          style={{ width: 'fit-content', height: 'fit-content', backgroundColor: '#1253da', color: '#FFFF' }}
        >
          <Add />
        </IconButton>
      </Box>

      <Box display='flex' flexDirection='column' gap={3}>
        <PrintButton packageList={packageList} driverId={driverData ? driverData.driver : '-1'} originDock={origin} destinationDock={destination} />
        <Button
          variant='contained'
          onClick={() => clearRomanian()}
          style={{ backgroundColor: '#6b6b6b', width: '240px', height: '40px' }}
        >
          Limpar Romaneio
        </Button>
      </Box>

      <Paper sx={{ width: '150px', height: '120px' }} elevation={3} >
        <Typography variant="h4" textAlign='center' fontWeight='bold'>TOTAL</Typography>
        <Typography variant="h3" textAlign='center' fontWeight='bold'>{totalRows}</Typography>
      </Paper>

    </Box>
  )
}

export { HeaderElements }
