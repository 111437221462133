import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    @media screen and (min-width: 912px) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
    }
`;

export const Title = styled.div`
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
`;

export const UpperSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const LowerSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
`;
export const MessageItem = styled.div`
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;
export const MessageItemLabel = styled.div`
    font-size: 1.4rem;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
`;
export const MessageItemValues = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;
export const ResultsTitle = styled.div`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 300;
    margin: 20px 0px;
`;
