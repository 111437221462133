import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import Card from '../../components/Card';
import getStyle from '../../utils/getStyle';
import { Container } from './styles';

const SlaEfficiency = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading, data: kpis } = useQuery(
        ['kpis'],
        async () => {
            const { data } = await api.get('/kpis');

            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Dashboards atualizados!',
                { variant: 'success',
                  action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const slaDay = useMemo(() => {
        if (!kpis) return 0;

        if (Number.isNaN(kpis.items.deliveries?.sla)) return 0;

        return parseFloat(kpis.items.deliveries?.sla);
    }, [kpis]);

    const slaMonth = useMemo(() => {
        if (!kpis) return 0;

        const deliveries = kpis.items.sla.reduce(
            (total, { delivered }) => total + parseInt(delivered),
            0
        );
        const onTime = kpis.items.sla.reduce((total, { onTime }) => total + parseInt(onTime), 0);
        return ((onTime / deliveries) * 100).toFixed(2);
    }, []);

    const efficiency = useMemo(() => {
        if (!kpis) return 0;

        function kpiDelivered(value) {
            if (Number.isNaN(value.items.deliveries.delivered)) return 0;

            return parseInt(value.items.deliveries.delivered);
        }

        const [deliveries] = kpis.items.status.filter(
            ({ status }) => status === 'Entrega em andamento'
        );

        const value =
            (kpiDelivered(kpis) / (parseInt(deliveries.quantity) + kpiDelivered(kpis))) * 100;

        if (Number.isNaN(value)) return 0;

        return value.toFixed(2);
    }, []);

    if (!kpis && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return isLoading ? (
        <Loader color="#000" />
    ) : !kpis && !isLoading ? (
        <Alert severity="success" sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
            Nenhuma atualização
        </Alert>
    ) : (
        <Container>
            <Card theme={getStyle(slaDay)} description="SLA GERAL HOJE" value={slaDay} />
            <Card theme={getStyle(efficiency)} description="EFICÁCIA HOJE" value={efficiency} />
            <Card theme={getStyle(slaMonth)} description="SLA GERAL MÊS" value={slaMonth} />
        </Container>
    );
};

export default SlaEfficiency;
