import styled, { keyframes, css } from 'styled-components';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { flash, pulse } from 'react-animations';
const anim = keyframes`${flash}`;
const fromCacheAnimation = keyframes`${pulse}`;

export const Container = styled.div`
    width: 100%;
    max-width: 100vw;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #fafafa;
    box-shadow: 0px 0.3px 0.5px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    & + & {
        margin-top: 10px;
    }
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 0px;
`;

export const Label = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.dark};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const Value = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.theme.light};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const Status = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 0px;
    border-radius: 20%;
    width: 500px;
    height: 15px;
`;

const pillVariants = {
    green: css`
        background-color: #188251;
    `,
    blue: css`
        background-color: blue;
    `,
    orange: css`
        background-color: orange;
    `,
    'light-green': css`
        color: ${(props) => props.theme.dark};
        background-color: #42ffad;
    `,
    'dark-green': css`
        background-color: #00691b;
    `,
    gray: css`
        background-color: gray;
    `,
    purple: css`
        background-color: purple;
    `,
    fromCache: css`
        animation: infinite 2s ${fromCacheAnimation};
        background: rgb(2, 0, 36);
        background: linear-gradient(
            -90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(9, 9, 121, 1) 35%,
            rgba(0, 212, 255, 1) 100%
        );
    `
};

export const StatusText = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    word-break: keep-all;
    white-space: nowrap;

    color: ${(props) => props.theme.secondary};

    ${(props) => (props.variant ? pillVariants[props.variant] : pillVariants['blue'])}

    padding: 16px;
    border-radius: 16px;
    height: 10px;
    width: fit-content;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ToggleButton = styled.button`
    width: 100%;
    border: none;
`;

export const ButtonsContainer = styled.div`
    margin: 5px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`;

export const ExpandIcon = styled(KeyboardArrowDown)``;
export const CollapseIcon = styled(KeyboardArrowUp)``;

export const WarningIcon = styled.span`
    color: red;
    animation: infinite 1s ${anim};
`;
