import React, { useState } from 'react';
import { useMemo } from 'react';
import {
  OrderListItem,
  OrderDate,
  OrderListItemNumber,
  OrderListItemBody,
  OrderListItemDetailsBody,
  DetailsItem,
  DetailsItemContent,
  DetailsItemLabel,
  DetailCloseIcon,
  DetailOpenIcon,
  ToggleCardButton,
} from './styles';

const ListItemExpandable = ({ data }) => {
  const [open, setOpen] = useState(false);

  const order = useMemo(() => {
    const {
      alphaCode,
      code,
      packageDate,
      statusLabel,
      location,
      neighborhood,
      driver,
      phone,
      recipient,
    } = data;

    const date = new Date(
      new Date(packageDate).getFullYear(),
      new Date(packageDate).getMonth(),
      new Date(packageDate).getDate() + 1
    ).toLocaleDateString('pt-BR');

    return {
      alphaCode,
      code,
      date,
      statusLabel,
      address: location,
      neighborhood,
      driver,
      phone,
      recipient,
    };
  }, [data]);

  return (
    <OrderListItem open={open}>
      <OrderListItemBody>
        <OrderListItemNumber>{order.alphaCode}</OrderListItemNumber>
        <OrderDate>{order.date}</OrderDate>
        <ToggleCardButton onClick={() => setOpen(!open)}>
          {open ? <DetailCloseIcon /> : <DetailOpenIcon />}
        </ToggleCardButton>
      </OrderListItemBody>
      {open && (
        <OrderListItemDetailsBody>
          <DetailsItem>
            <DetailsItemLabel>Status</DetailsItemLabel>
            <DetailsItemContent>{order.statusLabel}</DetailsItemContent>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>Endereço</DetailsItemLabel>
            <DetailsItemContent>{order.address}</DetailsItemContent>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>Destinatário</DetailsItemLabel>
            <DetailsItemContent>{order.recipient}</DetailsItemContent>
          </DetailsItem>
        </OrderListItemDetailsBody>
      )}
    </OrderListItem>
  );
};

export default ListItemExpandable;
