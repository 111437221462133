import React from 'react';

import { Container, Item, Label, Value, HistoryContainer } from './styles';

import HistoryItem from '../HistoryItem';

const Details = ({ isBackoffice, order, onUpdate }) => {
  return (
    <Container>
      {isBackoffice &&
        order.invoices.map((i) => (
          <Item key={i.number}>
            <Label>Nota Nº: {i.number}</Label>
            <Value>Chave: {i.key}</Value>
            {i.series && <Value>Série: {i.series}</Value>}
            <Value>Valor: {i.value}</Value>
          </Item>
        ))}
      <Label>Histórico</Label>
      <HistoryContainer>
        {order.history
          .sort((a, b) => a.date > b.date)
          .map((e) => (
            <HistoryItem
              event={e}
              key={`${e.action}-${e.date}`}
              onUpdate={onUpdate}
            />
          ))}
      </HistoryContainer>
    </Container>
  );
};

export default Details;
