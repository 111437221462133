import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import Loader from '../components/Loader';

import OperationRoutes from '../modules/Operation/routes';
import RetailerRoutes from '../modules/Retailer/routes';
import PanelRoutes from '../modules/Panel/routes';
import DashboardRoutes from '../modules/Dashboard/routes'


import Welcome from '../modules/Shared/Pages/Welcome';
import Login from '../modules/Auth/Login';
import ChangePassword from '../modules/Auth/ChangePassword';
import FinancialRoutes from '../modules/Financial/routes';

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <OperationRoutes />
      <RetailerRoutes />
      <PanelRoutes />
      <DashboardRoutes />
      <FinancialRoutes />
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/trocar-senha' component={ChangePassword} isPrivate />
        <Redirect path='/' exact to='/inicio' isPrivate />
        <Route path='/inicio' exact component={Welcome} isPrivate />
      </Switch>
    </Suspense>
  );
};

export default Routes;
