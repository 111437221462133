import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Loader from '../../../../components/Loader';
import { isValid } from '../../../../utils/validations';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../services/api';

const DockSelector = ({
  disabled = false,
  onChange,
  placeholder = 'Selecionar XD',
  required = false,
  style
}) => {
  const { isLoading, data: docks } = useQuery(
    ['docks'],
    async () => {
      const { data } = await api.get('/docks');

      return data;
    },
    {
      placeholderData: () => {
        return [];
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  );

  return isLoading ? (
    <Loader type="ThreeDots" color="black" />
  ) : (
    <FormControl
      style={{ width: '300px', ...style }}
      variant="outlined"
      sx={{ m: 1, minWidth: 300 }}
      disabled={disabled}
      required>
      <InputLabel id="dock-select">{placeholder}</InputLabel>
      <Select
        defaultValue=""
        labelId="dock-select"
        id="dock-select"
        label="Termo de busca"
        onChange={(e) => onChange(isValid(e.target.value) ? e.target.value : null)}>
        {!!docks &&
          docks.map((d) => (
            <MenuItem value={d.id} key={d.id}>
              {d.dock}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DockSelector;
