import { json2excel } from 'js2excel';
import { DownloadIcon, ToggleButton } from './styles';
import { logError } from '../../../../utils/error';

const DriverPackagesDownload = ({ deliveries }) => {
    const prepareData = () => {
        try {
            const data = deliveries.map((d) => ({
                pacotes: d.package,
                destinatario: d.recipient,
                cidade: d.city,
                docka: d.dock,
                Bairro: d.neighborhood,
                Pedido: d.package,
                sla: d.sla,
                UF: d.state
            }));
            json2excel({
                data,
                name: 'Driver-deliveries',
                formateDate: 'yyyy/mm/dd'
            });
        } catch (error) {
            logError(error);
        }
    };
    return (
        <>
            <ToggleButton onClick={prepareData}>
                <DownloadIcon />
            </ToggleButton>
        </>
    );
};

export { DriverPackagesDownload };
