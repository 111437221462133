import { Box, styled, Button as ButtonUi } from '@mui/material';

export const Container = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const Button = styled(ButtonUi)``;
