import { Autocomplete, FormControl, Stack, TextField } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { isValid } from '../../../../utils/validations';
import DeliveryModalities from '../../components/DeliveryModalities';
import DriverPackagesList from '../../components/DriverPackagesList';
import DriverSupplier from '../../components/DriverSupplier';
import {
    ConfirmTransferenceButton,
    Container,
    DriverItem,
    NoDriverSelectedWarning,
    PackageListContainer
} from './styles';

const Relocate = () => {
    const [transferObject, setTransferObject] = useState({
        from: null,
        to: null,
        packages: []
    });
    const [supplier, setSupplier] = useState(null);
    const [deliveryModality, setDeliveryModality] = useState('154');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading: isDriverToLoading, data: driversTo } = useQuery(
        [`operations/drivers/${isValid(supplier) ? supplier : ''}`],
        async () => {
            const response = await api.get(
                `/operations/drivers/${isValid(supplier) ? supplier : ''}`
            );

            return response.data;
        },
        {
            placeholderData: () => {
                return [];
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    const { isLoading: isWalletLoading } = useQuery([`drivers/${transferObject.from}/wallet`]);

    const availabelDriversTo = useMemo(() => {
        if (!driversTo) return [];

        if (driversTo.length === 0) return [];

        return driversTo
            .filter((d) => !!transferObject.from && d.id !== transferObject.from)
            .map((d) => ({ label: d.name, id: d.id }));
    }, [driversTo, transferObject.from]);

    function setFrom(data) {
        if (data === null) {
            setSupplier(null);
            const newTransferObject = {
                from: null,
                to: null,
                packages: []
            };
            setTransferObject(newTransferObject);
        }
        if (!!data) {
            const { supplier, driver } = data;

            const newTransferObject = {
                from: driver,
                to: transferObject.to,
                packages: []
            };

            if (isValid(supplier)) {
                Object.assign(newTransferObject, { supplier });
                setSupplier(supplier);
            }

            setTransferObject(newTransferObject);
        }
    }

    function setTo(driver) {
        const newTransferObject = {
            ...transferObject,
            from: transferObject.from,
            to: driver,
            packages: transferObject.packages
        };

        setTransferObject(newTransferObject);
    }

    function setPackages(packages) {
        const newTransferObject = {
            from: transferObject.from,
            to: transferObject.to,
            packages
        };
        setTransferObject(newTransferObject);
    }

    function handleTransfer() {
        const requestObj = { ...transferObject, deliveryModality };

        api.post('/operations/reallocate', requestObj)
            .then((response) => {
                const { data } = response;

                const haveStackError = response.data.toString().includes('error');

                if (!data || haveStackError) {
                    enqueueSnackbar(
                        'Devido a um erro a transferência não pode ser concluida, tente novamente mais tarde.',
                        {
                            variant: 'error',
                            action: (id) => handleCloseAction(id, closeSnackbar)
                        }
                    );
                } else {
                    enqueueSnackbar(
                        `${transferObject.packages.length} pacotes transferidos com sucesso!!`,
                        {
                            variant: 'success',
                            action: (id) => handleCloseAction(id, closeSnackbar)
                        }
                    );
                    setTransferObject({
                        from: null,
                        to: null,
                        packages: []
                    });

                    const el = document.getElementById('driver2');
                    if (el) el.options[0].selected = 'selected';
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar(
                    'Devido a um erro a transferência não pode ser concluida, tente novamente mais tarde.',
                    {
                        variant: 'info',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    }
                );
            });
    }

    return (
        <Container>
            <Stack direction="row">
                <DriverItem>
                    <DriverSupplier
                        onChange={(e) => setFrom(e)}
                        disabled={isDriverToLoading || isWalletLoading}
                        noVehicle
                    />
                </DriverItem>
                {isDriverToLoading || isWalletLoading ? (
                    <Loader color="#000" sx={{ flex: 1, m: 4 }} />
                ) : (
                    <Stack direction="column" spacing={2}>
                        <FormControl variant="filled">
                            <Autocomplete
                                openOnFocus
                                disabled={isDriverToLoading}
                                defaultValue=""
                                id="driver2"
                                label="Motorista Destino"
                                options={availabelDriversTo}
                                renderInput={(params) => (
                                    <TextField {...params} label="Motorista Destino" />
                                )}
                                onChange={(_, { id }) => setTo(id)}
                                noOptionsText="Nenhuma correspondência"
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        </FormControl>

                        <DeliveryModalities
                            style={{ width: '300px' }}
                            onChange={(v) => {
                                setDeliveryModality(v);
                            }}
                        />
                    </Stack>
                )}
            </Stack>

            <PackageListContainer>
                <ConfirmTransferenceButton
                    disabled={
                        !transferObject.from ||
                        !transferObject.to ||
                        transferObject.packages.length === 0
                    }
                    onClick={() => handleTransfer()}>
                    Transferir
                </ConfirmTransferenceButton>

                {transferObject.from ? (
                    <DriverPackagesList driver={transferObject.from} onChange={setPackages} />
                ) : (
                    <NoDriverSelectedWarning>
                        Selecione os motoristas primeiro
                    </NoDriverSelectedWarning>
                )}
            </PackageListContainer>
        </Container>
    );
};

export default Relocate;
