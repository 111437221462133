import React, { useMemo } from 'react';
import { Container, Item, Label, Value, Section } from './styles';

const PickUpAtStoreOrderCard = ({ data, onCheck, selected = false }) => {
    const order = useMemo(() => {
        const { customerId, customer, driverId, driver, location, code } = data;
        const orderObject = {
            checked: selected,
            customerId,
            customer,
            driverId,
            driver,
            location,
            code,
        };

        return orderObject;
    }, [data, selected]);

    return (
        <Container onClick={() => onCheck(order.code)} checked={order.checked}>
            <Section>
                <Item>
                    <Label>Cod. Pedido: </Label>
                    <Value>{order.code}</Value>
                </Item>
            </Section>

            <Section>
                <Item>
                    <Label>Cliente: </Label>
                    <Value>{order.customer}</Value>
                </Item>
            </Section>

            <Section>
                <Item>
                    <Label>Motorista: </Label>
                    <Value>{order.driver}</Value>
                </Item>
            </Section>

            <Item style={{ flex: 1 }}>
                <Label>Destino: </Label>
                <Value>{order.location}</Value>
            </Item>
        </Container>
    );
};

export default PickUpAtStoreOrderCard;
