import { Box, Chip } from '@mui/material';

import { Container } from './styles';

const TagFile = ({ status }) => {

  function handleTagColorIfStatus(status) {
    if (status === 'xml') return 'success'
    if (status === 'pdf') return 'info'
    return 'warning'
  }


  return (
    <Container>
      <Chip
        variant="filled"
        color={handleTagColorIfStatus(status)}
        size="small"
        label={status === '*' ? 'xml/pdf' : status}
        sx={{
          m: '1mm',
          fontWeight: '500'
        }}
      />
    </Container>
  )

};

export { TagFile };
