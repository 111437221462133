import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
    width: 100%;
    flex: 1;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const SearchForm = styled.div`
    width: 100%;
    max-width: 857px;
    min-height: 168px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    flex-wrap: wrap;

    & > button {
        margin: 8px 0px;
    }

    & > h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: ${(props) => props.theme.dark};
    }

    & > p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin: 4px 0px;
        padding: 0px;
        color: ${(props) => props.theme.light};
    }

    & > p:last-child {
        width: 100%;
        text-align: center;
    }
`;

export const UpperSection = styled.div`
    margin-bottom: 10px;
`;

export const HorizontalSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const SearchInput = styled.textarea`
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 300;
    padding: 10px;

    width: 300px;
    max-width: 300px;
    min-width: 300px;
    max-height: 300px;

    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};
    color: ${(props) => props.theme.dark};

    &:focus {
        border-bottom: 2px solid black;
        color: ${(props) => props.theme.dark};
        font-weight: 400;
        &::placeholder {
            color: ${(props) => props.theme.dark};
            font-weight: 400;
        }
    }

    &:disabled {
        border-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.light};
    }
`;

export const Select = styled.select`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12),
        0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: none;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;

    padding-left: 10px;
    height: 40.00001525878906px;
    width: 176px;

    border-radius: 4px 4px 0px 0px;
    background-color: ${(props) => darken(0.05, props.theme.secondary)};
    color: ${(props) => props.theme.dark};

    margin: 20px 0px;

    &:-webkit-input-placeholder {
        text-align: left;
        font-size: 50px;
    }
`;

export const OrderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 30px 0px;
`;

export const MessageItem = styled.div`
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
`;
export const MessageItemLabel = styled.div`
    font-size: 1.4rem;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
`;
export const MessageItemValues = styled.div`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`;

export const ContainerBox = styled.div`
display: flex;
gap: 1rem;
`;
