import { useSnackbar } from 'notistack';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import CustomerSelectionModal from '../components/CustomerSelectionModal/index';
import api from '../services/api';
import { queryClient } from '../services/queryClient';
import configs from '../utils/configs.json';
import { roles } from '../utils/roles';
import { session } from '../utils/storage';
import handleCloseAction from '../components/HandleCloseAction';
import apm from '../services/elastic-apm';
import { logError } from '../utils/error';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const prefetch = async (type) => {
        if (type === 'customer') {
            await queryClient.prefetchQuery(
                ['retailer/delivery-modalities'],
                async () => await (await api.get('/retailer/delivery-modalities')).data
            );

            await queryClient.prefetchQuery(
                ['retailer/tags'],
                async () => await (await api.get('/retailer/tags')).data
            );

            return;
        }

        await queryClient.prefetchQuery(
            ['customers'],
            async () => await (await api.get('/customers')).data
        );
        await queryClient.prefetchQuery(
            ['docks'],
            async () => await (await api.get('/docks')).data
        );
        await queryClient.prefetchQuery(
            ['customers'],
            async () => await (await api.get('/customers')).data
        );
        await queryClient.prefetchQuery(
            ['operations/delivery/modalities'],
            async () => await (await api.get('/operations/delivery/modalities')).data
        );

        await queryClient.prefetchQuery(
            ['operations/suppliers'],
            async () => await (await api.get('/operations/suppliers')).data
        );
    };

    const [data, setData] = useState(() => {
        const user = JSON.parse(session.getEncrypted('@vaifacilbr:user'));
        const token = session.getEncrypted('@vaifacilbr:token');

        if (token && user && Object.keys(user).length > 0) {
            const { currentCustomer } = user;
            if (currentCustomer) {
                api.defaults.headers.tenant = `${currentCustomer.id}`;

                const isQueryOnlyCustomer = configs.queryOnlyCustomers.includes(
                    user.currentCustomer.id
                );

                if (isQueryOnlyCustomer) {
                    delete user.roles;
                    user.roles = ['queryOnly'];
                } else {
                    prefetch('customer');
                }
            }

            api.defaults.headers.authorization = `Bearer ${token}`;

            apm.setUserContext({
                id: user.id,
                email: user.email,
                username: user.name
            });

            return { token, user };
        }

        return {};
    });

    const isBackoffice = useMemo(() => {
        if (!data) return false;

        const { user } = data;

        if (!user) return false;

        const allowedRoles = ['1', '3'];

        const isAllowed = allowedRoles.filter((i) => user.roles.includes(i)).length > 0;

        return isAllowed;
    }, [data]);

    const signOut = useCallback(() => {
        session.removeEncrypted('@vaifacilbr:token');
        session.removeEncrypted('@vaifacilbr:user');

        setData({});
    }, [isBackoffice]);

    const signIn = useCallback(
        async ({ username, password }) => {
            try {
                const response = await api.post('/auth/login', { username, password });

                const { token, user } = response.data;

                apm.setUserContext({
                    id: user.id,
                    email: user.email,
                    username: user.name
                });

                if (!Object.keys(user).length > 0) {
                    session.clear();
                    setData({});
                    throw new Error('Invalid user');
                }

                const { customers } = user;

                const haveCustomers = customers.length > 0;

                if (haveCustomers) {
                    if (customers.length === 1) {
                        const [customer] = customers;
                        Object.assign(user, { currentCustomer: customer });

                        api.defaults.headers.tenant = `${customer.id}`;

                        const isQueryOnlyCustomer = configs.queryOnlyCustomers.includes(
                            customer.id
                        );

                        if (isQueryOnlyCustomer) {
                            user.oldRoles = user.roles;
                            delete user.roles;
                            user.roles = ['queryOnly'];
                        } else {
                            prefetch('customer');
                        }
                    } else {
                        Object.assign(user, { currentCustomer: null });
                    }
                }

                session.setEncrypted('@vaifacilbr:token', token);
                session.setEncrypted('@vaifacilbr:user', JSON.stringify(user));

                api.defaults.headers.authorization = `Bearer ${token}`;

                if (!haveCustomers) {
                    prefetch();
                }

                setData({ token, user });

                enqueueSnackbar('Bem vindo(a) de volta!', {
                    variant: 'success',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            } catch (error) {
                logError(error);
                const { message } = error;

                if (!message) {
                    enqueueSnackbar('Erro ao fazer login, tente novamente', {
                        variant: 'error',
                        action:(id) => handleCloseAction(id, closeSnackbar)
                    });
                    return;
                }

                if (message.includes('environment')) {
                    enqueueSnackbar('Erro no ambiente da aplicação', {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                    return;
                }

                if (message.includes('user')) {
                    enqueueSnackbar('Erro no ambiente da aplicação', {
                        variant: 'error',
                        action: (id) => handleCloseAction(id, closeSnackbar)
                    });
                    return;
                }

                enqueueSnackbar('Erro na autenticação, cheque suas credenciais.', {
                    variant: 'error',
                    action: (id) => handleCloseAction(id, closeSnackbar)
                });
            }
        },
        [prefetch]
    );

    const updateUser = useCallback(
        (user) => {
            if (user.currentCustomer) {
                api.defaults.headers.tenant = `${user.currentCustomer.id}`;

                const isQueryOnlyCustomer = configs.queryOnlyCustomers.includes(
                    user.currentCustomer.id
                );

                if (isQueryOnlyCustomer) {
                    user.oldRoles = user.roles;

                    delete user.roles;
                    user.roles = ['queryOnly'];
                } else {
                    const { oldRoles, roles } = data.user;

                    if (roles.includes('queryOnly') && oldRoles) {
                        user.roles = oldRoles;
                    }

                    prefetch('customer');
                }
            }

            session.setEncrypted('@vaifacilbr:user', JSON.stringify(user));

            setData({
                token: data.token,
                user
            });
        },
        [setData, data.token]
    );

    const getAuthorization = useCallback(
        (resource) => {
            const { user } = data;

            if (!user || (user && !user.roles) || user.roles.length === 0) return false;

            const userRoles = user.roles.map((r) => {
                return roles[r] || null;
            });

            const userResources = userRoles.reduce((acc, curr) => {
                curr && acc.push(...curr.resources);

                return acc;
            }, []);

            if (userResources.includes('*')) return true;

            if (userResources.includes(resource)) return true;

            return false;
        },
        [data]
    );

    const showCustomerSelectionModal = useMemo(() => {
        if (!data.user) return false;

        if (!!data.user.currentCustomer) return false;

        if (data.user.currentCustomer === null) return true;

        return false;
    }, [data]);

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                signIn,
                signOut,
                updateUser,
                getAuthorization,
                showCustomerSelectionModal
            }}>
            <CustomerSelectionModal />
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    const context = useContext(AuthContext);

    return context;
}

export { AuthProvider, useAuth };
