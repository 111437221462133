import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-evenly;
padding: 2.5rem;
gap: 2.5rem;
`;
