import { Delete } from "@mui/icons-material"
import { Avatar, Button, Card, CardContent, IconButton, Typography } from "@mui/material"

const PackageCard = ({ data, deletePackage }) => {

  return (
    <Card sx={{ minWidth: 275, position: 'relative', height: '180px' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>

        <IconButton
          onClick={() => deletePackage(data)}
          style={{ position: 'absolute', top: '0', right: '0', color: 'red' }}
          size='20'
        >
          <Delete />
        </IconButton>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {data.contractName}
        </Typography>
        <Typography variant="h5" component="div" fontWeight='bold'>
          {data.code}

        </Typography>
        <Typography variant="body2">
          {data.address}, {data.number}, {data.neighborhood}
          <br />
          {data.state}, {data.city}
        </Typography>
      </CardContent>
      <Typography variant="h6" fontWeight='bold' sx={{ position: 'absolute', bottom: '0.5rem', right: '1rem' }} >
        {data.count}
      </Typography>
    </Card>
  )
}

export { PackageCard }
