import React, { useState } from 'react';
import { useMemo } from 'react';
import {
  OrderListItem,
  OrderDate,
  OrderListItemNumber,
  OrderListItemBody,
  OrderListItemDetailsBody,
  DetailsItem,
  DetailsItemContent,
  DetailsItemLabel,
  DetailCloseIcon,
  DetailOpenIcon,
  ToggleCardButton,
  ToggleButton,
  Icon,
} from './styles';

const ListItem = ({ data, onSelect, initialState, selected }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(initialState);

  const order = useMemo(() => {
    const {
      alphaCode,
      code,
      packageDate,
      statusLabel,
      contract,
      address,
      neighborhood,
    } = data;
    setChecked(selected.includes(alphaCode));

    const date = new Date(`${packageDate}T03:00:00Z`).toLocaleDateString(
      'pt-BR'
    );

    return {
      alphaCode,
      code,
      date,
      statusLabel,
      contract,
      address,
      neighborhood,
    };
  }, [data, selected]);

  function handleCheck() {
    onSelect(order.alphaCode);
    setChecked(!checked);
  }

  return (
    <OrderListItem open={open}>
      <OrderListItemBody>
        <ToggleButton onClick={() => handleCheck()}>
          <Icon className='material-icons' enable={checked}>
            {checked ? 'toggle_on' : 'toggle_off'}
          </Icon>
        </ToggleButton>
        <OrderListItemNumber>Pedido # {order.code}</OrderListItemNumber>
        <OrderDate>{order.date}</OrderDate>
        <ToggleCardButton onClick={() => setOpen(!open)}>
          {open ? <DetailCloseIcon /> : <DetailOpenIcon />}
        </ToggleCardButton>
      </OrderListItemBody>
      {open && (
        <OrderListItemDetailsBody>
          <DetailsItem>
            <DetailsItemLabel>Status</DetailsItemLabel>
            <DetailsItemContent>{order.statusLabel}</DetailsItemContent>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>Modalidade</DetailsItemLabel>
            <DetailsItemContent>{order.contract}</DetailsItemContent>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>Endereço</DetailsItemLabel>
            <DetailsItemContent>{order.address}</DetailsItemContent>
          </DetailsItem>
          <DetailsItem>
            <DetailsItemLabel>Bairro</DetailsItemLabel>
            <DetailsItemContent>{order.neighborhood}</DetailsItemContent>
          </DetailsItem>
        </OrderListItemDetailsBody>
      )}
    </OrderListItem>
  );
};

export default ListItem;
