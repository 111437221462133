import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';

const Panels = ({ orders }) => {
    const succeedCount = useMemo(() => {
        return orders.filter((o) => o.state === 'success').length;
    }, [orders]);

    const erroredCount = useMemo(() => {
        return orders.filter((o) => o.state === 'error').length;
    }, [orders]);

    const haveErrors = useMemo(() => {
        return erroredCount > 0;
    }, [erroredCount]);

    return (
        <Stack
            width="100%"
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}>
            <Stack
                sx={{
                    padding: 2
                }}
                direction="column"
                justifyContent="flex-start"
                alignItems="center">
                <Typography fontSize="2rem" fontWeight="600">
                    {succeedCount}
                </Typography>
                <Typography fontSize="1.4rem" fontWeight="300">
                    Sucesso
                </Typography>
            </Stack>

            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={
                    haveErrors && {
                        background: 'crimson',
                        color: '#fff',
                        padding: 2,
                        borderRadius: '8px'
                    }
                }>
                <Typography fontSize="2rem" fontWeight="600">
                    {erroredCount}
                </Typography>
                <Typography fontSize="1.4rem" fontWeight="300">
                    Erros
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Panels;
