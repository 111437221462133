import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import { Table } from '../../components/Table';
import getStyle from '../../utils/getStyle';
import { Container, themes } from './styles';

const SlaByContract = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { isLoading, data: kpis } = useQuery(
        ['kpis'],
        async () => {
            const { data } = await api.get('/kpis');

            return data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar('Dashboards atualizados!',
                { variant: 'success',
                  action: (id) => handleCloseAction(id, closeSnackbar)
                });
            },
            staleTime: 1000 * 60 * 5 // 5 minutes
        }
    );

    function refDate() {
        const [day, month, year] = new Date().toLocaleDateString('pt-BR').split('/');
        const refDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return refDate;
    }

    const slaContractDay = useMemo(() => {
        if (!kpis) return 0;
        const data = _.groupBy(
            kpis.items.sla.filter(({ date }) => date === refDate()),
            'contract'
        );
        return Object.keys(data).map((key) => {
            const delivered = data[key].reduce(
                (total, { delivered }) => total + parseFloat(delivered),
                0
            );
            const onTime = data[key].reduce((total, { onTime }) => total + parseFloat(onTime), 0);
            const [{ contractedSla }] = data[key];
            return {
                contract: key,
                sla: (onTime / delivered) * 100,
                contractedSla
            };
        });
    }, [kpis]);

    const slaContract = useMemo(() => {
        if (!kpis) return [];

        const data = _.groupBy(kpis.items.sla, 'contract');
        return Object.keys(data).map((key) => {
            const delivered = data[key].reduce(
                (total, { delivered }) => total + parseFloat(delivered),
                0
            );
            const onTime = data[key].reduce((total, { onTime }) => total + parseFloat(onTime), 0);
            const [{ contractedSla }] = data[key];
            return {
                contract: key,
                sla: (onTime / delivered) * 100,
                contractedSla
            };
        });
    }, [kpis]);

    if (!kpis && !isLoading)
        return (
            <Alert
                severity="success"
                sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
                Nenhuma atualização
            </Alert>
        );

    return isLoading ? (
        <Loader color="#000" />
    ) : !kpis && !isLoading ? (
        <Alert severity="success" sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
            Nenhuma atualização
        </Alert>
    ) : (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Contrato</th>
                        <th>SLA</th>
                    </tr>
                </thead>
                <tbody>
                    {slaContractDay.map((contract) => (
                        <tr key={contract.contract} style={themes(getStyle(contract.sla))}>
                            <td>{contract.contract}</td>
                            <td>{contract.sla.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>SLA POR CONTRATO HOJE</td>
                    </tr>
                </tfoot>
            </Table>
            <Table>
                <thead>
                    <tr>
                        <th>Contrato</th>
                        <th>SLA</th>
                    </tr>
                </thead>
                <tbody>
                    {slaContract.map((contract) => (
                        <tr key={contract.contract} style={themes(getStyle(contract.sla))}>
                            <td>{contract.contract}</td>
                            <td>{contract.sla.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>SLA POR CONTRATO MÊS</td>
                    </tr>
                </tfoot>
            </Table>
        </Container>
    );
};

export default SlaByContract;
