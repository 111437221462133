import { TableElement } from './styles'

const Table = ({ children }) => {
    return (
        <TableElement>
            {children}
        </TableElement>
    )
}

export { Table }
