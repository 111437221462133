import { GetApp } from "@material-ui/icons";
import styled from "styled-components";

export const ToggleButton = styled.button`
  background: transparent;
  width: fit-content;
  height: fit-content;
  border: none;
  cursor: pointer;
`;

export const DownloadIcon = styled(GetApp)``;
