import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
border: 1px solid #e1e1e0;
border-radius: 0.25rem;
box-sizing: border-box;
width: 20rem;
height: 32rem;

`;

export const Total = styled.strong`
font-size: 5rem;
color: #333;

`;

export const Footer = styled.div`
border-top: 1px solid #e1e1e0 ;
margin-top: 2rem;
padding: 1rem;
display: flex;
align-items: center;
justify-content: center;
width: 100%;

`;

export const Description = styled.span`
color: #cd5c5c;
font-weight: bold;
font-size: 1.75rem;
`;
