import { Switch } from 'react-router-dom';
import React from 'react';
import Route from '../../../routes/Route';
import Stock from '../pages/Stock';
import SlaEfficiency from '../pages/SlaEfficiency';
import SlaByContract from '../pages/SlaByContract';
import DeliveriesMonth from '../pages/DeliveriesMonth';
import SlaEvolution15Days from '../pages/SlaEvolution15Days';
import StockEvolution from '../pages/StockEvolution';

const DashboardRoutes = () => {
    return (
        <Switch>
            <Route path="/dashboard-estoque" component={Stock} isPrivate />
            <Route path="/dashboard-sla-eficacia" component={SlaEfficiency} isPrivate />
            <Route path="/dashboard-sla-por-contrato" component={SlaByContract} isPrivate />
            <Route path="/dashboard-entregas-mes" component={DeliveriesMonth} isPrivate />
            <Route path="/dashboard-sla-evolucao" component={SlaEvolution15Days} isPrivate />
            <Route path="/dashboard-estoque-evolucao" component={StockEvolution} isPrivate />
        </Switch>
    );
};

export default DashboardRoutes;
