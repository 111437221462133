import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import api from '../../../../services/api';
import { deliveriesFormattingForBlockedDeliveriesPanel } from '../../../../utils/formatting';
import BlockedDeliveriesTable from './BlockedDeliveriesTable';

const BlockedDeliveriesPanel = () => {
    const [type, setType] = useState('*');

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const { isLoading, data: lookDeliveries } = useQuery(
        ['/operations/blockDelivery'],
        async () => {
            const { data } = await api.get('/operations/blockDelivery');
            return data;
        }
    );

    if (isLoading || !lookDeliveries) {
        return <CircularProgress />;
    }

    const filteredDeliveries = lookDeliveries.filter((pkg) => {
        if (type === '*') {
            return true;
        }
        return pkg.occurrence === type;
    });

    const blockedDeliveriesLength = lookDeliveries.filter(
        (pkg) => pkg.occurrence === 'Barrar entrega'
    ).length;
    const agreementLength = lookDeliveries.filter(
        (pkg) => pkg.occurrence === 'Acordo Comercial'
    ).length;

    const deliveriesFormatted = deliveriesFormattingForBlockedDeliveriesPanel(filteredDeliveries);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            width="100%"
            height="100%">
            <Box display="flex" flexDirection="column" width="fit-content" height="800px" gap={2}>
                <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                    <FormControl sx={{ minWidth: '250px' }}>
                        <InputLabel id="notification_type">Tipo de Notificação</InputLabel>
                        <Select
                            labelId="notification_type"
                            id="demo-simple-select"
                            value={type}
                            label="Tipo de Notificação"
                            onChange={handleChange}>
                            <MenuItem value="*">Todos</MenuItem>
                            <MenuItem value="Barrar entrega">Barrar entrega</MenuItem>
                            <MenuItem value="Acordo comercial">Acordo comercial</MenuItem>
                        </Select>
                    </FormControl>
                    <Box
                        display="flex"
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                width: 128,
                                height: 128
                            },
                            gap: 3
                        }}>
                        <Paper
                            elevation={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '700',
                                fontSize: '5rem',
                                flexDirection: 'column',
                                border: 'solid 1px gray',
                                height: 'fit-content',
                                borderRadius: '0.5rem',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                }
                            }}
                            onClick={() => setType('Barrar entrega')}>
                            <Box
                                sx={{
                                    marginBottom: '-1.2rem',
                                    background: 'orange',
                                    width: '100%',
                                    borderTopRightRadius: '0.5rem',
                                    borderTopLeftRadius: '0.5rem',
                                    textAlign: 'center',
                                    boxSizing: 'border-box',
                                    borderBottom: 'solid 1px gray'
                                }}>
                                <Typography fontWeight="bold" margin={0} padding={0}>
                                    Barrar Entrega
                                </Typography>
                            </Box>
                            {blockedDeliveriesLength}
                        </Paper>
                        <Paper
                            elevation={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '700',
                                fontSize: '5rem',
                                flexDirection: 'column',
                                border: 'solid 1px gray',
                                height: 'fit-content',
                                borderRadius: '0.5rem',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                }
                            }}
                            onClick={() => setType('Acordo comercial')}>
                            <Box
                                sx={{
                                    marginBottom: '-1.20rem',
                                    background: '#ff0000',
                                    width: '100%',
                                    borderTopRightRadius: '0.5rem',
                                    borderTopLeftRadius: '0.5rem',
                                    textAlign: 'center',
                                    boxSizing: 'border-box',
                                    borderBottom: 'solid 1px gray'
                                }}>
                                <Typography
                                    margin={0}
                                    padding={0}
                                    fontWeight="bold"
                                    sx={{
                                        color: 'white'
                                    }}>
                                    Acordo C.
                                </Typography>
                            </Box>
                            {agreementLength}
                        </Paper>
                    </Box>
                </Box>
                <BlockedDeliveriesTable blockedDeliveries={deliveriesFormatted} />
            </Box>
        </Box>
    );
};

export default BlockedDeliveriesPanel;
