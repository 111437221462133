import React, { useMemo } from 'react';
import { Redirect, Route as ReactDOMRoute } from 'react-router-dom';

import Layout from '../components/Layout';
import { useAuth } from '../hooks/auth';
import { allPages } from '../utils/menu';

const Route = ({ isPrivate = false, component: Component, path, ...rest }) => {
    const { user, getAuthorization } = useAuth();

    const authorized = useMemo(() => {
        const resource = allPages.find((r) => !!r.active && r.endpoint === path);

        if (!!user && !!resource) {
            if (getAuthorization(resource.name)) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }, [getAuthorization, path, user]);

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                return isPrivate === !!user && authorized ? (
                    isPrivate ? (
                        <Layout>
                            <Component />
                        </Layout>
                    ) : (
                        <Component />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate ? '/login' : '/inicio',
                            state: { from: location }
                        }}
                    />
                );
            }}
        />
    );
};

export default Route;
