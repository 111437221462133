import React, { useMemo, useRef } from 'react';
import Modal from '../Modal';
import { useAuth } from '../../hooks/auth';
import { Button } from '../index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { ListSubheader, MenuItem, TextField } from '@mui/material';
import handleCloseAction from '../HandleCloseAction';
import { logError } from '../../utils/error';

const validationSchema = Yup.object().shape({
    customerId: Yup.string().trim().required().notOneOf(['false'])
});

const CustomerSelectionModal = () => {
    const { showCustomerSelectionModal, user, updateUser } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const customers = useMemo(() => {
        if (!user) return null;

        return user.customers;
    }, [user]);

    const formik = useFormik({
        initialValues: {
            customerId: ''
        },
        validationSchema,
        onSubmit: handleSetCurrentCustomer
    });

    async function handleSetCurrentCustomer({ customerId }, { reset }) {
        try {
            const selectedCustomer = customers.find((c) => c.id === customerId);
            const newUser = {
                ...user,
                currentCustomer: selectedCustomer
            };

            updateUser(newUser);

            enqueueSnackbar('Usuário Cadastrado com sucesso!', {
                variant: 'success',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });

            reset();
        } catch (error) {
            logError(error);

            const validationErrors = {};
            if (error instanceof Yup.ValidationError) {
                error.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
            }
        }
    }

    return (
        customers && (
            <Modal
                isOpen={showCustomerSelectionModal}
                style={{ content: { width: '100%', maxWidth: '400px' } }}>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        select
                        label="Selecione a loja"
                        margin="normal"
                        style={{ width: '100%' }}
                        value={formik.values.customerId}
                        onChange={formik.handleChange('customerId')}>
                        <ListSubheader color="primary">Selecione a loja</ListSubheader>
                        {customers.map((c) => (
                            <MenuItem key={c.id} value={c.id}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Button type="submit">Confirmar</Button>
                </form>
            </Modal>
        )
    );
};

export default CustomerSelectionModal;
