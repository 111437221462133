export const charsets = [
    "utf8",
    "ISO-8859-1",
    "ISO-8859-2",
    "ISO-8859-3",
    "ISO-8859-4",
    "ISO-8859-9",
    "ISO-8859-13",
    "ISO-8859-15",
    "IBM850",
    "IBM852",
    "windows-1252"
]

// eslint-disable-next-line no-control-regex
export const notLatinChar = new RegExp(/[^\u0000-\u007F\u00C0-\u00C3\u00C8-\u00CA\u00CC-\u00Ce\u00D2-\u00D5\u00D9-\u00DC\u00E0-\u00E4\u00E7-\u00EE\u00F0-\u00F6\u00F9-\u00FC]+/miug)

// eslint-disable-next-line no-control-regex
export const latinChar = new RegExp(/[\u0000-\u007F\u00C0-\u00C3\u00C8-\u00CA\u00CC-\u00Ce\u00D2-\u00D5\u00D9-\u00DC\u00E0-\u00E4\u00E7-\u00EE\u00F0-\u00F6\u00F9-\u00FC]+/miug)