import { useBarcode } from 'react-barcodes';
import { Container } from './styles';

const Barcode = ({ code }) => {
    const { inputRef } = useBarcode({
        value: code,
        options: { format: 'CODE128' },
    });
    return (
        <Container>
            <svg ref={inputRef} style={{ width: '70mm' }} />
        </Container>
    );
};
export default Barcode;
