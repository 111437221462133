import React, { useMemo } from 'react';
import { Container, Page, Logo, Row, Tag, BarcodeContainer } from './styles';
import Barcode from '../../../../../components/Barcode';

import logo from '../../../../../assets/img/logo-white.png';

const Orders = ({ data, printRef }) => {
    const order = useMemo(() => {
        const { alphaCode, code, recipient } = data;

        const phone =
            recipient && recipient.phones ? recipient.phones.map((i) => i.number)[0] : null;

        return {
            alphaCode,
            code,
            recipient: recipient.name,
            address: recipient.address.location.address,
            addressNumber: recipient.address.number,
            complement: recipient.address.complement,
            neighborhood: recipient.address.location.neighborhood,
            city: recipient.address.location.city,
            state: recipient.address.location.state,
            phone
        };
    }, [data]);
    return (
        <div style={{ display: 'none' }}>
            <Container ref={printRef}>
                <Page>
                    <Tag>
                        <Row>
                            <Logo src={logo} />
                            <strong>{order.customer}</strong>
                        </Row>
                        <Row>
                            <strong>{order.recipient}</strong>
                            <BarcodeContainer>
                                <Barcode code={order.code.toString()} />
                            </BarcodeContainer>
                        </Row>
                        <Row>
                            {order.address},{` ${order.addressNumber}`}
                        </Row>
                        <Row>{order.complement}</Row>
                        <Row>{order.neighborhood}</Row>
                        <Row>
                            {order.city}/{order.state}
                        </Row>
                        <Row>TELEFONE: {order.phone}</Row>
                    </Tag>
                </Page>
            </Container>
        </div>
    );
};

export default Orders;
