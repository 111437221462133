import { Card, CardContent, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  width: 180mm;
  height: 273mm;
  padding: 3mm;
  gap: 2mm;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  page-break-after: always;
  * > & {
    text-transform: uppercase;
  }
`;

export const TagUI = styled(Card)`
  display: flex;
  position: relative;

  flex-direction: column;
  width: 150mm;
  height: 49mm;
  border: 0.1mm solid black;
  margin: 0 0 0.1mm 0;
  border-radius: 2mm;
`;

export const Logo = styled('img')`
  width: 30mm;
  margin-right: 5mm;
`;

export const TagHeader = styled('div')`
  display: flex;
  align-items: center;
  padding: 3mm 3mm 3mm 3mm;
`;

export const Title = styled(Typography)`
  font-weight: bold;
  font-size: 3mm;
`;

export const TagBody = styled(CardContent)`
  display: block;
  font-size: 3mm;
  position: relative;
  padding: 0 3mm 3mm 3mm;
`;

export const TextContainer = styled(Box)`
  width: 60mm;
`;

export const Text = styled(Typography)`
  font-size: 0.75rem;
`;
