import { DownloadForOffline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material"

import { useState } from "react";
import { CteTable } from "../../components/CteTable";

import { CteRequestModal } from "../../components/CteRequestModal";


const DownloadXmlCte = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection='column'
        alignItems="center"
        justifyContent="center"
        flex="1"
        gap={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}

        >
          <Typography fontSize='1rem' fontWeight='bold'>Solicitar Download</Typography>
          <DownloadForOffline />
        </Button>
        <CteTable />
      </Box>
      <CteRequestModal isOpen={openModal} onRequestClose={() => setOpenModal(false)} />
    </>
  )
}

export { DownloadXmlCte }


