import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 80vh;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div`
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 300;
    margin: 20px 0px;
    text-align: center;
    padding: 4px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & > * {
        margin: 8px 0px;
    }
`;
