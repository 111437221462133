import styled from 'styled-components';

import { CloudUpload } from '@material-ui/icons';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100vw;
`;

export const DropContainer = styled.section`
    min-width: 200px;
    max-width: 500px;
    width: 70vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DropText = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.light};
    text-align: center;
`;

export const DropTextImportant = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: ${(props) => props.theme.primary};
    margin-top: 10px;
`;

export const UploadIcon = styled(CloudUpload)`
    color: rgba(0, 0, 0, 0.2);
    font-size: 40px;
    transform: scale(3) translateY(-10px);
`;
