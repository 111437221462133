import * as material from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Icon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer, getMenu } from '../../../../store/menuSlice';
import PageItem from './PageItem';

const MenuItem = ({ item, level = 1 }) => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    const { drawer } = useSelector(getMenu());

    function handleToggleMenu() {
        if (!drawer) dispatch(toggleDrawer());
        setOpen(!open);
    }

    useEffect(() => {
        if (!drawer) {
            setOpen(false);
        }
    }, [drawer]);

    return (
        <>
            <ListItemButton
                onClick={() => handleToggleMenu()}
                sx={{ background: 'white', filter: open && 'brightness(0.8)', pl: level + 1 }}>
                <ListItemIcon>
                    <Icon as={material[item.icon]} />
                </ListItemIcon>
                <ListItemText primary={item.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.subitems.map((subitem) => (
                        <MenuItem item={subitem} level={level + 1} key={subitem.name} />
                    ))}
                    {item.pages.map((page) => (
                        <PageItem item={page} key={page.name} />
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export default MenuItem;
