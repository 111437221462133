import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material"
import handleCloseAction from "../../../../components/HandleCloseAction";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import Loader from "../../../../components/Loader";
import api from "../../../../services/api";
import { RowForTable } from "./tableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const CteTable = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, data: rows } = useQuery(['/cte/file/batch'],
    async () => {
      const response = await api.get(`/cte/file/batch`);
      const rows = response.data.map((d, index) => { return { ...d, id: index } })
      return rows;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Tabela atualizada!',
        { variant: 'success',
          action: (id) => handleCloseAction(id, closeSnackbar)
        });
      },
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading) {
    return <Loader type="ThreeDots" color="#000" />
  }


  return (
    <TableContainer component={Paper} sx={{ height: '80vh', overflow: 'auto' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>CNPJ do Cliente</StyledTableCell>
            <StyledTableCell>Data Da Solicitação</StyledTableCell>
            <StyledTableCell>Periodo Inicial</StyledTableCell>
            <StyledTableCell>Periodo Final</StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>Tipos de Documentos</StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>Tipos de Arquivos</StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>Status</StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody >
          {rows && rows.map(row => (
            <RowForTable row={row} key={v4()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { CteTable }
