import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Button } from '../../../../../components';
import Loader from '../../../../../components/Loader';
import { useAuth } from '../../../../../hooks/auth';
import api from '../../../../../services/api';
import {
    Container,
    DeleteButton,
    DeleteConfirmDialog,
    DeleteIcon,
    HistoryItemInfo,
    HistoryItemLine,
    InfoLabel,
    InfoValue
} from './styles';
import handleCloseAction from '../../../../../components/HandleCloseAction';
import { logError } from '../../../../../utils/error';

const HistoryItem = ({ event, onUpdate }) => {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const { getAuthorization } = useAuth();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const canDelete = useMemo(() => {
        if (event.fromCache) return false;

        const forbiddenStatus = ['63'];

        const haveAuthorization = getAuthorization('delete-history-item-on-search');

        const validStatus = !forbiddenStatus.includes(String(event.statusId));

        return haveAuthorization && validStatus;
    }, [event.statusId, getAuthorization]);

    async function handleDelete() {
        try {
            setLoading(true);
            const response = await api.delete(`/operations/events/${event.id}`);

            if (!response) throw new Error('Delete failed');
            if (response.status !== 200) throw new Error('Delete failed');

            setTimeout(() => {
                setLoading(false);
                onUpdate();
                return;
            }, 3000);
        } catch (error) {
            logError(error);

            enqueueSnackbar('Falha ao deletar evento, tente novamente.', {
                variant: 'error',
                action: (id) => handleCloseAction(id, closeSnackbar)
            });
            console.log(error);
            setLoading(false);
        }
    }

    return !!loading ? (
        <Loader type="ThreeDots" color="#000" style={{ flex: 1 }} />
    ) : (
        <Container>
            {canDelete && !!deleteConfirm ? (
                <DeleteConfirmDialog>
                    <h3>Deletar evento:</h3>
                    <p>
                        {event.action} na data {event.date}
                    </p>
                    <p>Usuário: {event.user}</p>
                    <div style={{ margin: '8px 0px' }}>
                        <Button onClick={() => handleDelete()}>Confirmar</Button>
                        <Button
                            variant="secondary"
                            style={{ marginLeft: '16px' }}
                            onClick={() => setDeleteConfirm(false)}>
                            Cancelar
                        </Button>
                    </div>
                </DeleteConfirmDialog>
            ) : (
                <>
                    <HistoryItemLine>
                        {canDelete && (
                            <DeleteButton onClick={() => setDeleteConfirm(true)}>
                                <DeleteIcon className="material-icons">delete</DeleteIcon>
                            </DeleteButton>
                        )}
                        <HistoryItemInfo>
                            <InfoLabel>Data</InfoLabel>
                            <InfoValue>{event.date}</InfoValue>
                        </HistoryItemInfo>
                        <HistoryItemInfo>
                            <InfoLabel>Usuário</InfoLabel>
                            <InfoValue>{event.user}</InfoValue>
                        </HistoryItemInfo>
                        <HistoryItemInfo>
                            <InfoLabel>Ação</InfoLabel>
                            <InfoValue>{event.action}</InfoValue>
                        </HistoryItemInfo>
                    </HistoryItemLine>
                    <HistoryItemLine>
                        {event.dock && (
                            <HistoryItemInfo>
                                <InfoLabel>Doca</InfoLabel>
                                <InfoValue>{event.dock}</InfoValue>
                            </HistoryItemInfo>
                        )}
                        {event.occurrence && (
                            <HistoryItemInfo>
                                <InfoLabel>Ocorrência</InfoLabel>
                                <InfoValue>{event.occurrence}</InfoValue>
                            </HistoryItemInfo>
                        )}
                        {event.driver && (
                            <HistoryItemInfo>
                                <InfoLabel>Motorista</InfoLabel>
                                <InfoValue>{event.driver}</InfoValue>
                            </HistoryItemInfo>
                        )}
                        {event.description && (
                            <HistoryItemInfo>
                                <InfoLabel>Descrição</InfoLabel>
                                <InfoValue>{event.description}</InfoValue>
                            </HistoryItemInfo>
                        )}
                        {event.fromCache && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </HistoryItemLine>
                </>
            )}
        </Container>
    );
};

export default HistoryItem;
