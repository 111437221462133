import { Alert } from '@mui/material';
import handleCloseAction from '../../../../components/HandleCloseAction';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';
import Card from '../../components/Card';
import DoughnutChart from '../../components/DoughnutChart';
import { StockByStatus } from '../../components/StockByStatus';
import getColorsRandom from '../../utils/getColorsRandom';
import { CardsSection, Container } from './styles';

const DashboardVaiFacil = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, data: kpis } = useQuery(
    ['kpis'],
    async () => {
      const { data } = await api.get('/kpis');

      return data;
    },
    {
      onError: (err) => {
        console.log('err', err);
      },
      onSuccess: () => {
        enqueueSnackbar('Dashboards atualizados!',
        { variant: 'success',
          action: (id) => handleCloseAction(id, closeSnackbar)
        });
      },
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  );

  const status = useMemo(() => {
    if (!kpis) return [];
    return kpis.items.status;
  }, [kpis]);

  const ontime = useMemo(() => {
    if (!kpis) return [];

    return kpis.items.deliveries.onTime;
  }, [kpis]);

  const deliveries = useMemo(() => {
    if (!kpis) return [];
    return kpis.items.deliveries.delivered;
  }, [kpis]);

  const chartData = useMemo(() => {
    if (!kpis) return null;

    const contracts = kpis.items.integrated.map((item) => item.contract);
    const quantity = kpis.items.integrated.map((item) => item.quantity);

    const total = kpis.items.integrated.reduce(
      (total, { quantity }) => total + parseFloat(quantity),
      0
    );

    const data = {
      labels: contracts,
      datasets: [
        {
          label: 'Pedidos do dia',
          data: quantity,
          backgroundColor: getColorsRandom(contracts),
          borderWidth: 4
        }
      ]
    };

    return {
      total,
      data
    };
  }, [kpis]);


  if (isLoading) {
    return (
      <Loader type="ThreeDots" color="#000" />
    )
  }

  if (!kpis && !isLoading)
    return (
      <Alert
        severity="success"
        sx={{ margin: 4, width: 'fit-content', ml: 'auto', mr: 'auto' }}>
        Nenhuma atualização
      </Alert>
    );

  return (
    <Container>
      <DoughnutChart data={chartData} />
      <CardsSection>
        <Card description="ENTREGUES HOJE" value={deliveries} />
        <Card description="NO PRAZO" value={ontime} />
      </CardsSection>
      <StockByStatus data={status} footer={'ESTOQUE POR STATUS'}>
        <th>STATUS</th>
        <th>QTD</th>
      </StockByStatus>
    </Container>
  )
};

export default DashboardVaiFacil;
