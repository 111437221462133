import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    @media screen and (min-width: 912px) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
    }
`;

export const Title = styled.div`
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 500;
    margin: 20px 0px;
`;

export const UpperSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const LowerSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const IconButton = styled.button`
    background: transparent;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    margin: 20px 0px;
`;

export const Icon = styled.span`
    font-size: 30px;
    margin-left: 8px;
    color: ${(props) => (props.enable ? props.theme.success : props.theme.danger)};
`;
